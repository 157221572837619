import React, { useState } from 'react'
import {
    DEFAULT_VIDEO_CONSTRAINTS,
    SELECTED_VIDEO_INPUT_KEY,
} from '../../utils/constants'
import { LocalVideoTrack } from 'twilio-video'
import useDevices from '../../hooks/useDevices'
import useMediaStreamTrack from '../../hooks/Tracks/useMediaStreamTrack'
import useVideoContext from '../../hooks/Video/useVideoContext'
import VideoTrack from '../Participant/VideoTrack'
import { useTranslation } from 'react-i18next'

export default function VideoInputList() {
    const { videoInputDevices } = useDevices()
    const { localTracks } = useVideoContext()

    const localVideoTrack = localTracks.find(
        (track) => track.kind === 'video'
    ) as LocalVideoTrack | undefined
    const mediaStreamTrack = useMediaStreamTrack(localVideoTrack)
    const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
        window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
    )
    const localVideoInputDeviceId =
        mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId

    function replaceTrack(newDeviceId: string) {
        setStoredLocalVideoDeviceId(newDeviceId)
        window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId)
        localVideoTrack?.restart({
            ...(DEFAULT_VIDEO_CONSTRAINTS as object),
            deviceId: { exact: newDeviceId },
        })
    }

    const { t } = useTranslation()

    return (
        <div className="w-full mx-auto">
            <label className="block text-lg font-medium text-gray-700 mb-1">
                {' '}
                {t('video_page.video input')}
            </label>

            {localVideoTrack && (
                <div className="w-48 h-32 mx-auto">
                    <VideoTrack isLocal track={localVideoTrack} />
                </div>
            )}
            {videoInputDevices.length > 1 ? (
                <select
                    onChange={(e) => replaceTrack(e.target.value)}
                    value={localVideoInputDeviceId || ''}
                    className="border border-gray-300 rounded-md p-2 w-full"
                >
                    {videoInputDevices.map((device) => (
                        <option value={device.deviceId} key={device.deviceId}>
                            {device.label}
                        </option>
                    ))}
                </select>
            ) : (
                <p className="flex justify-center">
                    {localVideoTrack?.mediaStreamTrack.label ||
                        t('video_page.no local video')}
                </p>
            )}
        </div>
    )
}
