import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { Message, Conversation, Client } from '@twilio/conversations'
import api from '../../api'
import SmsReducer from './SmsReducer'
import { getUnreadCount } from '../../utils/unreadSms'

interface SmsDeviceState {
    client: Client | null
    ready: boolean
    conversations: Conversation[]
    messages: Map<string, Message[]>
}

const initialState: SmsDeviceState = {
    client: null,
    ready: false,
    conversations: [],
    messages: new Map(),
}

async function getSubscribedConversations(
    client: Client
): Promise<Conversation[]> {
    let subscribedConversations = await client.getSubscribedConversations()
    let conversations = subscribedConversations.items

    while (subscribedConversations.hasNextPage) {
        subscribedConversations = await subscribedConversations.nextPage()
        conversations = [...conversations, ...subscribedConversations.items]
    }

    return conversations
}

async function getConversationMessages(
    conversation: Conversation
): Promise<Message[]> {
    let messagesPaginator = await conversation.getMessages()
    let messages = messagesPaginator.items

    while (messagesPaginator.hasNextPage) {
        messagesPaginator = await messagesPaginator.nextPage()
        messages = [...messages, ...messagesPaginator.items]
    }

    return messages
}

type SmsDeviceContextType = {
    client: Client | null
    ready: boolean
    conversations: Conversation[] | null
    messages: Map<string, Message[]> | null
    unreadMessages: number
    updateUnreadMessages: () => void
}

const SmsDeviceContext = createContext<SmsDeviceContextType>({
    client: null,
    ready: false,
    conversations: null,
    messages: null,
    unreadMessages: 0,
    updateUnreadMessages: () => null,
})

export const useSmsDevice = () => useContext(SmsDeviceContext)

interface SmsDeviceProviderProps {
    children: React.ReactNode
}

export const SmsDeviceProvider: React.FC<SmsDeviceProviderProps> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(SmsReducer, initialState)
    const [unreadMessages, setUnreadMessages] = React.useState(0)

    const initializeDevice = async () => {
        try {
            const response = await api.get('/phone/token')
            if (
                response.data &&
                response.data.data &&
                response.data.data.token
            ) {
                const token = response.data.data.token
                const client = new Client(token)
                client.on('initFailed', (error) => {
                    console.error('Twilio Conversations Error:', error)
                    // Handle the error here
                })
                client.on('connectionError', (error) => {
                    console.error('Twilio Conversations Error:', error)
                    // Handle the error here
                })
                dispatch({ type: 'SET_CLIENT', payload: { client } })
                const conversations = await getSubscribedConversations(client)
                dispatch({
                    type: 'SET_CONVERSATIONS',
                    payload: { conversations },
                })

                client.on('tokenAboutToExpire', async () => {
                    try {
                        const response = await api.get('/phone/token')
                        if (
                            client &&
                            response.data &&
                            response.data.data &&
                            response.data.data.token
                        ) {
                            client.updateToken(response.data.data.token)
                        }
                    } catch (err) {
                        console.error(err)
                    }
                })

                client.on('tokenExpired', async () => {
                    try {
                        const response = await api.get('/phone/token')
                        if (
                            client &&
                            response.data &&
                            response.data.data &&
                            response.data.data.token
                        ) {
                            client.updateToken(response.data.data.token)
                        }
                    } catch (err) {
                        console.error(err)
                    }
                })
                client.on('conversationJoined', async (conversation) => {
                    if (conversation.status === 'joined') {
                        dispatch({
                            type: 'CONVERSATION_JOINED',
                            payload: { conversation },
                        })
                    }
                })
                client.on('conversationLeft', (conversation) => {
                    dispatch({
                        type: 'CONVERSATION_LEFT',
                        payload: { conversation },
                    })
                })

                client.on('messageAdded', async (message: Message) => {
                    dispatch({ type: 'MESSAGE_ADDED', payload: { message } })
                })
            }
        } catch (error) {
            console.error('Error fetching token:', error)
        }
    }

    useEffect(() => {
        initializeDevice()

        return () => {
            if (state.client) {
                state.client.removeAllListeners()
                dispatch({
                    type: 'SET_READY',
                    payload: { ready: false },
                })
            }
        }
    }, []) // Run once on component mount

    const fetchMessagesForConversations = async (
        conversations: Conversation[]
    ) => {
        const messagesMap: Map<string, Message[]> = new Map()
        try {
            for (const conversation of conversations) {
                const messages = await getConversationMessages(conversation)
                messagesMap.set(conversation.sid, messages)
            }
            dispatch({
                type: 'SET_MESSAGES',
                payload: { messages: messagesMap },
            })
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (state.conversations) {
            fetchMessagesForConversations(state.conversations)
        }
    }, [state.conversations])

    useEffect(() => {
        if (state.client) {
            state.client.on('connectionStateChanged', (state) => {
                let readyState = false

                if (state === 'connected') {
                    readyState = true
                }

                dispatch({
                    type: 'SET_READY',
                    payload: { ready: readyState },
                })

                if (state === 'denied') {
                    console.error('Connection denied')
                }
            })
        }
    }, [state.client])

    const updateUnreadMessages = () => {
        let unreadMessagesCounter = 0
        state.conversations?.forEach((conversation) => {
            unreadMessagesCounter += getUnreadCount(
                conversation,
                state.messages
            )
        })
        setUnreadMessages(unreadMessagesCounter)
    }

    useEffect(() => {
        updateUnreadMessages()
    }, [state.messages])

    return (
        <SmsDeviceContext.Provider
            value={{ ...state, unreadMessages, updateUnreadMessages }}
        >
            {children}
        </SmsDeviceContext.Provider>
    )
}
