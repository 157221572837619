import {
    BookOpenIcon,
    FilmIcon,
    MusicalNoteIcon,
    PuzzlePieceIcon,
    RadioIcon,
    TvIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SectionLayout from './SectionLayout'
import RadioScreen from './Fun/Radio/RadioScreen'
import MediaScreen from './Fun/Media/MediaScreen'
import { useTranslation } from 'react-i18next'
import GamesScreen from './Fun/Games/GamesScreen'
import GameScreen from './Fun/Games/GameScreen'
import MusicScreen from './Fun/Music/MusicScreen'

const FunScreen: React.FC = () => {
    const { t } = useTranslation()

    const cards = [
        {
            name: t('fun_screen.radio'),
            href: 'radio',
            icon: <RadioIcon />,
            enabled: true,
        },
        {
            name: t('fun_screen.TV'),
            href: 'tv',
            icon: <TvIcon />,
            enabled: false,
        },
        {
            name: t('fun_screen.audiobooks'),
            href: 'audiobook',
            icon: <BookOpenIcon />,
            enabled: false,
        },
        {
            name: t('fun_screen.music'),
            href: 'music',
            icon: <MusicalNoteIcon />,
            enabled: true,
        },
        {
            name: t('fun_screen.films'),
            href: 'movies',
            icon: <FilmIcon />,
            enabled: true,
        },
        {
            name: t('fun_screen.games'),
            href: 'games',
            icon: <PuzzlePieceIcon />,
            enabled: true,
        },
        {
            name: t('fun_screen.media'),
            href: 'media/0',
            icon: <UserGroupIcon />,
            enabled: true,
        },
    ]

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <SectionLayout
                        section_title={t('footer.fun')}
                        main_color="bg-sky-600/60"
                        cards={cards}
                    />
                }
            />
            <Route path="radio" element={<RadioScreen />} />
            <Route path="tv" element={<>tv</>} />
            <Route path="audiobook" element={<>audiobook</>} />
            <Route path="music/*" element={<MusicScreen />} />
            <Route path="movies" element={<>movies</>} />
            <Route path="games">
                <Route index element={<GamesScreen />} />
                <Route path=":id" element={<GameScreen />} />
            </Route>
            <Route path="media">
                <Route index element={<MediaScreen />} />
                <Route path=":id" element={<MediaScreen />} />
            </Route>
        </Routes>
    )
}

export default FunScreen
