import { Conversation, Message } from '@twilio/conversations'

export const getUnreadCount = (
    conversation: Conversation,
    messages: Map<string, Message[]>
): number => {
    try {
        const messageArray = messages.get(conversation.sid)

        if (!messageArray) {
            return 0
        }
        if (messageArray.length <= 0) {
            return 0
        }
        if (
            conversation.lastReadMessageIndex !== null &&
            conversation.lastReadMessageIndex !== undefined
        ) {
            return (
                messageArray[messageArray.length - 1].index -
                conversation.lastReadMessageIndex
            )
        }

        if (conversation.lastReadMessageIndex === null) {
            return messageArray[messageArray.length - 1].index
        }
    } catch (e) {
        console.error(e)
    }
    return 0
}
