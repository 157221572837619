import React, { useState, ChangeEvent } from 'react'
import {
    PlusIcon,
    TrashIcon,
    MinusCircleIcon,
    PencilIcon,
} from '@heroicons/react/24/outline'
import { Event, Override } from './EventUtils'
import {
    eventColorMap,
    getRecurrenceRule,
    getRecurrenceMode,
} from './EventUtils'
import ColorSelect from './ColorSelect'
import api from '../../../../api'
import { addMinutes, startOfHour, formatISO9075 } from 'date-fns'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

interface AddEventModalProps {
    calendarId: string
    onClose: () => void
    addEvent: (event: Event) => void
    event: Event | null
    replaceEvent: (event: Event) => void
}

const mapOverrides = (overrides: Override[]): OverridePost[] => {
    return overrides.map((override) => {
        let unit: string
        let convertedMinutes: number

        if (override.minutes % (24 * 60) === 0) {
            unit = 'day'
            convertedMinutes = override.minutes / (24 * 60)
        } else if (override.minutes % 60 === 0) {
            unit = 'hour'
            convertedMinutes = override.minutes / 60
        } else {
            unit = 'minute'
            convertedMinutes = override.minutes
        }

        return {
            Method: override.method,
            Minutes: convertedMinutes,
            Unit: unit,
        }
    })
}

const eventToPost = (event: Event): EventPost => {
    return {
        Start: {
            DateTime: event.start.dateTime,
            TimeZone: event.start.timeZone,
        },
        End: {
            DateTime: event.end.dateTime,
            TimeZone: event.end.timeZone,
        },
        Summary: event.summary ? event.summary : '',
        Description: event.description ? event.description : '',
        Location: event.location ? event.location : '',
        Reminders: {
            Overrides: event.reminders?.overrides
                ? mapOverrides(event.reminders.overrides)
                : [],
        },
        ColorId: event.colorId ? event.colorId : '1',
        Recurrence: event.recurrence,
    }
}

interface EventPost {
    Start: {
        DateTime: string
        TimeZone: string
    }
    End: {
        DateTime: string
        TimeZone: string
    }
    Summary: string
    Description?: string
    Location?: string
    Reminders: {
        Overrides?: OverridePost[]
        //UseDefault: boolean
    }
    ColorId?: string
    Recurrence?: string[]
}

interface OverridePost {
    Method: string
    Minutes: number
    Unit: string
}

const AddEventModal: React.FC<AddEventModalProps> = ({
    calendarId,
    onClose,
    addEvent,
    event,
    replaceEvent,
}) => {
    const currentDate = new Date()
    const roundedDate = startOfHour(addMinutes(currentDate, 30))

    const endDate = addMinutes(roundedDate, 60)

    const startDateTime = roundedDate.toISOString()
    const endDateTime = endDate.toISOString()

    const [loading, setLoading] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { t } = useTranslation()

    const timeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Ljubljana'
    const [eventData, setEventData] = useState<EventPost>(
        event
            ? eventToPost(event)
            : {
                  Start: {
                      DateTime: startDateTime,
                      TimeZone: timeZone,
                  },
                  End: {
                      DateTime: endDateTime,
                      TimeZone: timeZone,
                  },
                  Summary: '',
                  Description: '',
                  Location: '',
                  Reminders: {
                      Overrides: [],
                      //UseDefault: false,
                  },
                  ColorId: '1',
                  Recurrence: ['none'],
              }
    )

    const [reminders, setReminders] = useState<OverridePost[]>(
        event
            ? eventData.Reminders && eventData.Reminders.Overrides
                ? eventData.Reminders.Overrides
                : []
            : [
                  {
                      Method: 'popup',
                      Minutes: 10,
                      Unit: 'minutes',
                  },
              ]
    )

    const [recurrence, setRecurrence] = useState<string>(
        event
            ? getRecurrenceMode(event.recurrence ? event.recurrence.join() : '')
            : 'none'
    )

    const handleAddReminder = () => {
        if (reminders.length < 4) {
            setReminders([
                ...reminders,
                {
                    Method: 'popup',
                    Minutes: 10,
                    Unit: 'minutes',
                },
            ])
        }
    }

    const handleInputChange = (
        event: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = event.target
        if (name === 'End' || name === 'Start') {
            const DateTime = new Date(value).toISOString()

            const TimeZone =
                Intl.DateTimeFormat().resolvedOptions().timeZone ||
                'Europe/Belgrade'

            setEventData((prevData) => ({
                ...prevData,
                [name]: {
                    DateTime,
                    TimeZone,
                },
            }))
        } else {
            setEventData((prevData) => ({
                ...prevData,
                [name]: value,
            }))
        }
    }

    const handleReminderInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        index: number
    ) => {
        const { name, value } = event.target
        const updatedReminders = [...reminders]

        if (name.includes('reminderMethod')) {
            updatedReminders[index].Method = value
        } else if (name.includes('reminderTime')) {
            updatedReminders[index].Minutes = parseInt(value)
        } else if (name.includes('reminderUnit')) {
            updatedReminders[index].Unit = value
        }

        setReminders(updatedReminders)
    }

    const handleRemoveReminder = (index: number) => {
        const updatedReminders = [...reminders]
        updatedReminders.splice(index, 1)
        setReminders(updatedReminders)
    }

    const handleColorChange = (ColorId: string) => {
        setEventData((prevData) => ({
            ...prevData,
            ColorId,
        }))
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)

        if (recurrence !== 'custom') {
            if (recurrence === 'none') {
                eventData.Recurrence = undefined
            } else {
                eventData.Recurrence = [
                    getRecurrenceRule(eventData.Start.DateTime, recurrence),
                ]
            }
        }

        const startDatetime = new Date(eventData.Start.DateTime)
        const endDatetime = new Date(eventData.End.DateTime)

        if (endDatetime < startDatetime) {
            const a = t('calendar_page.end datetime error')
            setErrorMessage(a)
            setLoading(false)
            return
        }

        let hasReminderExceededLimit = false

        reminders.forEach((event) => {
            let minutes = event.Minutes
            if (event.Unit === 'hours') {
                minutes = minutes * 60
            } else if (event.Unit === 'days') {
                minutes = minutes * 60 * 24
            }
            const fourWeeksInMinutes = 4 * 7 * 24 * 60 // 4 weeks converted to minutes

            if (minutes > fourWeeksInMinutes) {
                hasReminderExceededLimit = true
            }
        })

        if (hasReminderExceededLimit) {
            const a = t('calendar_page.4 week before error')
            setErrorMessage(a)
            setLoading(false)
            return
        }
        eventData.Reminders.Overrides = JSON.parse(JSON.stringify(reminders))
        eventData.Reminders.Overrides?.map((event) => {
            if (event.Unit === 'hours') {
                event.Minutes = event.Minutes * 60
            } else if (event.Unit === 'days') {
                event.Minutes = event.Minutes * 60 * 24
            }

            return event
        })

        try {
            const response = event
                ? await api.put('calendar/update/' + calendarId, {
                      ...eventData,
                      id: event.id,
                  })
                : await api.post('calendar/create/' + calendarId, eventData)

            if (response.status === 200 && response.data.status === 'success') {
                if (event) {
                    const a = t('calendar_page.event updated')
                    setSuccessMessage(a)
                    if (response.data && response.data.data) {
                        replaceEvent(response.data.data)
                    }
                } else {
                    const a = t('calendar_page.event created')
                    setSuccessMessage(a)
                    addEvent(response.data.data)
                }

                setTimeout(() => {
                    onClose()
                }, 1000)
            } else {
                const a = t('calendar_page.went wrong')
                setErrorMessage(a)
            }
        } catch (e) {
            console.error(e)
            const a = t('calendar_page.went wrong')
            setErrorMessage(a)
        }
        setLoading(false)
        setTimeout(() => {
            setSuccessMessage('')
            setErrorMessage('')
        }, 3000)
    }

    return (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-black/40">
            <div className=" bg-white p-4 h-[33rem] w-116 overflow-auto mx-auto rounded-lg shadow-lg z-10">
                <h2 className="text-lg font-semibold mb-1">
                    {event
                        ? t('calendar_page.edit event')
                        : t('calendar_page.add event')}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex mb-4">
                        <label htmlFor="title" className="block text-lg mr-1">
                            {t('calendar_page.title')}:
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="Summary"
                            className="w-full border-gray-300 border p-1 rounded  "
                            onChange={handleInputChange}
                            value={eventData.Summary}
                            required
                        />
                    </div>

                    <div className="flex justify-between">
                        <div>
                            <div className="mb-4 flex">
                                <label
                                    htmlFor="location"
                                    className="block text-lg  mr-1"
                                >
                                    {t('calendar_page.location')}:
                                </label>
                                <input
                                    type="text"
                                    id="location"
                                    name="Location"
                                    className="w-full border-gray-300 border p-1 rounded"
                                    onChange={handleInputChange}
                                    value={eventData.Location}
                                />
                            </div>
                            <div className="mb-3 flex">
                                <label
                                    htmlFor="startDateTime"
                                    className="block text-lg  mr-1"
                                >
                                    {t('calendar_page.start')}:
                                </label>
                                <input
                                    type="datetime-local"
                                    id="startDateTime"
                                    name="Start"
                                    className="w-full border-gray-300 border p-1 rounded"
                                    value={formatISO9075(
                                        new Date(eventData.Start.DateTime),
                                        { representation: 'complete' }
                                    )}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-4 flex">
                                <label
                                    htmlFor="endDateTime"
                                    className="block text-lg mr-1"
                                >
                                    {t('calendar_page.end')}:
                                </label>
                                <input
                                    type="datetime-local"
                                    id="endDateTime"
                                    name="End"
                                    className="w-full border-gray-300 border p-1 rounded"
                                    value={formatISO9075(
                                        new Date(eventData.End.DateTime),
                                        { representation: 'complete' }
                                    )}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 flex">
                                <label
                                    htmlFor="color"
                                    className="block text-lg mr-1 h-9 items-center"
                                >
                                    {t('calendar_page.color')}:
                                </label>
                                <ColorSelect
                                    value={
                                        eventData.ColorId
                                            ? eventData.ColorId
                                            : '1'
                                    }
                                    options={Array.from(eventColorMap).map(
                                        ([id, className]) => ({
                                            id,
                                            className,
                                        })
                                    )}
                                    onChange={handleColorChange}
                                />
                            </div>
                            <div className="mb-4 flex">
                                <label
                                    htmlFor="recurrence"
                                    className="block text-lg mr-1"
                                >
                                    {t('calendar_page.recurrence')}:
                                </label>
                                <select
                                    id="recurrence"
                                    name="Recurrence"
                                    className="border-gray-300 border p-1 rounded w-40"
                                    value={recurrence}
                                    onChange={(
                                        e: ChangeEvent<HTMLSelectElement>
                                    ) => setRecurrence(e.target.value)}
                                >
                                    <option value="none">
                                        {t('calendar_page.no repeat')}:
                                    </option>
                                    <option value="daily">
                                        {t('calendar_page.daily')}:
                                    </option>
                                    <option value="weekly">
                                        {t('calendar_page.weekly')}
                                    </option>
                                    <option value="monthly">
                                        {t('calendar_page.monthly')}
                                    </option>
                                    <option value="yearly">
                                        {t('calendar_page.yearly')}
                                    </option>
                                    <option value="weekdays">
                                        {t('calendar_page.every weekday')}
                                    </option>
                                    {recurrence === 'custom' && (
                                        <option value="custom">
                                            {t('calendar_page.custom')}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="w-80">
                            <div className="mb-4">
                                <div className="flex justify-between mb-1">
                                    <label
                                        htmlFor="reminders"
                                        className="block text-lg"
                                    >
                                        {t('calendar_page.reminders')}
                                    </label>
                                    <button
                                        type="button"
                                        className="flex items-center justify-center bg-blue-500 text-white rounded p-1"
                                        onClick={handleAddReminder}
                                    >
                                        <PlusIcon className="w-5 h-5" />
                                    </button>
                                </div>

                                <div className="flex flex-col space-y-2 mt-2">
                                    {reminders.map((reminder, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center space-x-2"
                                        >
                                            <select
                                                id={`reminderMethod-${index}`}
                                                name={`reminderMethod-${index}`}
                                                className="border-gray-300 border p-1 rounded w-32"
                                                value={reminder.Method}
                                                onChange={(e) =>
                                                    handleReminderInputChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            >
                                                <option value="email">
                                                    {t('calendar_page.email')}
                                                </option>
                                                <option value="popup">
                                                    {t(
                                                        'calendar_page.notification'
                                                    )}
                                                </option>
                                            </select>
                                            <input
                                                type="number"
                                                id={`reminderTime-${index}`}
                                                name={`reminderTime-${index}`}
                                                className="w-14 border-gray-300 border p-1 rounded"
                                                value={reminder.Minutes}
                                                onChange={(e) =>
                                                    handleReminderInputChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />
                                            <select
                                                id={`reminderUnit-${index}`}
                                                name={`reminderUnit-${index}`}
                                                className="border-gray-300 border p-1 rounded w-24"
                                                value={reminder.Unit}
                                                onChange={(e) =>
                                                    handleReminderInputChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            >
                                                <option value="minutes">
                                                    {t('calendar_page.minutes')}
                                                </option>
                                                <option value="hours">
                                                    {t('calendar_page.hours')}
                                                </option>
                                                <option value="days">
                                                    {t('calendar_page.days')}
                                                </option>
                                            </select>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleRemoveReminder(index)
                                                }
                                            >
                                                <MinusCircleIcon className="w-8 h-8 text-gray-500 hover:text-red-500" />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-4">
                        <label
                            htmlFor="Description"
                            className="block text-lg mb-1 mr-1"
                        >
                            {t('calendar_page.description')}
                        </label>
                        <textarea
                            id="Description"
                            name="Description"
                            rows={4}
                            className="w-full border-gray-300 border p-2 rounded resize-none"
                            onChange={handleInputChange}
                            value={eventData.Description}
                        />
                    </div>

                    <div className="flex justify-between ">
                        <button
                            className="flex bg-red-500 hover:bg-red-700 text-white rounded p-2"
                            onClick={onClose}
                        >
                            {t('calendar_page.cancel')}
                            <TrashIcon className="ml-1 w-6 h-6" />
                        </button>
                        <div className="flex items-center">
                            {loading ? (
                                <LoadingWheel className="w-6 h-6" />
                            ) : (
                                <>
                                    {!errorMessage && successMessage && (
                                        <div className="text-green-500 mr-3">
                                            {successMessage}
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="text-red-500 mr-3">
                                            {errorMessage}
                                        </div>
                                    )}
                                </>
                            )}

                            <button
                                type="submit"
                                className="flex bg-blue-500 hover:bg-blue-600 text-white rounded p-2"
                            >
                                {event ? (
                                    <>
                                        {t('calendar_page.edit event')}
                                        <PencilIcon className="ml-1 w-5 h-5" />{' '}
                                    </>
                                ) : (
                                    <>
                                        {t('calendar_page.add event')}
                                        <PlusCircleIcon className="ml-1 w-6 h-6" />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddEventModal
