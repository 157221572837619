import { Action, AuthState } from './Types'

const authReducer = (state: AuthState, action: Action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                authenticated: true,
                user: action.payload,
            }
        case 'LOGOUT':
            return {
                ...state,
                authenticated: false,
                user: null,
            }

        case 'STOP_LOADING':
            return {
                ...state,
                loading: false,
            }
        default:
            throw new Error('Unknown action type')
    }
}

export default authReducer
