import React, { useState, useRef } from 'react'
import {
    ShareIcon,
    ClipboardIcon,
    ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom'
import api from '../../../../api'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

const ShareButton: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const shareLinkInputRef = useRef<HTMLInputElement | null>(null)
    const [shareLink, setShareLink] = useState<string | null>(null)
    const [isCopied, setIsCopied] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()

    const handleShare = async () => {
        try {
            setLoading(true)
            const response = await api.get('/photos/share/' + id)
            if (response.data && response.data.data) {
                setShareLink(response.data.data.shareInfo.shareableUrl)
            }
            setLoading(false)
        } catch (error) {
            console.error('Error sharing media:', error)
            setLoading(false)
        }
    }

    const handleCopy = () => {
        if (shareLinkInputRef.current) {
            shareLinkInputRef.current.select()
            navigator.clipboard
                .writeText(shareLinkInputRef.current.value)
                .then(() => {
                    setIsCopied(true)
                    setTimeout(() => {
                        setIsCopied(false)
                    }, 5000)
                })
                .catch((error) => {
                    console.error('Error copying to clipboard:', error)
                })
        }
    }

    return (
        <div className="flex items-end gap-2">
            {shareLink || shareLink === '' ? (
                <>
                    {shareLink !== '' ? (
                        <div className="flex items-end">
                            <input
                                ref={shareLinkInputRef}
                                className="py-2 px-4 h-11 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                value={shareLink}
                                readOnly
                            />
                            <button
                                className={`px-4 py-2  rounded-r-md ${
                                    isCopied ? 'bg-green-500' : 'bg-blue-500'
                                } text-white hover:bg-blue-600 focus:outline-none transition duration-200`}
                                onClick={handleCopy}
                            >
                                {isCopied ? (
                                    <ClipboardDocumentCheckIcon className="h-7 w-7" />
                                ) : (
                                    <ClipboardIcon className="h-7 w-7" />
                                )}
                            </button>
                        </div>
                    ) : (
                        <div className="text-red-600 bg-white rounded py-2 px-4 text-xl">
                            {t('album_page.album cannot be shared')}
                        </div>
                    )}
                </>
            ) : (
                <button
                    className="flex items-center gap-1 px-4 py-2 text-white text-xl bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                    onClick={handleShare}
                >
                    {loading ? (
                        <LoadingWheel className="h-5 w-5 mb-0.5" />
                    ) : (
                        <ShareIcon className="h-5 w-5" />
                    )}
                    {t('album_page.share')}
                </button>
            )}
        </div>
    )
}

export default ShareButton
