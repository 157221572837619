import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SectionLayout from './SectionLayout'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import AlbumsScreen from './Personal/Photos/AlbumsScreen'
import PhotosScreen from './Personal/Photos/PhotosScreen'
import CalendarScreen from './Personal/Calendar/CalendarScreen'

import { useTranslation } from 'react-i18next'

const PersonalScreen: React.FC = () => {
    const { t } = useTranslation()

    const cards = [
        {
            name: t('personal_screen.albums'),
            href: 'photos',
            icon: <PhotoIcon />,
            enabled: true,
        },
        {
            name: t('personal_screen.calendar'),
            href: 'calendar',
            icon: <CalendarDaysIcon />,
            enabled: true,
        },
    ]

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <SectionLayout
                        section_title={t('footer.personal')}
                        main_color="bg-orange-600/60"
                        cards={cards}
                    />
                }
            />
            <Route path="photos">
                <Route index element={<AlbumsScreen />} />
                <Route path=":id" element={<PhotosScreen />} />
            </Route>
            <Route path="calendar">
                <Route
                    index
                    element={
                        <CalendarScreen
                            canEdit={true}
                            calendarId={'primary'}
                            title={t('calendar_page.personal calendar')}
                        />
                    }
                />
            </Route>
        </Routes>
    )
}

export default PersonalScreen
