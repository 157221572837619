import React from 'react';
import {useTranslation} from 'react-i18next';

function SpotifyLogin() {
    const {t} = useTranslation();
    return (
        <div className="flex w-full h-full items-center justify-cente">
            <header className="App-header w-full">
            <a className="btn-spotify bg-green-600 font-semi-bold p-2 rounded-lg hover:bg-green-700" href={process.env.REACT_APP_API_URL+ "/sessions/oauth/spotify"}>
                {t('music_page.login with spotify')}
            </a>
            </header>
        </div>
    );
}

export default SpotifyLogin;