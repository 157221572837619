import React from 'react'
import SectionLayout from './SectionLayout'
import {
    ChatBubbleBottomCenterIcon,
    EnvelopeIcon,
    PhoneArrowDownLeftIcon,
    VideoCameraIcon,
} from '@heroicons/react/24/outline'
import { Route, Routes } from 'react-router-dom'
import EmailScreen from './Communication/Email/EmailScreen'
import { useTranslation } from 'react-i18next'
import PhoneScreen from './Communication/Phone/PhoneScreen'
import SmsScreen from './Communication/Sms/SmsScreen'
import VideoScreen from './Communication/Video/VideoScreen'
import { useSettings } from '../../hooks/useSettings'

const CommunicationScreen: React.FC = () => {
    const [settings] = useSettings()
    const { t } = useTranslation()

    const cards = [
        {
            name: t('communication_screen.phone'),
            href: 'phone',
            icon: <PhoneArrowDownLeftIcon />,
            enabled: settings?.phoneNumber ? true : false,
        },
        {
            name: t('communication_screen.SMS'),
            href: 'sms',
            icon: <ChatBubbleBottomCenterIcon />,
            enabled: settings?.phoneNumber ? true : false,
        },
        {
            name: t('communication_screen.email'),
            href: 'email',
            icon: <EnvelopeIcon />,
            enabled: true,
        },
        {
            name: t('communication_screen.videoMessaging'),
            href: 'video',
            icon: <VideoCameraIcon />,
            enabled: settings?.phoneNumber ? true : false,
        },
    ]

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <SectionLayout
                        section_title={t('footer.communication')}
                        main_color="bg-lime-600/60"
                        cards={cards}
                    />
                }
            />
            <Route path="email" element={<EmailScreen />} />
            {settings?.phoneNumber && (
                <>
                    <Route path="phone" element={<PhoneScreen />} />
                    <Route path="sms" element={<SmsScreen />} />

                    <Route path="video">
                        <Route index element={<VideoScreen />} />
                        <Route path=":URLRoomName" element={<VideoScreen />} />
                    </Route>
                </>
            )}
        </Routes>
    )
}

export default CommunicationScreen
