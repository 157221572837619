import { Message, Conversation, Client } from '@twilio/conversations'

// Action types
const MESSAGE_ADDED = 'MESSAGE_ADDED'
const CONVERSATION_JOINED = 'CONVERSATION_JOINED'
const CONVERSATION_LEFT = 'CONVERSATION_LEFT'
const SET_CLIENT = 'SET_CLIENT'
const SET_MESSAGES = 'SET_MESSAGES'
const SET_CONVERSATIONS = 'SET_CONVERSATIONS'
const UPDATE_TOKEN = 'UPDATE_TOKEN'
const SET_READY = 'SET_READY'

// Action interfaces
interface SetClientAction {
    type: typeof SET_CLIENT
    payload: { client: Client }
}

interface SetMessagesAction {
    type: typeof SET_MESSAGES
    payload: { messages: Map<string, Message[]> }
}

interface SetConversationsAction {
    type: typeof SET_CONVERSATIONS
    payload: { conversations: Conversation[] }
}

interface UpdateTokenAction {
    type: typeof UPDATE_TOKEN
    payload: { token: string }
}

// Action interfaces
interface MessageAddedAction {
    type: typeof MESSAGE_ADDED
    payload: { message: Message }
}

interface ConversationJoinedAction {
    type: typeof CONVERSATION_JOINED
    payload: { conversation: Conversation }
}
interface ConversationLeftAction {
    type: typeof CONVERSATION_LEFT
    payload: { conversation: Conversation }
}
interface SetReadyAction {
    type: typeof SET_READY
    payload: { ready: boolean }
}

type ActionType =
    | SetReadyAction
    | MessageAddedAction
    | ConversationJoinedAction
    | ConversationLeftAction
    | SetClientAction
    | SetMessagesAction
    | SetConversationsAction
    | UpdateTokenAction

// Reducer function
interface SmsDeviceState {
    client: Client | null
    ready: boolean
    conversations: Conversation[]
    messages: Map<string, Message[]>
}

const SmsReducer = (
    state: SmsDeviceState,
    action: ActionType
): SmsDeviceState => {
    switch (action.type) {
        case MESSAGE_ADDED: {
            const message = action.payload.message
            const conversationSid = message.conversation.sid
            const updatedMessages = new Map(state.messages)
            const existingMessages = updatedMessages.get(conversationSid) || []

            // Check for duplicates
            const isDuplicate = existingMessages.some(
                (msg) => msg.sid === message.sid
            )

            // If it's a new message, add it to the messages map
            if (!isDuplicate) {
                updatedMessages.set(conversationSid, [
                    ...existingMessages,
                    message,
                ])
            }

            return { ...state, messages: updatedMessages }
        }
        case CONVERSATION_JOINED: {
            const { conversation } = action.payload

            // Check for duplicate conversation by sid
            const isDuplicateConversation = state.conversations.some(
                (existingConversation) =>
                    existingConversation.sid === conversation.sid
            )

            // If it's a new conversation, add it to the conversations array
            if (!isDuplicateConversation) {
                return {
                    ...state,
                    conversations: [...state.conversations, conversation],
                }
            }

            return state
        }

        case CONVERSATION_LEFT: {
            const { conversation } = action.payload

            return {
                ...state,
                conversations: state.conversations.filter(
                    (it) => it.sid !== conversation.sid
                ),
            }
        }

        case SET_CLIENT:
            return { ...state, client: action.payload.client }
        case SET_MESSAGES:
            return { ...state, messages: action.payload.messages }
        case SET_CONVERSATIONS:
            return { ...state, conversations: action.payload.conversations }
        case UPDATE_TOKEN:
            // Assuming token is passed as "token" in the payload
            return {
                ...state,
                client: state.client ? new Client(action.payload.token) : null,
            }
        case SET_READY:
            return { ...state, ready: action.payload.ready }

        default:
            return state
    }
}

export default SmsReducer
