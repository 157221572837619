import React, { useState } from 'react'
import {
    XMarkIcon,
    PencilIcon,
    TrashIcon,
    CalendarIcon,
    UserCircleIcon,
    MapPinIcon,
    Bars3BottomLeftIcon,
    BellIcon,
} from '@heroicons/react/24/outline'
import { Event, Override } from './EventUtils'
import { parseISO } from 'date-fns'
import { formatShortDate, getColorById } from './CalendarScreen'
import api from '../../../../api'
import ConfirmationModal from '../../../common/ConfirmationModal'
import { useTranslation } from 'react-i18next'

interface EventModalProps {
    calendarId: string
    event: Event
    onClose: () => void
    onEdit: () => void
    deleteEvent: (event: Event) => void
    canEdit: boolean
}

const parseReminderMinutes = (minutes: number): string => {
    if (minutes >= 1440) {
        // If more than a day
        const days = Math.floor(minutes / 1440)
        return days === 1 ? `${days} day` : `${days} days`
    } else if (minutes >= 60) {
        // If more than an hour
        const hours = Math.floor(minutes / 60)
        return hours === 1 ? `${hours} hour` : `${hours} hours`
    } else if (minutes > 1) {
        // If more than a minute
        return `${minutes} minutes`
    } else {
        return `${minutes} minute`
    }
}

const displayReminders = (reminders: {
    overrides?: Override[]
    useDefault?: boolean
}) => {
    const { t } = useTranslation()

    return (
        <div>
            {reminders.useDefault ? (
                <span className="flex">
                    <BellIcon className="w-6 h-6 text-gray-500 mr-2" />
                    <span>10 {t('calendar_page.minutes before')}</span>
                </span>
            ) : (
                <>
                    {reminders.overrides ? (
                        reminders.overrides.map((reminder: Override, index) => (
                            <span className="flex" key={index}>
                                <BellIcon className="w-6 h-6 text-gray-500 mr-2" />
                                <span>
                                    {parseReminderMinutes(reminder.minutes)}{' '}
                                    {t('calendar_page.before')}
                                </span>
                            </span>
                        ))
                    ) : (
                        <span className="flex">
                            <BellIcon className="w-6 h-6 text-gray-500 mr-2" />
                            <span>{t('calendar_page.no reminders')}</span>
                        </span>
                    )}
                </>
            )}
        </div>
    )
}

const EventModal: React.FC<EventModalProps> = ({
    calendarId,
    event,
    onClose,
    onEdit,
    deleteEvent,
    canEdit,
}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [error, setError] = useState('')
    const { t } = useTranslation()

    const handleDeleteEvent = async () => {
        try {
            const response = await api.put('calendar/delete/' + calendarId, {
                id: event.id,
            })
            // Perform any additional actions after successful deletion
            if (response.status === 200) {
                deleteEvent(event)
                onClose() // Close the modal
            } else {
                const a = t('calendar_page.went wrong')
                setError(a)
            }
        } catch (error) {
            const a = t('calendar_page.went wrong')
            setError(a)

            console.error(error)
        }
    }

    const editEvent = async () => {
        onClose()
        onEdit()
    }

    return (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-black/40">
            <div className="relative bg-white p-4 h-[33rem] w-116 mx-auto rounded-lg shadow-lg z-10">
                {canEdit && (
                    <>
                        <button
                            className="absolute top-3 right-32 text-gray-500 hover:text-gray-700"
                            onClick={editEvent}
                        >
                            <PencilIcon className="w-10 h-10" />
                        </button>
                        <button
                            className="absolute top-3 right-16 text-gray-500 hover:text-gray-700 mr-1"
                            onClick={() => setShowConfirmationModal(true)}
                        >
                            <TrashIcon className="w-10 h-10" />
                        </button>{' '}
                    </>
                )}
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <XMarkIcon className="w-12 h-12" />
                </button>
                <div
                    className={`w-96 rounded h-4 ${getColorById(
                        event.colorId
                    )}`}
                ></div>
                <div className="flex flex-col pt-6">
                    <div className="flex">
                        <h2 className="flex w-full text-2xl font-semibold mb-2 break-all max-h-16  overflow-auto">
                            {event.summary ? event.summary : 'No title'}
                        </h2>
                    </div>
                    <div className="flex justify-between text-lg mt-1">
                        <div className="flex">
                            <CalendarIcon className="w-6 h-6 text-gray-500 mr-2" />
                            <span>
                                {t('calendar_page.from')}:
                                {' ' +
                                    formatShortDate(
                                        parseISO(event.start.dateTime)
                                    )}
                            </span>
                        </div>

                        <span className="flex">
                            <CalendarIcon className="w-6 h-6 text-gray-500 mr-2" />
                            <span>
                                {t('calendar_page.to')}:
                                {' ' +
                                    formatShortDate(
                                        parseISO(event.end.dateTime)
                                    )}
                            </span>
                        </span>
                    </div>
                    <div className="flex justify-between text-lg mt-3">
                        <div>
                            <span className="flex">
                                <MapPinIcon className="w-6 h-6 text-gray-500 mr-2" />
                                {event.location
                                    ? event.location
                                    : 'No location'}
                            </span>
                            <span className="flex mt-3">
                                <UserCircleIcon className="w-6 h-6 text-gray-500 mr-2 mt-1" />
                                {event.creator?.email}
                            </span>
                        </div>
                        <div>
                            {/* <span className="flex">
                                <BellIcon className="w-6 h-6 text-gray-500 mr-2" />
                                {event.reminders && event.reminders.overrides
                                    ? event.reminders.overrides.length
                                    : 0}{' '}
                                reminders
                            </span> */}
                            {event.reminders ? (
                                displayReminders(event.reminders)
                            ) : (
                                <span className="flex">
                                    <BellIcon className="w-6 h-6 text-gray-500 mr-2" />
                                    <span>
                                        {t('calendar_page.no reminders')}
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="flex items-start text-lg mt-3 h-52">
                        <Bars3BottomLeftIcon className="w-6 h-6 text-gray-800 mr-2 mt-1  flex shrink-0" />

                        <span className="flex items-start justify-start">
                            <span className="max-h-48  overflow-auto break-all">
                                {event.description
                                    ? event.description
                                    : t('calendar_page.no description')}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            {showConfirmationModal && (
                <ConfirmationModal
                    message={t('calendar_page.sure delete')}
                    succesButtonMessage={t('calendar_page.delete event')}
                    errorMessage={error}
                    onSuccess={handleDeleteEvent}
                    onCancel={() => setShowConfirmationModal(false)}
                />
            )}
        </div>
    )
}

export default EventModal
