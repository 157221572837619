import React from 'react'
import { Participant } from 'twilio-video'
import useParticipantNetworkQualityLevel from '../../hooks/Participants/useParticipantNetworkQualityLevel'

const STEP = 3
const BARS_ARRAY = [0, 1, 2, 3, 4]

interface NetworkQualityLevelProps {
    participant: Participant
}

const NetworkQualityLevel: React.FC<NetworkQualityLevelProps> = ({
    participant,
}) => {
    const networkQualityLevel = useParticipantNetworkQualityLevel(participant)

    if (networkQualityLevel === null) return null
    return (
        <div className="w-8 h-8 p-2 ml-3 flex items-center justify-center  z-10">
            <div className="flex items-end bg-black bg-opacity-50 py-2 pl-1">
                {BARS_ARRAY.map((level) => (
                    <div
                        key={level}
                        className={`w-2 mr-1 ${
                            networkQualityLevel > level
                                ? 'bg-white'
                                : 'bg-white opacity-20'
                        }`}
                        style={{ height: `${STEP * (level + 1)}px` }}
                    />
                ))}
            </div>
        </div>
    )
}

export default NetworkQualityLevel
