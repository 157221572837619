import React, {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
} from 'react'
import { Participant } from 'twilio-video'
import { useAppState } from './VideoAppState'

type PaginationContextType = {
    currentPage: number
    totalPages: number
    setCurrentPage: (page: number) => void
    setTotalPages: (page: number) => void
}

const PaginationContext = createContext<PaginationContextType | undefined>(
    undefined
)

type PaginationProviderProps = {
    children: ReactNode
}

export function PaginationProvider({ children }: PaginationProviderProps) {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    return (
        <PaginationContext.Provider
            value={{ currentPage, totalPages, setCurrentPage, setTotalPages }}
        >
            {children}
        </PaginationContext.Provider>
    )
}

export function usePaginationContext() {
    const context = useContext(PaginationContext)
    if (!context) {
        throw new Error(
            'usePaginationContext must be used within a PaginationProvider'
        )
    }
    return context
}

export function usePagination(participants: Participant[]) {
    const { currentPage, setCurrentPage } = usePaginationContext()
    const { maxGalleryViewParticipants } = useAppState()
    const totalPages = Math.ceil(
        participants.length / maxGalleryViewParticipants
    )

    const isBeyondLastPage = currentPage > totalPages

    useEffect(() => {
        if (isBeyondLastPage) {
            setCurrentPage(totalPages)
        }
    }, [isBeyondLastPage, totalPages])

    const paginatedParticipants = participants.slice(
        (currentPage - 1) * maxGalleryViewParticipants,
        currentPage * maxGalleryViewParticipants
    )

    return { paginatedParticipants, setCurrentPage, currentPage, totalPages }
}
