import React, { createContext } from 'react'
import { RemoteParticipant } from 'twilio-video'
/* import useGalleryViewParticipants from '../../hooks/useGalleryViewParticipants/useGalleryViewParticipants';*/
import useGalleryViewParticipants from './useGalleryViewParticipants'
import useSpeakerViewParticipants from './useSpeakerViewParticipants'

/**
 * The purpose of the ParticipantProvider component is to ensure that the hooks useGalleryViewParticipants
 * and useSpeakerViewParticipants are not unmounted as users switch between Gallery View and Speaker View.
 * This will make sure that the ordering of the participants on the screen will remain so that the most
 * recent dominant speakers are always at the front of the list.
 */

export interface IParticipantContext {
    galleryViewParticipants: RemoteParticipant[]
    speakerViewParticipants: RemoteParticipant[]
}

// eslint-disable-next-line
export const ParticipantContext = createContext<IParticipantContext>(null!)

export const ParticipantProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const galleryViewParticipants = useGalleryViewParticipants()
    const speakerViewParticipants = useSpeakerViewParticipants()

    return (
        <ParticipantContext.Provider
            value={{
                galleryViewParticipants,
                speakerViewParticipants,
            }}
        >
            {children}
        </ParticipantContext.Provider>
    )
}
