import React, { useEffect, useState } from 'react'
import api from '../../../../api'
import { useTranslation } from 'react-i18next'
import { Vital } from './VitalsScreen'
import LoadingWheel from '../../../common/LoadingWheel'
import ConfirmationModal from '../../../common/ConfirmationModal'
import { TrashIcon } from '@heroicons/react/24/outline'

interface CreateVitalModalProps {
    handleAddVital: (vitals: Vital[]) => void
    handleModalClose: () => void
    setVitals: (vitals: Vital[]) => void
    setSelectedVital: (selectedVital: Vital | undefined) => void
    selectedVital: Vital | undefined
    selectedOption: string
}

const AddVitalModal: React.FC<CreateVitalModalProps> = ({
    handleAddVital,
    handleModalClose,
    setVitals,
    setSelectedVital,
    selectedVital,
    selectedOption,
}) => {
    const [data, setData] = useState<string>('')
    const [date, setDate] = useState<Date>(new Date())
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [modalDeleteVital, setModalDeleteVital] = useState<boolean>(false)
    const [errorDeleteVital, setErrorDeleteVital] = useState<string>('')
    const { t } = useTranslation()
    const [borderValue, setBorderValue] = useState<number[]>([0, 300])

    const modalClose = () => {
        setData('')
        setDate(new Date())
        handleModalClose()
    }

    const handleModalSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault()
        setIsLoading(true)
        setErrorMessage('')

        try {
            const response = await api.post('/' + selectedOption + '/add', {
                Data: parseFloat(data),
                Date: date.toISOString(),
            })
            if (response.data && response.data.data) {
                handleAddVital(response.data.data.data)
            }
        } catch (error) {
            console.error('Error creating vital:', error)
            const s = t('phone_page.create error')
            setErrorMessage(s)
        }

        setIsLoading(false)
        modalClose()
    }

    const handleDeleteVital = async () => {
        try {
            const response = await api.delete(
                '/' + selectedOption + '/delete/' + selectedVital?.id
            )
            if (response.data && response.data.data) {
                setVitals(response.data.data.data)
                setSelectedVital(undefined)
                setModalDeleteVital(false)
                setErrorDeleteVital('')
                handleModalClose()
            }
            const s = t('vitals_page.delete error')
            setErrorDeleteVital(s)
        } catch (error) {
            console.error('Error deleting vitals:', error)
            const s = t('vitals_page.delete error')
            setErrorDeleteVital(s)
        }
    }

    const modalTitle = (option: string) => {
        switch (option) {
            case 'bloodPressure':
                return `${t('vitals_page.blood_pressure')} (0-200 mmHg)`
            case 'bloodSugar':
                return `${t('vitals_page.blood_sugar')} (1-10 mmol/L)`
            case 'weight':
                return `${t('vitals_page.weight')} (0-300 kg)`

            default:
                break
        }
    }

    const deleteTitle = (option: string) => {
        switch (option) {
            case 'bloodPressure':
                return `${t('vitals_page.blood_pressure')}: `
            case 'bloodSugar':
                return `${t('vitals_page.blood_sugar')}: `
            case 'weight':
                return `${t('vitals_page.weight')}: `

            default:
                break
        }
    }

    const measurment = (option: string) => {
        switch (option) {
            case 'bloodPressure':
                return `(mmHg)`
            case 'bloodSugar':
                return `(mmol/L)`
            case 'weight':
                return `(kg)`

            default:
                break
        }
    }

    useEffect(() => {
        switch (selectedOption) {
            case 'bloodPressure':
                setBorderValue([0, 200])
                break
            case 'bloodSugar':
                setBorderValue([1, 10])
                break
            case 'weight':
                setBorderValue([0, 300])
                break

            default:
                break
        }
    }, [selectedOption])

    return (
        <div className="absolute inset-0 flex items-center justify-center z-50 ">
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={modalClose}
            ></div>
            <div className="bg-white rounded-lg p-6 z-20 w-80">
                <div className="flex justify-between">
                    <p className="text-xl font-semibold mb-4">
                        {modalTitle(selectedOption)}
                    </p>
                </div>
                <form onSubmit={handleModalSubmit}>
                    <div className={`mb-4`}>
                        <label
                            htmlFor="albumName"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('vitals_page.modal_value')}
                        </label>
                        {selectedVital ? (
                            <p className="text-gray-700 text-xl font-medium mb-2">
                                {selectedVital.data +
                                    ' ' +
                                    measurment(selectedOption)}
                            </p>
                        ) : (
                            <input
                                type="text"
                                id="vitalValue"
                                className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                                value={data}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setData('')
                                        return
                                    }
                                    const inputValue = parseFloat(
                                        e.target.value
                                    )
                                    const isValidFloat = /^[+-]?\d+(\.\d{0,2})?$/.test(e.target.value);
                                    if (
                                        !isNaN(inputValue) &&
                                        inputValue >= borderValue[0] &&
                                        inputValue <= borderValue[1] &&
                                        isValidFloat
                                    ) {
                                        setData(e.target.value) // Convert it back to string if it's valid
                                    }
                                }}
                                required
                                min={borderValue[0]}
                                max={borderValue[1]}
                            />
                        )}
                    </div>

                    {errorMessage && (
                        <div className="text-red-500 mb-4">{errorMessage}</div>
                    )}

                    <div className="flex justify-between">
                        <button
                            type="button"
                            className={`mr-2 px-4 py-2 text-white ${
                                selectedVital
                                    ? 'bg-blue-500 hover:bg-blue-600'
                                    : 'bg-red-500 hover:bg-red-600'
                            } rounded-md  focus:outline-none text-xl`}
                            onClick={modalClose}
                        >
                            {t('phone_page.cancel')}
                        </button>
                        {selectedVital ? (
                            <button
                                className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none text-xl"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setModalDeleteVital(true)
                                }}
                            >
                                <TrashIcon className="w-6 h-6" />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none text-xl"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <LoadingWheel className="w-6 h-6" />
                                ) : (
                                    t('phone_page.add')
                                )}
                            </button>
                        )}
                    </div>
                </form>
            </div>
            {modalDeleteVital && (
                <ConfirmationModal
                    message={
                        t('vitals_page.are you sure delete') +
                        deleteTitle(selectedOption) +
                        selectedVital?.data
                    }
                    succesButtonMessage={t('phone_page.delete')}
                    onSuccess={handleDeleteVital}
                    onCancel={() => {
                        setModalDeleteVital(false)
                        setErrorDeleteVital('')
                    }}
                    errorMessage={errorDeleteVital}
                />
            )}
        </div>
    )
}

export default AddVitalModal
