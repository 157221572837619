import {
    ArrowUturnLeftIcon,
    HeartIcon,
    HomeIcon,
    PhoneIcon,
    PuzzlePieceIcon,
    UserIcon,
} from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface FooterProps {
    selectedTab: string
}

export const parseHistory = (): string[] => {
    try {
        const savedLocations = localStorage.getItem('previousLocations')
        const parsedLocations = JSON.parse(savedLocations || '[]')

        if (
            Array.isArray(parsedLocations) &&
            parsedLocations.every((item) => typeof item === 'string')
        ) {
            return parsedLocations
        }

        return []
    } catch (e) {
        localStorage.setItem('previousLocations', JSON.stringify([]))
        return []
    }
}

const Footer: React.FC<FooterProps> = ({ selectedTab }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [previousLocation, setPreviousLocation] = useState<string[]>([])
    useEffect(() => {
        setPreviousLocation(parseHistory())
    }, [])

    useEffect(() => {
        if (previousLocation.length === 0) {
            const parsedLocations = parseHistory()
            if (parsedLocations.length > 0) {
                setPreviousLocation(parsedLocations)
            } else {
                setPreviousLocation([location.pathname])
            }
        } else if (
            location.pathname !== previousLocation[previousLocation.length - 1]
        ) {
            setPreviousLocation([...previousLocation, location.pathname])
            try {
                localStorage.setItem(
                    'previousLocations',
                    JSON.stringify([...previousLocation, location.pathname])
                )
            } catch (e) {
                console.error(e)
            }
        }
    }, [location])

    const handleBack = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.preventDefault()
        if (previousLocation.length > 1) {
            navigate(previousLocation[previousLocation.length - 2])
            setPreviousLocation(previousLocation.slice(0, -1))
            try {
                localStorage.setItem(
                    'previousLocations',
                    JSON.stringify(previousLocation.slice(0, -1))
                )
            } catch (e) {
                console.error(e)
            }
        }
    }

    const tabs = [
        {
            key: 0,
            name: t('footer.back'), // Use the translation key for 'Back'
            icon: <ArrowUturnLeftIcon />,
            icon_color: 'text-white',
            path: '/',
        },
        {
            key: 1,
            name: t('footer.health'), // Use the translation key for 'Health'
            icon: <HeartIcon />,
            icon_color: 'text-pink-600',
            path: '/health',
        },
        {
            key: 2,
            name: t('footer.fun'), // Use the translation key for 'Fun'
            icon: <PuzzlePieceIcon />,
            icon_color: 'text-blue-600',
            path: '/fun',
        },
        {
            key: 3,
            name: t('footer.communication'), // Use the translation key for 'Communication'
            icon: <PhoneIcon />,
            icon_color: 'text-lime-600',
            path: '/communication',
        },
        {
            key: 4,
            name: t('footer.personal'), // Use the translation key for 'Personal'
            icon: <UserIcon />,
            icon_color: 'text-orange-600',
            path: '/personal',
        },
        {
            key: 5,
            name: t('footer.home'), // Use the translation key for 'Home'
            icon: <HomeIcon />,
            icon_color: 'text-white',
            path: '/',
        },
    ]
    return (
        <footer className="fixed bottom-0 w-full z-footer h-footer-sm md:h-footer-md lg:h-footer-lg xl:h-footer-xl 2xl:h-footer-2xl">
            {/* Flex row */}
            {/* <div className="flex justify-evenly"> */}
            <div className="grid grid-cols-6 h-full">
                {/* Flex items */}
                {tabs.map((tab) => (
                    <Link
                        to={tab.path}
                        key={tab.key}
                        onClick={tab.key === 0 ? handleBack : undefined}
                    >
                        <div
                            className={
                                (tab.key === Number(selectedTab)
                                    ? 'bg-white-600'
                                    : 'bg-transparent/40') +
                                ' flex flex-col justify-center gap-2 h-full px-4'
                            }
                        >
                            <span
                                className={
                                    (tab.key === Number(selectedTab)
                                        ? 'text-white'
                                        : tab.icon_color) +
                                    ' self-center w-12 md:w-14 lg:w-16 xl:w-20'
                                }
                            >
                                {tab.icon}
                            </span>
                            <span className="text-center text-base md:text-xl lg:text-xl xl:text-2xl font-medium text-white truncate">
                                {tab.name}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
        </footer>
    )
}

export default Footer
