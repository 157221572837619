import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../context/auth/AuthContext'
import { useSettings } from '../hooks/useSettings'
import { useTranslation } from 'react-i18next'
import { parseHistory } from './layout/Footer'

type Props = {
    children: JSX.Element
}

function RequirePin({ children }: Props) {
    const { loading } = useAuthState()
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [enteredPin, setEnteredPin] = useState('')

    const [settings] = useSettings()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnteredPin(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (settings?.pinCode === enteredPin) {
            setIsAuthenticated(true)
        } else {
            alert(t('settings_page.requirePin.invalid pin'))
        }
    }
    const handleCancel = () => {
        const previousLocation = parseHistory()
        if (previousLocation.length > 0) {
            navigate(previousLocation[previousLocation.length - 1])
        } else {
            navigate('/')
        }
    }

    if (loading) {
        return <div>LOADING...</div>
    }

    return isAuthenticated ? (
        children
    ) : (
        <div className="relative z-10 ">
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 border">
                        <div>
                            <div className="mt-3 text-center sm:mt-5">
                                <p className="text-lg font-medium leading-6 text-gray-900">
                                    {t('settings_page.requirePin.pin required')}
                                </p>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {t(
                                            'settings_page.requirePin.input your'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-4">
                                <div className="mt-1">
                                    <input
                                        type="password"
                                        name="pin"
                                        id="pin"
                                        onChange={handlePinChange}
                                        className="block w-[1/2]  mx-auto rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-700  col-start-2 "
                                >
                                    {t('settings_page.requirePin.submit')}
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCancel}
                                    className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:col-start-1 mt-0 "
                                >
                                    {t('settings_page.requirePin.cancel')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequirePin
