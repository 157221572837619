import React from 'react'
import {
    GALLERY_VIEW_ASPECT_RATIO,
    GALLERY_VIEW_MARGIN,
} from '../../utils/constants'
import Participant from '../Participant/Participant'
import useGalleryViewLayout from '../../hooks/Room/useGalleryViewLayout'
import useVideoContext from '../../hooks/Video/useVideoContext'
import useParticipantsContext from '../../hooks/Participants/useParticipantContext'
import { usePagination } from '../../hooks/usePagination'
import useDominantSpeaker from '../../hooks/Participants/useDominantSpeaker'
import { useAppState } from '../../hooks/VideoAppState'

export function GalleryView() {
    //const [currentPage, setCurrentPage] = useState(1);
    const { maxGalleryViewParticipants } = useAppState()
    const { room } = useVideoContext()
    const { galleryViewParticipants } = useParticipantsContext()
    const dominantSpeaker = useDominantSpeaker(true)

    const { paginatedParticipants, currentPage } = usePagination(
        room
            ? [room.localParticipant, ...galleryViewParticipants]
            : galleryViewParticipants
    )

    const galleryViewLayoutParticipantCount =
        currentPage === 1
            ? paginatedParticipants.length
            : maxGalleryViewParticipants
    const { participantVideoWidth, containerRef } = useGalleryViewLayout(
        galleryViewLayoutParticipantCount
    )

    const participantWidth = `${participantVideoWidth}px`
    const participantHeight = `${Math.floor(
        participantVideoWidth * GALLERY_VIEW_ASPECT_RATIO
    )}px`

    return (
        <div className={`flex h-full  w-full relative items-center`}>
            <div
                className={`flex flex-wrap items-center justify-center h-full w-full max-h-[98%]`}
                ref={containerRef}
            >
                {paginatedParticipants.map((participant) => (
                    <div
                        key={participant.sid}
                        style={{
                            width: participantWidth,
                            height: participantHeight,
                            margin: GALLERY_VIEW_MARGIN,
                        }}
                        className={`m-${GALLERY_VIEW_MARGIN}`}
                    >
                        <Participant
                            participant={participant}
                            isLocalParticipant={
                                room
                                    ? participant === room.localParticipant
                                    : false
                            }
                            isDominantSpeaker={participant === dominantSpeaker}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
