import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthState } from '../context/auth/AuthContext'

type Props = {
    children: JSX.Element
}

function RequireAuth({ children }: Props) {
    const { authenticated, loading } = useAuthState()

    if (loading) {
        return <div>LOADING...</div>
    }

    return authenticated ? children : <Navigate to="/login" replace />
}

export default RequireAuth
