import { useEffect, useRef } from 'react'
import { AudioTrack as IAudioTrack } from 'twilio-video'
import { useAppState } from '../VideoAppState'

interface AudioTrackProps {
    track: IAudioTrack
}

export default function AudioTrack({ track }: AudioTrackProps) {
    const { activeSinkId } = useAppState()
    const audioEl = useRef<HTMLAudioElement>()

    useEffect(() => {
        audioEl.current = track.attach()
        audioEl.current.setAttribute('data-cy-audio-track-name', track.name)
        document.body.appendChild(audioEl.current)
        return () =>
            track.detach().forEach((el) => {
                el.remove()

                // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
                // See: https://github.com/twilio/twilio-video.js/issues/1528
                el.srcObject = null
            })
    }, [track])

    useEffect(() => {
        try {
            //@ts-expect-error [Ignoring the error since setSinkId might not be recognized by TypeScript]
            audioEl.current?.setSinkId?.(activeSinkId)
        } catch (e) {
            console.error('Error setting sink id: ', e)
        }
    }, [activeSinkId])

    return null
}
