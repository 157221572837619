import React, { useContext, useEffect, useReducer } from 'react'
import { useVolumeTarget } from '../volume/VolumeContext'
import radioStations from './RadioStations'

const volumeStep = 0.05

type RadioState = {
    radioPlayer: HTMLAudioElement
    selectedStationSrc: string | null
    isPlaying: boolean
    volume: number
    stations: {
        title: string
        src: string
        img: string
    }[]
}

type RadioAction =
    | { type: 'SELECT_STATION'; src: string }
    | { type: 'PAUSE' }
    | { type: 'PLAY' }
    | { type: 'TOGGLE_PLAY' }
    | { type: 'SET_VOLUME'; volume: number }
    | { type: 'VOLUME_UP' }
    | { type: 'VOLUME_DOWN' }
    | { type: 'VOLUME_MUTE' }

const RadioStateContext = React.createContext<RadioState | undefined>(undefined)
const RadioDispatchContext = React.createContext<
    React.Dispatch<RadioAction> | undefined
>(undefined)

function pause(state: RadioState): RadioState {
    state.radioPlayer.pause()
    return {
        ...state,
        isPlaying: false,
    }
}

function play(state: RadioState): RadioState {
    state.radioPlayer.play()
    return {
        ...state,
        isPlaying: true,
    }
}

function radioReducer(state: RadioState, action: RadioAction): RadioState {
    switch (action.type) {
        case 'SELECT_STATION': {
            const { radioPlayer, selectedStationSrc } = state
            radioPlayer.pause()
            if (action.src && action.src !== selectedStationSrc) {
                radioPlayer.src = action.src
                setTimeout(() => {
                    radioPlayer.play().catch((error) => {
                        console.error('Failed to play audio:', error)
                    })
                }, 10) // Delay play request by 10 milliseconds
            }

            return {
                ...state,
                selectedStationSrc: action.src,
                isPlaying: true,
            }
        }
        case 'PAUSE': {
            return pause(state)
        }
        case 'PLAY': {
            return play(state)
        }
        case 'TOGGLE_PLAY': {
            return state.isPlaying ? pause(state) : play(state)
        }
        case 'SET_VOLUME': {
            return {
                ...state,
                volume: action.volume,
            }
        }
        case 'VOLUME_UP': {
            const newVolume =
                state.volume + volumeStep > 1 ? 1 : state.volume + volumeStep
            return {
                ...state,
                volume: newVolume,
            }
        }
        case 'VOLUME_DOWN': {
            const newVolume =
                state.volume - volumeStep < 0 ? 0 : state.volume - volumeStep
            return {
                ...state,
                volume: newVolume,
            }
        }
        case 'VOLUME_MUTE': {
            return {
                ...state,
                volume: 0,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

const initialState: RadioState = {
    radioPlayer: new Audio(),
    selectedStationSrc: null,
    isPlaying: false,
    volume: 0.5,
    stations: radioStations,
}

interface RadioProviderProps {
    children: React.ReactNode
}

const RadioProvider: React.FC<RadioProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(radioReducer, initialState)

    const volumeTarget = useVolumeTarget()

    useEffect(() => {
        state.radioPlayer.volume = state.volume
    })

    useEffect(() => {
        if (volumeTarget !== 'radio') {
            dispatch({ type: 'PAUSE' })
        }
    }, [volumeTarget])

    return (
        <RadioStateContext.Provider value={state}>
            <RadioDispatchContext.Provider value={dispatch}>
                {children}
            </RadioDispatchContext.Provider>
        </RadioStateContext.Provider>
    )
}

function useRadioState(): RadioState {
    const context = useContext(RadioStateContext)
    if (context === undefined) {
        throw new Error('useRadioState must be used within a RadioProvider')
    }
    return context
}

function useRadioDispatch(): React.Dispatch<RadioAction> {
    const context = useContext(RadioDispatchContext)
    if (context === undefined) {
        throw new Error('useRadioDispatch must be used within a RadioProvider')
    }
    return context
}

export { RadioProvider, useRadioState, useRadioDispatch }
