import React from 'react'
import { Email } from './EmailScreen'

interface Props {
    mails: Email[]
    fetchMailDetails: (mailId: string) => void
    selectedId: string
}

const returnDate = (date: string) => {
    const timestamp = new Date(date)

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
        dayPeriod: 'short',
    }
    const formattedDate = timestamp.toLocaleString(undefined, options)
    const formattedTime = timestamp.toLocaleString(undefined, timeOptions)
    return formattedTime + ' ' + formattedDate
}

const returnSender = (sender: string) => {
    // check if < exists in the sender string
    if (sender.includes('<')) {
        const senderName = sender.substring(0, sender.indexOf('<')).trim()
        const senderEmail = sender
            .substring(sender.indexOf('<') + 1, sender.length - 1)
            .trim()
        return (
            <>
                <div className="text-blue-400 text-xl mt-4 truncate h-8 shrink-0">
                    {senderName}
                </div>
                <div className="text-blue-950 text-md truncate h-6 shrink-0">
                    {senderEmail}
                </div>
            </>
        )
    } else {
        return (
            <div className="text-blue-400 text-xl mt-4 truncate h-8 shrink-0">
                {sender}
            </div>
        )
    }
}

const EmailFeed = React.forwardRef<HTMLDivElement, Props>(
    ({ mails, fetchMailDetails, selectedId }, ref) => {
        return (
            <div className="overflow-auto flex flex-col " ref={ref}>
                {mails.map((email: Email, index: number) => (
                    <div
                        key={index}
                        className={`flex flex-col justify-left h-40 border-b p-2 relative scroll-child cursor-pointer break-words ${
                            selectedId === email.id
                                ? 'bg-zinc-600/90'
                                : 'bg-zinc-600/50 hover:bg-zinc-600/70'
                        }`}
                        onClick={() => fetchMailDetails(email.id)}
                    >
                        <div className="text-gray-900 text-md absolute right-1 top-1 font-medium ">
                            {returnDate(email.date)}
                        </div>
                        {returnSender(email.senderEmail)}
                        <div className="text-white font-medium text-xl w-full overflow-hidden">
                            {email.subject}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
)
EmailFeed.displayName = 'EmailFeed'

export default EmailFeed
