import React, { useEffect, useState, createRef } from 'react'
import NewsFeed from '../homeComponents/news/NewsFeed'
import WeatherWidget from '../homeComponents/weather/WeatherWidget'
import NotificationsFeed from '../homeComponents/notifications/NotificationsFeed'

import { News } from '../../utils/GlobalInterface'
import { getNews } from '../../utils/requests/getNews'
import ScrollFeedButtons from '../common/scrollComponents/ScrollFeedButtons'
import { useTranslation } from 'react-i18next'

const HomeScreen: React.FC = () => {
    const [news, setNews] = useState<News[]>([])
    const { t } = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getNews()
                setNews(response as News[])
            } catch (error) {
                console.error('Error fetching news:', error)
            }
        }
        fetchData()
    }, [])

    const title = (titleKey: string) => {
        const translatedTitle = t(`home_screen.${titleKey}`) // Translate the title
        return (
            <div className="text-3xl md:text-4xl xl:text-5xl font-medium text-white mb-2">
                {translatedTitle}
            </div>
        )
    }

    const newsScrollContainerRef = createRef<HTMLDivElement>()
    const weatherScrollContainerRef = createRef<HTMLDivElement>()
    const notificationsScrollContainerRef = createRef<HTMLDivElement>()

    return (
        <div className="flex justify-evenly gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex flex-col w-full gap-2">
                {title('news')}
                <NewsFeed news={news} ref={newsScrollContainerRef} />
                <div className="flex justify-center mt-2">
                    <ScrollFeedButtons
                        scrollContainerRef={newsScrollContainerRef}
                    />
                </div>
            </div>
            <div className="flex flex-col w-full gap-2">
                {title('weather')}
                <WeatherWidget ref={weatherScrollContainerRef} />
                <div className="flex justify-center mt-2">
                    <ScrollFeedButtons
                        scrollContainerRef={weatherScrollContainerRef}
                    />
                </div>
            </div>
            <div className="flex flex-col w-full gap-2">
                {title('notifications')}
                <NotificationsFeed ref={notificationsScrollContainerRef} />
                <div className="flex justify-center mt-2">
                    <ScrollFeedButtons
                        scrollContainerRef={notificationsScrollContainerRef}
                    />
                </div>
            </div>
        </div>
    )
}

export default HomeScreen
