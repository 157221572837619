import React from 'react'
import VolumeControl from '../VolumeControl'
import CurrentDateTime from '../CurrentDateTime'
import SettingsButton from '../settingsComponents/SettingsButton'
import { useTwilioDevice } from '../../context/Twilio/TwilioDeviceContext'
import { PhoneIcon, PhoneXMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoutButton from '../common/LogoutButton'

import SpotifyPlayerComponent from '../common/SpotifyPlayer/SpotifyPlayer'

const Sidebar: React.FC = () => {
    const { t } = useTranslation()
    const { incomingCall, call } = useTwilioDevice()
    return (
        <div className="flex flex-col gap-2 xl:gap-4 pb-4 px-4 pt-2 md:px-8 md:pt-4 lg:px-10 lg:pt-6 xl:px-14 xl-pt-12 h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl overflow-hidden">
            <div className='flex justify-end'>
            <SettingsButton />
            <LogoutButton />
            </div>
            <div
                className={`flex h-[4.5rem] `}
            >
            <SpotifyPlayerComponent />


                {incomingCall && (
                    <Link
                        to="/communication/phone"
                        className="flex border border-white rounded border-2 text-white hover:bg-zinc-600/70  items-center shrink w-full"
                    >
                        <PhoneIcon className=" animate-bounce w-12 h-12 mt-3 ml-1 self-center " />

                        <span className="w-full text-xl p-2 truncate shrink">
                            {incomingCall.parameters.From
                                ? incomingCall.parameters.From
                                : t('phone_page.unknown caller')}
                        </span>
                    </Link>
                )}
                {call && (
                    <button
                        onClick={() => call.disconnect()}
                        className="flex border border-white rounded border-2 text-white bg-red-600 hover:bg-red-700  items-center shrink w-full"
                    >
                        <PhoneXMarkIcon className=" w-12 h-12  ml-1 self-center " />
                        <span className="w-full text-xl p-2 truncate shrink">
                            {call.parameters.From
                                ? call.parameters.From
                                : call.customParameters.get('To')
                                ? call.customParameters.get('To')
                                : t('phone_page.unknown caller')}
                        </span>
                    </button>
                )}

            </div>
            <CurrentDateTime />
            <VolumeControl />
        </div>
    )
}

export default Sidebar
