import React, { useState } from 'react'
import LoadingWheel from '../../../../common/LoadingWheel'
import { useSpotify } from '../../../../../context/spotify/SpotifyPlayerProvider'
import { useTranslation } from 'react-i18next'

interface CreateAlbumModalProps {
    handleModalClose: () => void
}

const CreatePlaylistModal: React.FC<CreateAlbumModalProps> = ({
    handleModalClose,
}) => {
    const { spotifyApi, updatePlaylist } = useSpotify()

    const [name, setName] = useState<string>(
        updatePlaylist ? updatePlaylist.name : ''
    )

    const [description, setDescription] = useState<string>(
        updatePlaylist?.description ? updatePlaylist.description : ''
    )

    const [publicPlaylist, setPublicPlaylist] = useState<boolean>(
        updatePlaylist?.public ? updatePlaylist.public : false
    )

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const { t } = useTranslation()

    const modalClose = () => {
        setName('')
        setDescription('')
        setPublicPlaylist(false)
        handleModalClose()
    }

    const handleModalSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault()
        setIsLoading(true)
        setErrorMessage('')

        try {
            const response = updatePlaylist
                ? await spotifyApi?.changePlaylistDetails(updatePlaylist.id, {
                      name: name,
                    description: description || undefined,
                      public: publicPlaylist,
                  })
                : await spotifyApi?.createPlaylist(name, {
                      description: description,
                      public: publicPlaylist,
                  })
            if (response?.statusCode === 200 || response?.statusCode === 201) {
                modalClose()
            }
        } catch (error) {
            if (updatePlaylist) {
                console.error('Error updating playlist:', error)
                const s = t('music_page.error updating playlist')
                setErrorMessage(s)
            } else {
                console.error('Error creating playlist:', error)
                const s = t('music_page.error creating playlist')
                setErrorMessage(s)
            }
        }

        setIsLoading(false)
    }

    return (
        <div className="absolute inset-0 flex items-center justify-center z-50 ">
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={modalClose}
            ></div>
            <div className="bg-white rounded-lg p-6 z-20 w-80">
                <h2 className="text-3xl font-semibold mb-4">
                    {updatePlaylist
                        ? t('music_page.update playlist')
                        : t('music_page.create playlist')}
                </h2>
                <form onSubmit={handleModalSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="albumName"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('music_page.playlist name')}
                        </label>
                        <input
                            type="text"
                            id="albumName"
                            className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="albumName"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('music_page.description')}
                        </label>
                        <input
                            type="text"
                            id="albumName"
                            className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                                setErrorMessage('')
                            }}
                        />
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="publicPlaylist"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('music_page.public')}
                        </label>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="publicPlaylist"
                                checked={publicPlaylist}
                                onChange={(e) =>
                                    setPublicPlaylist(e.target.checked)
                                }
                            />
                            <label htmlFor="publicPlaylist" className="ml-2">
                                {publicPlaylist
                                    ? t('music_page.public')
                                    : t('music_page.private')}
                            </label>
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="text-red-500 mb-4">{errorMessage}</div>
                    )}

                    <div className="flex justify-between">
                        <button
                            type="button"
                            className="mr-2 px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none text-xl"
                            onClick={modalClose}
                        >
                            {t('phone_page.cancel')}
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none text-xl"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <LoadingWheel className="w-6 h-6" />
                            ) : updatePlaylist ? (
                                t('phone_page.update')
                            ) : (
                                t('phone_page.add')
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreatePlaylistModal
