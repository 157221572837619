import React from 'react'
import ConfirmationModal from '../../../../common/ConfirmationModal'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

export default function usePrompt({
    when,
    message,
    onConfirm,
    onCancel,
    showLeaveConfirmation,
    setShowLeaveConfirmation,
}: {
    when: boolean
    message: string
    onConfirm: () => void
    onCancel: () => void
    showLeaveConfirmation: boolean
    setShowLeaveConfirmation: (show: boolean) => void
}) {
    const blocker = useBlocker(when)

    React.useEffect(() => {
        if (blocker.state === 'blocked') {
            setShowLeaveConfirmation(true)
        }
    }, [blocker])

    React.useEffect(() => {
        if (blocker.state === 'blocked' && !when) {
            blocker.reset()
        }
    }, [blocker, when])

    const handleConfirm = () => {
        onConfirm()
        setShowLeaveConfirmation(false)
        if (blocker.state === 'blocked') {
            try {
                blocker.proceed()
            } catch (e) {
                console.error(e)
            }
        }
    }

    const handleCancel = () => {
        onCancel()
        setShowLeaveConfirmation(false)
        if (blocker.state === 'blocked') {
            blocker.reset()
        }
    }

    return (
        <>
            {showLeaveConfirmation && (
                <ConfirmationModal
                    succesButtonMessage="Leave"
                    message={message}
                    onSuccess={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </>
    )
}
