import React, { useState } from 'react'
import { getColorById } from './CalendarScreen'

interface ColorOption {
    id: string
    className: string
}

interface ColorSelectProps {
    value: string
    options: ColorOption[]
    onChange: (value: string) => void
}

const ColorSelect: React.FC<ColorSelectProps> = ({
    value,
    options,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleSelect = (selectedValue: string) => {
        onChange(selectedValue)
        setIsOpen(false)
    }

    return (
        <div className="relative">
            <button
                className="flex w-9 p-1 rounded-lg h-9  border-2 border-gray-400 "
                onClick={(event: React.FormEvent) => {
                    event.preventDefault()

                    setIsOpen(!isOpen)
                }}
            >
                <div
                    className={`w-5 h-5 rounded-full ml-0.5 mt-0.5 ${getColorById(
                        value
                    )}`}
                ></div>
            </button>
            {isOpen && (
                <div className="absolute flex z-50 top-0 left-9 p-0.5 h-9  w-18 bg-white border border-gray-300 rounded-lg shadow-lg">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            className="flex items-center py-2 px-3 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleSelect(option.id)}
                        >
                            <div
                                className={`w-5 h-5 rounded-full  ${option.className}`}
                            ></div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ColorSelect
