import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSmsDevice } from '../../../../../../context/Twilio/SmsDeviceContext'
import { Conversation } from '@twilio/conversations'
import ScrollFeedButtons from '../../../../../common/scrollComponents/ScrollFeedButtons'
import LoadingWheel from '../../../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

export function InviteButton({
    conversation,
    roomName,
}: {
    conversation: Conversation
    roomName: string
}) {
    const [loadingSendInvite, setLoadingSendInvite] = useState(false)
    const [sendError, setSendError] = useState('')

    const { t } = useTranslation()

    const inviteUser = async (convo: Conversation) => {
        const message =
            t('video_page.invite you') +
            roomName +
            t('video_page.join me in') +
            window.location.href
        setLoadingSendInvite(true)
        try {
            if (convo) {
                await convo.sendMessage(message)
            }
        } catch (error) {
            const msg = t('video_page.send error')
            setSendError(msg)

            setTimeout(() => {
                setSendError('')
            }, 3000)
        }
        setLoadingSendInvite(false)
    }

    return (
        <div className="flex items-center py-1 ">
            <span className="text-lg text-red-500 truncate mr-2">
                {sendError}
            </span>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold  px-5 text-xl rounded float-right w-32 h-full"
                onClick={() => {
                    if (!loadingSendInvite) {
                        inviteUser(conversation)
                    }
                }}
            >
                {loadingSendInvite ? (
                    <LoadingWheel className="w-8 h-8 ml-2" />
                ) : (
                    t('video_page.invite')
                )}
            </button>
        </div>
    )
}

export default function InviteUsersModal({
    onClose,
    roomName,
}: {
    open: boolean
    onClose: () => void
    roomName: string
}) {
    const { client, conversations } = useSmsDevice()
    const convScrollContainerRef = React.createRef<HTMLDivElement>()
    const [filteredConvos, setFilteredConvos] = useState<Conversation[]>([])

    const handleClose = () => {
        onClose()
    }

    const { t } = useTranslation()

    useEffect(() => {
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

        // Ensure conversations is not undefined before filtering
        if (conversations) {
            setFilteredConvos(
                conversations.filter((convo) =>
                    emailRegex.test(checkFriendlyName(convo))
                )
            )
        }
    }, [conversations])

    const checkFriendlyName = (conversation: Conversation): string => {
        try {
            if (conversation?.friendlyName && client?.user) {
                //The name is set by the user who created the conversation, it is set to the identety of the user he is sending a message to
                //If the user is the one who did not created the conversation, we need to find the other users identety and show it as the name
                if (client.user.identity === conversation.friendlyName) {
                    for (const participant of Array.from(
                        conversation._participants.values()
                    )) {
                        if (participant.identity !== client.user.identity) {
                            participant.lastReadMessageIndex
                            return participant.identity || ''
                        }
                    }
                } else {
                    return conversation.friendlyName
                }
            }
        } catch (e) {
            console.error(e)
        }

        return t('video_page.no name')
    }

    return (
        <>
            <div
                className="absolute bg-black/20 h-full w-full z-10"
                onClick={onClose}
            ></div>
            <div
                className={
                    'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white p-5 rounded-lg z-50'
                }
                style={{ width: '600px', minHeight: '400px' }}
            >
                <h2 className="text-2xl font-semibold">
                    {t('video_page.invite users')}
                </h2>
                <div className="text-lg">
                    {t('video_page.option instruction')}
                </div>
                <hr className="border-gray-500 border-1" />

                <div>
                    <div
                        className="overflow-auto flex flex-col h-80"
                        ref={convScrollContainerRef}
                    >
                        {filteredConvos?.length > 0 ? (
                            filteredConvos?.map(
                                (conversation: Conversation, index: number) => (
                                    <div
                                        key={index}
                                        className={`flex  justify-between h-20 border-b p-2 relative scroll-child break-words `}
                                    >
                                        <div className="text-blue-400 text-2xl mt-4 truncate h-10 mr-14">
                                            {checkFriendlyName(conversation)}
                                        </div>
                                        <InviteButton
                                            conversation={conversation}
                                            roomName={roomName}
                                        />
                                    </div>
                                )
                            )
                        ) : (
                            <div className="flex text-xl items-center justify-center h-full">
                                {' '}
                                {t('video_page.no invitable users')}{' '}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-between  mt-4">
                    <ScrollFeedButtons
                        scrollContainerRef={convScrollContainerRef}
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-5 text-xl rounded float-right"
                        onClick={handleClose}
                    >
                        {t('video_page.done')}
                    </button>
                </div>
            </div>
        </>
    )
}
