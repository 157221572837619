import React, { useEffect, useState } from 'react'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid'
import api from '../../api'
import { useCookies } from 'react-cookie'
import { useAuthDispatch } from '../../context/auth/AuthContext'
import ConfirmationModal from './ConfirmationModal'
import { useTranslation } from 'react-i18next'

const LogoutButton: React.FC = () => {
    const dispatch = useAuthDispatch()
    const [, , removeSession] = useCookies(['mysession'])
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState<string>('')

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    function isAxiosError(error: any): error is import('axios').AxiosError {
        return 'isAxiosError' in error;
    }

    useEffect(() => {
        setErrorMessage('')
    }, [showModal])

    const handleLogout = async () => {
        try {
            const response = await api.get('/auth/logout')
            if (response.status === 200 || response.status === 401) {
                removeSession('mysession')
                dispatch({ type: 'LOGOUT' })
            }
        } catch (e) {
            console.error(e);
            if (isAxiosError(e) && e?.response?.status === 401) {
                removeSession('mysession');
                dispatch({ type: 'LOGOUT' });
            }else{
                setErrorMessage(t('log_out.log out fail') || '')
            }
        }
    }
    return (
        <>
            <div className="flex justify-end">
                <button
                    onClick={() => setShowModal(true)}
                    className="text-white hover:text-gray-300"
                >
                    <ArrowRightOnRectangleIcon
                        className="h-16 w-16"
                        aria-hidden="true"
                    />
                </button>
            </div>
            {showModal && (
                <ConfirmationModal
                    message={t('log_out.log out confirmation')}
                    succesButtonMessage={t('log_out.log out')}
                    onSuccess={handleLogout}
                    onCancel={() => setShowModal(false)}
                    errorMessage={errorMessage}
                />
            )}
        </>
    )
}

export default LogoutButton
