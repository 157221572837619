import React, { useContext, useState, useCallback } from 'react'

interface VolumeState {
  volumeTarget: string;
  setVolumeTarget: (target: string) => void;
  volume: number;
  setVolume: (volume: number) => void
  prevVolume: number;
  setPrevVolume: (volume: number) => void,
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void
}

const VolumeStateContext = React.createContext<VolumeState | null>(null)

interface VolumeProviderProps {
    children: React.ReactNode
}
const initialVolume = 50
const VolumeProvider: React.FC<VolumeProviderProps> = ({ children }) => {
  const [volumeTarget, setVolumeTargetState] = useState('radio');
  const [volume, setVolume] = useState(initialVolume);
  const [prevVolume, setPrevVolume] = useState(initialVolume)
  const [isMuted, setIsMuted] = useState(false)

    const setVolumeTarget = useCallback((target: string) => {
        target = target || 'radio'
        setVolumeTargetState(target)
    }, [])

  const value: VolumeState = {
    volumeTarget,
    setVolumeTarget,
    volume,
    setVolume,
    prevVolume,
    setPrevVolume,
    isMuted,
    setIsMuted
  };

    return (
        <VolumeStateContext.Provider value={value}>
            {children}
        </VolumeStateContext.Provider>
    )
}

function useVolumeTarget(): string {
    const context = useContext(VolumeStateContext)
    if (!context) {
        throw new Error('useVolumeTarget must be used within a VolumeProvider')
    }
    return context.volumeTarget
}

function useSetVolumeTarget(): (target: string) => void {
    const context = useContext(VolumeStateContext)
    if (!context) {
        throw new Error(
            'useSetVolumeTarget must be used within a VolumeProvider'
        )
    }
    return context.setVolumeTarget
}

function useVolume(): number {
    const context = useContext(VolumeStateContext)
    if (!context) {
        throw new Error('useVolume must be used within a VolumeProvider')
    }
    return context.volume
}

function useSetVolume(): (volume: number) => void {
    const context = useContext(VolumeStateContext)
    if (!context) {
        throw new Error('useSetVolume must be used within a VolumeProvider')
    }
    return context.setVolume
}

function usePrevVolume(): [number, (volume: number) => void] {
  const context = useContext(VolumeStateContext);
  if (!context) {
    throw new Error('usePrevVolume must be used within a VolumeProvider');
  }
  return [context.prevVolume, context.setPrevVolume];
}

function useIsMuted(): [boolean, (isMuted: boolean) => void] {
  const context = useContext(VolumeStateContext);
  if (!context) {
    throw new Error('usePrevVolume must be used within a VolumeProvider');
  }
  return [context.isMuted, context.setIsMuted];
}

export { VolumeProvider, useVolumeTarget, useSetVolumeTarget, useVolume, useSetVolume, usePrevVolume, useIsMuted };
