import { createContext, useContext } from 'react'
import { DispatchContextValue, Settings } from './Types'

export const SettingsContext = createContext<Settings | undefined>(undefined)

export const DispatchSettingsContext = createContext<DispatchContextValue>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => {},
})

export const useSettingsState = () => useContext(SettingsContext)

export const useSettingsDispatch = () => {
    const dispatchContext = useContext(DispatchSettingsContext)
    if (dispatchContext === null) {
        throw new Error(
            'useSettingsDispatch must be used within a SettingsProvider'
        )
    }
    return dispatchContext.dispatch
}
