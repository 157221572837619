import React, { PropsWithChildren, useEffect, useState } from 'react'
import { TwilioError } from 'twilio-video'

interface ErrorDialogProps {
    dismissError: () => void
    error: TwilioError | Error | null
}

function ErrorDialog({
    dismissError,
    error,
}: PropsWithChildren<ErrorDialogProps>) {
    const { message } = error || {}
    const [dismissed, setDismissed] = useState(false)

    useEffect(() => {
        let dismissTimer: ReturnType<typeof setTimeout>

        if (error && !dismissed) {
            dismissTimer = setTimeout(() => {
                dismissError()
                setDismissed(true)
            }, 5000)
        }

        return () => {
            clearTimeout(dismissTimer)
        }
    }, [error, dismissError, dismissed])

    return (
        <div
            className={`fixed inset-x-0 top-2 flex items-center justify-center z-50 ${
                error ? 'block' : 'hidden'
            }`}
        >
            <div className="flex items-center bg-white rounded-lg p-4 ">
                <div className="flex-1 text-center ">
                    <h2 className=" text-red-600 text-lg font-semibold">
                        ERROR:
                    </h2>
                </div>
                <div>
                    <p className="text-gray-700 text-xl mx-4">{message}</p>
                </div>
                <div className="flex-1 text-end">
                    <button
                        onClick={() => {
                            setDismissed(true)
                            dismissError()
                        }}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ErrorDialog
