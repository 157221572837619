import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import NewsFeed from '../../../homeComponents/news/NewsFeed'

import { News } from '../../../../utils/GlobalInterface'
import { getNews } from '../../../../utils/requests/getNews'

import NewsDetails from '../../../homeComponents/news/NewsDetails'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import { useTranslation } from 'react-i18next'

const MediaScreen: React.FC = () => {
    const [news, setNews] = useState<News[]>([])
    const [article, setArticle] = useState<News>()
    const { id } = useParams()
    const { t } = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: News[] = await getNews()
                setNews(response)
            } catch (error) {
                console.error('Error fetching news:', error)
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        const articleId = Number(id)
        if (news && articleId >= 0 && articleId < news.length) {
            setArticle(news[articleId])
        } else {
            setArticle(undefined)
        }
    }, [id, news])
    const newsFeedContainerRef = useRef<HTMLDivElement>(null)
    const detailsContainerRef = useRef<HTMLDivElement>(null)

    return (
        <div className="flex flex-col gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="w-full text-6xl font-medium text-gray-300">
                {t('fun_screen.media')}
            </div>
            <div className="flex overflow-hidden gap-4">
                <div className="flex flex-col w-2/6 overflow-hidden">
                    <div className="overflow-auto" ref={newsFeedContainerRef}>
                        <NewsFeed news={news} />
                    </div>
                    <div className="mt-2 self-center">
                        <ScrollFeedButtons
                            scrollContainerRef={newsFeedContainerRef}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-4/6 overflow-hidden">
                    <div className="overflow-auto" ref={detailsContainerRef}>
                        {id ? (
                            article ? (
                                <NewsDetails
                                    title={article.title}
                                    description={article.description}
                                    image={article.image}
                                    url={article.url}
                                    author={article.author}
                                    category={article.category}
                                    source={article.source}
                                    className="scroll-child"
                                />
                            ) : (
                                <div className="text-6xl font-medium text-gray-300 mb-6">
                                    {t('media_page.article does not exist')}
                                </div>
                            )
                        ) : (
                            <div className="text-6xl font-medium text-gray-300 mb-6">
                                {t('media_page.no article selected')}
                            </div>
                        )}
                    </div>
                    <div className="mt-2 self-center">
                        <ScrollFeedButtons
                            scrollContainerRef={detailsContainerRef}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
    /* return (
        <>
            <div className="flex gap-4 justify-center drop-shadow-md h-full">
                <div className="w-2/6">
                    <NewsFeed news={news} />
                </div>
                <div className="w-4/6 h-144 ">
                    <div className="flex-1 overflow-auto mt-5">
                        {id ? (
                            article ? (
                                <NewsDetails
                                    title={article.title}
                                    description={article.description}
                                    image={article.image}
                                    url={article.url}
                                    author={article.author}
                                    category={article.category}
                                    source={article.source}
                                />
                            ) : (
                                <div className="text-6xl font-medium text-gray-300 mb-6">
                                    {'Article does not exist'}
                                </div>
                            )
                        ) : (
                            <div className="text-6xl font-medium text-gray-300 mb-6">
                                {'No article selected'}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    ) */
}

export default MediaScreen
