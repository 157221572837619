import { format, getDate } from 'date-fns'

export interface Event {
    attachments?: Attachment[]
    colorId?: string
    conferenceData?: {
        conferenceId: string
        conferenceSolution: {
            iconUri: string
        }
        entryPoints: EntryPoint[]
    }
    created?: string
    creator?: {
        email: string
        self: boolean
    }
    description?: string
    end: {
        dateTime: string
        timeZone: string
    }
    etag?: string
    eventType?: string
    hangoutLink?: string
    htmlLink?: string
    iCalUID?: string
    id?: string
    kind?: string
    location?: string
    organizer?: {
        email: string
        self: boolean
    }
    reminders: {
        overrides?: Override[]
        useDefault?: boolean
    }
    start: {
        dateTime: string
        timeZone: string
    }
    status?: string
    summary: string
    updated?: string
    recurrence?: string[]
}

interface Attachment {
    fileId: string
    fileUrl: string
    iconLink: string
    mimeType: string
    title: string
}

interface EntryPoint {
    entryPointType: string
    label?: string
    uri: string
}

export interface Override {
    method: string
    minutes: number
}

export const eventColorMap: Map<string, string> = new Map([
    ['1', 'bg-blue-500 text-black'],
    ['2', 'bg-green-500 text-black'],
    ['3', 'bg-purple-500 text-black'],
    ['4', 'bg-red-500 text-black'],
    ['5', 'bg-yellow-500 text-black'],
    ['6', 'bg-orange-500 text-black'],
    ['7', 'bg-teal-500 text-black'],
    ['8', 'bg-gray-500 text-black'],
    ['9', 'bg-blue-800 text-white '],
    ['10', 'bg-green-800 text-white '],
    ['11', 'bg-red-800 text-white'],
])

export const recurrenceRulesMap: Map<string, string> = new Map([
    ['none', ''], // Does not repeat
    ['daily', 'RRULE:FREQ=DAILY'], // Daily recurrence
    ['weekly', 'RRULE:FREQ=WEEKLY;BYDAY='], // Weekly recurrence on Monday
    ['monthly', 'RRULE:FREQ=MONTHLY;BYMONTHDAY='], // Monthly recurrence on the 15th day
    ['weekdays', 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'], // Recurs every weekday
])

export const getRecurrenceMode = (rule: string): string => {
    const entries = Array.from(recurrenceRulesMap)
    if (rule === '') {
        return 'none'
    }
    for (const [mode, recurrenceRule] of entries) {
        if (rule.includes(recurrenceRule) && recurrenceRule !== '') {
            return mode
        }
    }
    return 'custom'
}

export const getRecurrenceRule = (startDate: string, mode: string): string => {
    const startDateTime = new Date(startDate)

    let recurrenceRule = recurrenceRulesMap.get(mode)

    if (!recurrenceRule || mode === 'none') {
        return ''
    }

    if (mode === 'weekly' || mode === 'monthly') {
        if (mode === 'weekly') {
            const weekday = format(startDateTime, 'EEEE').toUpperCase()

            recurrenceRule += weekday.slice(0, 2)
        } else if (mode === 'monthly') {
            const dayOfMonth = getDate(startDateTime)
            recurrenceRule += dayOfMonth.toString()
        }
    }

    return recurrenceRule
}
