import React from 'react'
import { Link } from 'react-router-dom'
//import ScrollFeedButtons from '../common/scrollComponents/ScrollFeedButtons'

interface Card {
    name: string
    href: string
    icon: JSX.Element
    enabled: boolean
}

interface Props {
    section_title: string
    main_color: string
    cards: Card[]
}

const SectionLayout: React.FC<Props> = ({
    section_title,
    main_color,
    cards,
}) => {
    const enabled_text_color = 'text-gray-300'
    const disabled_text_color = 'text-gray-200/10'
    const disabled_bg_color = 'bg-transparent/10'
    const scrollRef = React.useRef<HTMLDivElement>(null)

    return (
        <div className="flex flex-col gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="text-6xl font-medium text-gray-300">
                {section_title}
            </div>
            {/*             <div className="grid grid-cols-5 justify-center gap-4"> */}
            <div
                className="flex gap-6 flex-wrap h-full overflow-auto"
                ref={scrollRef}
            >
                {cards.map((card) => (
                    <Link
                        to={card.href}
                        key={card.name}
                        className={
                            (card.enabled
                                ? main_color
                                : `${disabled_bg_color} pointer-events-none`) +
                            ' drop-shadow-md aspect-square h-[47%] -mt-2'
                        }
                    >
                        <div className="flex flex-col gap-4 justify-center drop-shadow-md h-full scroll-child">
                            <span
                                className={
                                    (card.enabled
                                        ? enabled_text_color
                                        : disabled_text_color) +
                                    ' text-center text-lg lg:text-2xl 2xl:text-5xl font-medium'
                                }
                            >
                                {card.name}
                            </span>
                            <span
                                className={
                                    (card.enabled
                                        ? enabled_text_color
                                        : disabled_text_color) +
                                    ' self-center w-16 lg:w-24 xl:w-32 2xl:w-36'
                                }
                            >
                                {card.icon}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="inline-block">&nbsp;</div>
            {/* <div className="self-center mt-2">
                <ScrollFeedButtons scrollContainerRef={scrollRef} />
            </div>  */}
        </div>
    )
}

export default SectionLayout
