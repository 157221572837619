import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

interface ScrollFeedButtonsProps {
    scrollContainerRef: React.RefObject<HTMLDivElement>
    extraScroll?: number
}

const HorizontalScrollButtons: React.FC<ScrollFeedButtonsProps> = ({
    scrollContainerRef,
    extraScroll = 0,
}) => {
    const scrollToElement = (direction: number) => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current
            const element = container.querySelector(
                `.scroll-child`
            ) as HTMLElement

            if (element) {
                const childWidth = element.offsetWidth // Use offsetWidth for width
                const childMarginRight = parseInt(
                    getComputedStyle(element).marginRight,
                    10
                ) // Use marginRight for horizontal margin
                const scrollOffset = childWidth + childMarginRight + extraScroll // Adjust for horizontal scrolling
                container.scrollBy({
                    left: scrollOffset * direction, // Use left for horizontal scrolling
                    behavior: 'smooth',
                })
            }
        }
    }

    const scrollToNextElement = () => {
        scrollToElement(1)
    }

    const scrollToPreviousElement = () => {
        scrollToElement(-1)
    }
    //change this to horizontal scroll buttons
    return (
        <div className="flex flex-col items-center justify-center p-3">
            <button
                className="flex w-10 h-10 md:h-12 md:w-12 bg-gray-300 rounded items-center justify-center hover:bg-gray-400 mb-3"
                onClick={scrollToPreviousElement}
            >
                <ChevronLeftIcon className="w-10 h-10" />
            </button>
            <button
                className="flex w-10 h-10 md:h-12 md:w-12 bg-gray-300 rounded items-center justify-center hover:bg-gray-400 "
                onClick={scrollToNextElement}
            >
                <ChevronRightIcon className="w-10 h-10 " />
            </button>
        </div>
    )
}

export default HorizontalScrollButtons
