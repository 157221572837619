import React from 'react';

interface BarometerButtonProps {
  index: number;
  onClick: () => void;
  label: string;
  clickedIndex: number;
  activeCss: () => string;
}

export const BarometerButton: React.FC<BarometerButtonProps> = ({
  index,
  onClick,
  label,
  clickedIndex,
  activeCss,
}) => {
  const buttonCss = `
    inline-flex items-center 
    border border-gray-300 
    px-2.5 md:px-4 lg:px-5 xl:px-6 py-4 lg:py-4 xl:py-5 text-xl md:text-2xl lg:text-2xl xl:text-4xl font-medium 
    focus:z-10 
    ${clickedIndex >= index ? activeCss() : 'bg-white'}
  `;

  return (
    <button
      type="button"
      onClick={onClick}
      className={
        index === 0
          ? `rounded-l-2xl ${buttonCss}`
          : index === 9
          ? `rounded-r-2xl ${buttonCss}`
          : buttonCss
      }
    >
      {label}
    </button>
  );
};

interface BarometerInputProps {
  clickedIndex: number;
  setClickedIndex: (clickedIndex: number) => void;
  setInputValue: (inputValue: string) => void;
  title:string;
  reversed?: boolean;
}

export const BarometerInput: React.FC<BarometerInputProps> = ({
  clickedIndex,
  setClickedIndex,
  setInputValue,
  reversed = false,
  title
}) => {

  const handleBoxClick = (index: number) => {
    setClickedIndex(index);
    setInputValue((index + 1).toString());
  };

  const activeCss = () => {
    if (clickedIndex < 3) {
      return reversed ? 'bg-green-400' : 'bg-red-400 text-white';
    } else if (clickedIndex < 7) {
      return 'bg-yellow-300 text-black';
    } else {
      return reversed ? 'bg-red-400' : 'bg-green-400 text-white';
    }
  };

  return (
    <div>
      <p className="my-5 text-center font-semibold uppercase text-white text-2xl">
        {title}
      </p>
      <div className="flex items-center justify-center">
        <span className="isolate inline-flex rounded-md shadow-sm">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
            <BarometerButton
              key={index}
              index={index}
              onClick={() => handleBoxClick(index)}
              label={
                index <= 2
                  ? reversed
                    ? '😃'
                    : '☹️'
                  : index >= 7
                  ? reversed
                    ? '☹️'
                    : '😃'
                  : '😐'
              }
              clickedIndex={clickedIndex}
              activeCss={activeCss}
            />
          ))}
        </span>
      </div>
    </div>
  );
};
