import React from 'react'
import './App.css'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom'
import LoginPage from './pages/LoginPage'

import RequireAuth from './components/RequireAuth'
import MainLayout from './components/layout/MainLayout'
import FunScreen from './components/sections/FunScreen'
import HealthScreen from './components/sections/HealthScreen'
import PersonalScreen from './components/sections/PersonalScreen'
import HomeScreen from './components/sections/HomeScreen'
import CommunicationScreen from './components/sections/CommunicationScreen'

import { AuthProvider } from './context/auth/AuthProvider'
import SettingsProvider from './context/settings/SettingsProvider'
import { VolumeProvider } from './context/volume/VolumeContext'
import { RadioProvider } from './context/radio/RadioContext'
import { TwilioDeviceProvider } from './context/Twilio/TwilioDeviceContext'
import { SmsDeviceProvider } from './context/Twilio/SmsDeviceContext'
import { SpotifyPlayerProvider } from './context/spotify/SpotifyPlayerProvider'
import RequirePin from './components/RequirePin'
import SettingsPage from './pages/Settings'

//router must be defined with createBrowserRouter to allow blocking (see example in twili video in file /composnents/sections/communication/video/VideoApp.tsx )
const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/login" element={<LoginPage />} />
            <Route
                path="/health/*"
                element={
                    <RequireAuth>
                        <MainLayout
                            selected_tab="1"
                            content={<HealthScreen />}
                            bg_gradient="bg-gradient-to-t from-pink-500 to-pink-900"
                        />
                    </RequireAuth>
                }
            ></Route>
            <Route
                path="/fun/*"
                element={
                    <RequireAuth>
                        <MainLayout
                            selected_tab="2"
                            content={<FunScreen />}
                            bg_gradient="bg-gradient-to-t from-sky-500 to-sky-900"
                        />
                    </RequireAuth>
                }
            ></Route>
            <Route
                path="/communication/*"
                element={
                    <RequireAuth>
                        <MainLayout
                            selected_tab="3"
                            content={<CommunicationScreen />}
                            bg_gradient="bg-gradient-to-t from-lime-500 to-lime-900"
                        />
                    </RequireAuth>
                }
            ></Route>
            <Route
                path="/personal/*"
                element={
                    <RequireAuth>
                        <MainLayout
                            selected_tab="4"
                            content={<PersonalScreen />}
                            bg_gradient="bg-gradient-to-t from-orange-500 to-orange-900"
                        />
                    </RequireAuth>
                }
            ></Route>
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <MainLayout
                            selected_tab="5"
                            content={<HomeScreen />}
                            bg_gradient="bg-cyan-950"
                        />
                    </RequireAuth>
                }
            ></Route>
            <Route
                path="/settings/*"
                element={
                    <RequireAuth>
                        <RequirePin>
                            <SettingsPage />
                        </RequirePin>
                    </RequireAuth>
                }
            ></Route>
        </>
    )
)

function App() {
    return (
        <VolumeProvider>
            <RadioProvider>
                <AuthProvider>
                    <SettingsProvider>
                        <TwilioDeviceProvider>
                            <SmsDeviceProvider>
                                <SpotifyPlayerProvider>
                                    <RouterProvider router={router} />
                                </SpotifyPlayerProvider>
                            </SmsDeviceProvider>
                        </TwilioDeviceProvider>
                    </SettingsProvider>
                </AuthProvider>
            </RadioProvider>
        </VolumeProvider>
    )
}

export default App
