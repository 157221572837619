import React from 'react'
import {
    sloveniaNewsProviders,
    germanyNewsProviders,
    unitedKingdomNewsProviders,
    austriaNewsProviders,
    unitedStatesNewsProviders,
} from './ProviderOptions'
import { useTranslation } from 'react-i18next'

type FormData = {
    language?: string
    colorBlindMode?: string
    useEyeTracker?: boolean
    pinCode?: string
    location?: string
    newsCategories?: string[]
    newsLanguages?: string[]
    newsProviders?: string[]
}

interface GeneralFormProps {
    formData: FormData

    handleStringArrayChange: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void
}

const NewsForm: React.FC<GeneralFormProps> = ({
    formData,
    handleStringArrayChange,
}) => {
    const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
        'GB'
    )
    const [shownProviders, setShownProviders] = React.useState<
        Record<string, string>
    >(unitedKingdomNewsProviders)

    const { t } = useTranslation()

    const handleCountrySelect = (country: string) => {
        setSelectedCountry(country)
        switch (country) {
            case 'GB':
                setShownProviders(unitedKingdomNewsProviders)
                break
            case 'US':
                setShownProviders(unitedStatesNewsProviders)
                break
            case 'DE':
                setShownProviders(germanyNewsProviders)
                break
            case 'AT':
                setShownProviders(austriaNewsProviders)
                break
            case 'SL':
                setShownProviders(sloveniaNewsProviders)
                break
            default:
                setShownProviders({})
                break
        }
    }
    const newsCategoryOptions: Record<string, string> = {
        general: t('settings_page.news.general'),
        business: t('settings_page.news.business'),
        entertainment: t('settings_page.news.entertainment'),
        health: t('settings_page.news.health'),
        science: t('settings_page.news.science'),
        sports: t('settings_page.news.sports'),
        technology: t('settings_page.news.technology'),
    }

    const newsLanguageOptions: Record<string, string> = {
        en: t('settings_page.english'),
        de: t('settings_page.german'),
    }

    const countryOptions: Record<string, string> = {
        GB: t('settings_page.country.UK'),
        US: t('settings_page.country.US'),
        DE: t('settings_page.country.germany'),
        AT: t('settings_page.country.austria'),
        SL: t('settings_page.country.slovenia'),
    }

    return (
        <ul role="list" className="mt-2 divide-y divide-gray-200">
            <div className="py-4">
                <label
                    htmlFor="newsCategories"
                    className="block text-base font-medium text-gray-700 mb-2"
                >
                    {t('settings_page.news categories')}
                </label>
                <div className="flex flex-wrap gap-6">
                    {Object.entries(newsCategoryOptions).map(
                        ([id, displayName]) => (
                            <span key={id} className="py-2">
                                <input
                                    type="checkbox"
                                    id={`newsCategory-${id}`}
                                    name="newsCategories"
                                    value={id}
                                    checked={formData.newsCategories?.includes(
                                        id
                                    )}
                                    onChange={handleStringArrayChange}
                                    className="text-teal-500 focus:ring-0 cursor-pointer ring-0 h-5 w-5 my-auto border-gray-300 rounded"
                                />
                                <label
                                    htmlFor={`newsCategory-${id}`}
                                    className="ml-2 text-sm my-auto font-medium text-gray-600"
                                >
                                    {displayName}
                                </label>
                            </span>
                        )
                    )}
                </div>
            </div>

            <div className="py-4">
                <label
                    htmlFor="newsLanguages"
                    className="block text-base font-medium text-gray-700 mb-2"
                >
                    {t('settings_page.news languages')}
                </label>
                {Object.entries(newsLanguageOptions).map(
                    ([id, displayName]) => (
                        <div key={id} className="flex py-2">
                            <input
                                type="checkbox"
                                id={`newsLanguage-${id}`}
                                name="newsLanguages"
                                value={id}
                                checked={formData.newsLanguages?.includes(id)}
                                onChange={handleStringArrayChange}
                                className="text-teal-500 focus:ring-0 cursor-pointer ring-0 h-5 w-5 my-auto border-gray-300 rounded"
                            />
                            <label
                                htmlFor={`newsLanguage-${id}`}
                                className="ml-4 text-sm my-auto font-medium text-gray-600"
                            >
                                {displayName}
                            </label>
                        </div>
                    )
                )}
            </div>

            <div className="py-4">
                <label
                    htmlFor="newsProviders"
                    className="block text-base font-medium text-gray-700 mb-2"
                >
                    {t('settings_page.news providers')}
                </label>
                <div className="flex gap-2">
                    {Object.entries(countryOptions).map(([code]) => (
                        <button
                            key={code}
                            type="button"
                            className={`${
                                selectedCountry === code
                                    ? 'bg-teal-500 text-white'
                                    : 'bg-gray-200 text-gray-700'
                            } py-2 px-4 rounded`}
                            onClick={() => handleCountrySelect(code)}
                        >
                            {code}
                        </button>
                    ))}
                </div>
                <div className="flex flex-wrap gap-x-6 mt-2">
                    {Object.entries(shownProviders).map(([id, displayName]) => (
                        <div key={id} className="flex py-2">
                            <input
                                type="checkbox"
                                id={`newsProvider-${id}`}
                                name="newsProviders"
                                value={id}
                                checked={formData.newsProviders?.includes(id)}
                                onChange={handleStringArrayChange}
                                className="text-teal-500 focus:ring-0 cursor-pointer ring-0 h-5 w-5 my-auto border-gray-300 rounded"
                            />
                            <label
                                htmlFor={`newsProvider-${id}`}
                                className="ml-2 text-sm my-auto font-medium text-gray-600"
                            >
                                {displayName}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </ul>
    )
}

export default NewsForm
