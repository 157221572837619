import React from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

interface Props {
    scrollToPrevious: () => void
    scrollToNext: () => void
}

const ScrollButtons: React.FC<Props> = ({ scrollToPrevious, scrollToNext }) => {
    return (
        <div className="flex">
            <button
                className="flex w-10 h-10 md:h-12 md:w-12 bg-gray-300 rounded items-center justify-center hover:bg-gray-400 mr-2"
                onClick={scrollToPrevious}
            >
                <ChevronUpIcon className="w-10 h-10" />
            </button>
            <button
                className="flex w-10 h-10 md:h-12 md:w-12 bg-gray-300 rounded items-center justify-center hover:bg-gray-400 "
                onClick={scrollToNext}
            >
                <ChevronDownIcon className="w-10 h-10 " />
            </button>
        </div>
    )
}

export default ScrollButtons
