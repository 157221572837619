import React, { useEffect} from 'react'
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useSmsDevice } from '../../../context/Twilio/SmsDeviceContext'
import { useTranslation } from 'react-i18next'

interface Props {
    color: string | undefined
    className: string
    setShowingNotifications: (showing: boolean) => void
}

const UnreadMessages: React.FC<Props> = ({
    color,
    className,
    setShowingNotifications,
}) => {
    const { unreadMessages } = useSmsDevice()
    const { t } = useTranslation()

    useEffect(() => {
        if (unreadMessages > 0) {
            setShowingNotifications(true)
        }
    }, [unreadMessages])

    if (!color) {
        color = 'bg-neutral-400/[.4]'
    }

    return (
        <>
            {unreadMessages > 0 && (
                <Link to="/communication/sms" className="w-full">
                    <div className={`relative ${color} flex ${className} p-1`}>
                        <div className="text-white h-16 p-2 -ml-1 aspect-square lg:h-20">
                            <ChatBubbleBottomCenterIcon />
                        </div>
                        <div className="absolute top-0 bottom-0 right-0 flex flex-col justify-center left-16 lg:left-20">
                            <p className="truncate text-white font-medium text-2xl ">
                                {t('notifications.you have') +
                                    ' ' +
                                    unreadMessages +
                                    ' ' +
                                    t('notifications.unread messages')}
                            </p>
                        </div>
                    </div>
                </Link>
            )}
        </>
    )
}

export default UnreadMessages
