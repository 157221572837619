import React, { useState, useEffect, FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import useVideoContext from './hooks/Video/useVideoContext'
import { useAuthState } from '../../../../context/auth/AuthContext'
import RoomNameScreen from './Components/JoinRoom/RoomNameScreen'
import DeviceSelectionScreen from './Components/JoinRoom/DeviceSelectionScreen'
import api from '../../../../api'
import ErrorDialog from './Components/Room/ErrorDialog'
import { useTranslation } from 'react-i18next'

export enum Steps {
    roomNameStep,
    deviceSelectionStep,
}

export default function JoinRoomScreen({
    setDeviceSettingsOpen,
}: {
    setDeviceSettingsOpen: (open: boolean) => void
}) {
    const { getAudioAndVideoTracks } = useVideoContext()
    const { URLRoomName } = useParams<{ URLRoomName?: string }>()

    const [step, setStep] = useState(Steps.roomNameStep)
    const user = useAuthState().user

    const [name, setName] = useState<string>(user?.email || '')

    const [roomName, setRoomName] = useState<string>('')

    const [mediaError, setMediaError] = useState<Error>()
    const [showError, setShowError] = useState<Error | null>(null)

    const { t } = useTranslation()

    useEffect(() => {
        setShowError(mediaError || null)
    }, [mediaError])

    useEffect(() => {
        if (URLRoomName) {
            setRoomName(URLRoomName)
            if (user?.email) {
                setStep(Steps.deviceSelectionStep)
            }
        }
    }, [user, URLRoomName])

    useEffect(() => {
        if (step === Steps.deviceSelectionStep && !mediaError) {
            getAudioAndVideoTracks().catch((error: Error) => {
                console.error(error)
                setMediaError(error)
            })
        }
    }, [getAudioAndVideoTracks, step, mediaError])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        window.history.replaceState(
            null,
            '',
            window.encodeURI(
                `/communication/video/${roomName}${
                    window.location.search || ''
                }`
            )
        )

        setStep(Steps.deviceSelectionStep)
    }

    const getToken = async (roomName: string): Promise<string> => {
        try {
            const response = await api.post('/phone/videotoken', {
                RoomName: roomName,
            })
            if (response.data.data.token) {
                return response.data.data.token
            }
        } catch (error) {
            console.error('Error getting video token:', error)
            const m = t('video_page.error token')
            setShowError(Error(m))
        }
        return ''
    }

    return (
        <>
            <ErrorDialog
                error={showError}
                dismissError={() => {
                    setShowError(null)
                }}
            />
            {step === Steps.roomNameStep && (
                <RoomNameScreen
                    name={name}
                    roomName={roomName}
                    setName={setName}
                    setRoomName={setRoomName}
                    handleSubmit={handleSubmit}
                />
            )}

            {step === Steps.deviceSelectionStep && (
                <DeviceSelectionScreen
                    name={name}
                    roomName={roomName}
                    setStep={setStep}
                    getToken={getToken}
                    setDeviceSettingsOpen={setDeviceSettingsOpen}
                />
            )}
        </>
    )
}
