import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import WaterDroplet from './water-drop.svg'
import Cloud from './cloud.svg'
import Wind from './wind.svg'
import { useTranslation } from 'react-i18next'
import { SettingsContext } from '../../../context/settings/SettingsContext'

interface WeatherData {
    location: Location
    current: Current
    forecast: {
        forecastday: []
    }
}

interface Location {
    name: string
    country: string
}

interface Current {
    last_updated: string
    temp_c: number
    feelslike_c: number
    condition: {
        icon: string
        text: string
    }
    wind_kph: number
    precip_mm: number
    humidity: number
}

interface Day {
    date: string
    day: {
        maxtemp_c: number
        condition: {
            icon: string
        }
    }
}

const WeatherWidget = React.forwardRef<HTMLDivElement>((_: unknown, ref) => {
    const settings = useContext(SettingsContext)
    const { t } = useTranslation()
    const [weatherData, setWeatherData] = useState<WeatherData>(
        {} as WeatherData
    )
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false)
    const API_KEY = process.env.REACT_APP_WEATHER_API_KEY
    const CITY = settings?.location || 'Ljubljana'
    const DAYS_AHEAD = 7 // Today + 6 days ahead

    const fetchForecastWeatherData = async () => {
        if (!API_KEY) {
            console.error('No weather API key provided')
            setRequestSuccess(false)
            return
        }
        if (!CITY) {
            console.error('No city provided')
            setRequestSuccess(false)
            return
        }
        axios
            .get(
                `https://api.weatherapi.com/v1/forecast.json?key=${API_KEY}&q=${CITY}&days=${DAYS_AHEAD}`
            )

            .then((response) => {
                setWeatherData(response.data)
                setRequestSuccess(true)
            })
            .catch(() => {
                //console.log(error)
                setRequestSuccess(false)
            })
    }

    useEffect(() => {
        fetchForecastWeatherData()
    }, [])

    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
        dayPeriod: 'short',
    }

    const dayTranslations = [
        t('sunday'),
        t('monday'),
        t('tuesday'),
        t('wednesday'),
        t('thursday'),
        t('friday'),
        t('saturday'),
    ]

    const formatTime = (dataString: string) => {
        const date = new Date(dataString)
        return date.toLocaleString(undefined, timeOptions)
    }

    const formatDate = (dataString: string) => {
        const date = new Date(dataString)
        return date.toLocaleString(undefined, dateOptions)
    }

    const formatDay = (dataString: string) => {
        const date = new Date(dataString)
        const dayOfWeek = date.getDay()
        return dayTranslations[dayOfWeek]
    }

    const imageUrlResize = (url: string) => {
        return url.replace('64x64', '128x128')
    }

    // data: weatherData.current
    // location: weatherData.location
    const renderCurrent = (
        data: WeatherData['current'],
        location: WeatherData['location']
    ) => {
        return (
            <div className="flex flex-wrap gap-2 2xl:flex-nowrap justify-center 2xl:justify-evenly w-full rounded-md bg-cyan-800 px-1 py-3 2xl:px-3">
                {/* LEFT SIDE */}
                <div className="flex flex-col justify-center">
                    <div className="flex text-center">
                        <p className="text-3xl text-white font-medium mx-auto">
                            {location.name}, {location.country}
                        </p>
                    </div>
                    <div className="text-base text-white text-center">
                        Today at {formatTime(data.last_updated)}
                    </div>
                    <div className="text-7xl text-white font-medium ml-2 text-center">
                        {data.temp_c}°C
                    </div>
                    <div className="text-sm text-white text-center">
                        Feels like {data.feelslike_c}°C
                    </div>
                </div>
                {/* RIGHT SIDE */}
                <div className="flex flex-col justify-center -mt-4">
                    <img
                        className="mx-auto"
                        src={imageUrlResize(data.condition?.icon)}
                        alt="Weather icon"
                    />
                    <p className="font-medium text-3xl text-white text-center -mt-8">
                        {data.condition?.text}
                    </p>
                    <div className="flex mt-2 justify-evenly">
                        <div className="flex flex-col text-white">
                            <img src={Cloud} className="h-6" />
                            <span className="text-xs">{data.precip_mm}mm</span>
                        </div>
                        <div className="flex flex-col text-white">
                            <img src={WaterDroplet} className="h-6" />
                            <span className="text-xs">{data.humidity}%</span>
                        </div>
                        <div className="flex flex-col text-white">
                            <img src={Wind} className="h-6" />
                            <span className="text-xs">
                                {data.wind_kph} km/h
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // data: weatherData.forecast
    const renderForecast = (data: WeatherData['forecast']) => {
        // Forecast returns #DAYS_AHEAD + todays day, so we cut it
        const cutData = data.forecastday.slice(1, DAYS_AHEAD)
        return (
            <div className="flex justify-evenly flex-wrap rounded-md bg-cyan-800 p-2 py-2 gap-y-1">
                {cutData.map((day: Day, index: number) => (
                    <div key={index} className="w-1/2 xl:w-1/3 text-center">
                        <img src={day.day.condition.icon} className="mx-auto" />
                        <div className="text-white font-medium">
                            {formatDay(day.date)}
                        </div>
                        <div className="text-white text-sm">
                            {formatDate(day.date)}
                        </div>
                        <div className="text-white font-bold">
                            {day.day.maxtemp_c}°C
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const renderUnavailable = () => {
        return (
            <div className="flex gap-2">
                <div className="hidden xl:block p-1 my-auto">
                    <ExclamationTriangleIcon className="w-12 xl:w-16 2xl:w-20 aspect-square text-yellow-600" />
                </div>
                <div>
                    <div className="text-gray-300 font-medium text-sm xl:text-base 2xl:text-xl">
                        SERVICE UNAVAILABLE
                    </div>
                    <div className="text-gray-300 text-xs xl:text-sm 2xl:text-base">
                        Sorry, the weather forecast is temporarily unavailable.
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={
                (requestSuccess ? 'bg-cyan-700' : 'bg-cyan-700/30') +
                ' flex flex-col bg-cyan-500 w-full p-2 gap-2 overflow-auto h-132'
            }
            ref={ref}
        >
            {requestSuccess ? (
                <div className="flex flex-col gap-2 scroll-child">
                    {weatherData.current &&
                        weatherData.location &&
                        renderCurrent(
                            weatherData.current,
                            weatherData.location
                        )}
                    {weatherData.forecast &&
                        weatherData.forecast.forecastday &&
                        renderForecast(weatherData.forecast)}
                </div>
            ) : (
                renderUnavailable()
            )}
        </div>
    )
})
WeatherWidget.displayName = 'WeatherWidget'

export default WeatherWidget
