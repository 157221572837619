import {
    PhoneIcon,
    PencilIcon,
    TrashIcon,
    PlusCircleIcon,
} from '@heroicons/react/24/outline'
import React, { useEffect, useState, useRef } from 'react'
import api from '../../../../api'
import ContactsFeed from './ContactsFeed'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import LoadingWheel from '../../../common/LoadingWheel'
import AddContactModal from './AddContactModal'
import ConfirmationModal from '../../../common/ConfirmationModal'
import CallComponent from './CallComponent'
import { useTwilioDevice } from '../../../../context/Twilio/TwilioDeviceContext'
import DeviceProblemModal from '../../../common/DeviceProblemModal'

import { useTranslation } from 'react-i18next'

export interface Contact {
    name: string
    number: string
    id: string
}

const PhoneScreen: React.FC = () => {
    const { t } = useTranslation()

    const [loadingContacts, setLoadingContacts] = useState<boolean>(false)
    const [contacts, setContacts] = useState<Contact[]>([] as Contact[])
    const [selectedContact, setSelectedContact] = useState<Contact>()
    const [selectedId, setSelectedId] = useState<string>('')
    const contactsFeedContainerRef = useRef<HTMLDivElement>(null)
    const detailsContainerRef = useRef<HTMLDivElement>(null)
    const [modalAddContact, setModalAddContact] = useState<boolean>(false)
    const [modalDeleteContact, setModalDeleteContact] = useState<boolean>(false)
    const [errorDeleteContact, setErrorDeleteContact] = useState<string>('')
    const [updateContact, setUpdateContact] = useState<Contact | undefined>()
    const [callingContact, setCallingContact] = useState<Contact | undefined>()
    const { call, incomingCall, ready } = useTwilioDevice()

    const handleSelectedContact = (id: string) => {
        setSelectedId(id)
        const selected = contacts.find((contact) => contact.id === id)
        setSelectedContact(selected)
        setCallingContact(undefined)
    }

    const fetchData = async () => {
        try {
            setLoadingContacts(true)

            const response = await api.get('/phone/contact')
            if (response.data && response.data.data) {
                setContacts(response.data.data.contacts)
                setLoadingContacts(false)
            }
        } catch (error) {
            console.error('Error fetching email data:', error)
            setLoadingContacts(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setErrorDeleteContact('')
    }, [modalDeleteContact])

    const handleDeleteContact = async () => {
        try {
            const response = await api.delete('/phone/contact/' + selectedId)
            if (response.data && response.data.data) {
                setContacts(response.data.data.contacts)
                setSelectedId('')
                setSelectedContact(undefined)
                setCallingContact(undefined)
                setModalDeleteContact(false)
                setErrorDeleteContact('')
            }
            const s = t('phone_page.delete error')
            setErrorDeleteContact(s)
        } catch (error) {
            console.error('Error creating contact:', error)
            const s = t('phone_page.delete error')
            setErrorDeleteContact(s)
        }
    }

    const handleAddContact = async (updatedContacts: Contact[]) => {
        setContacts(updatedContacts)
        const selected = updatedContacts.find(
            (contact) => contact.id === selectedId
        )
        setSelectedContact(selected)
        setCallingContact(undefined)
    }

    return (
        <div className="relative flex flex-col gap-2 pb-4 px-4 pt-6 md:px-8 md:pt-6 lg:px-12 lg:pt-8 xl:px-16 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between">
                <div className="w-full text-5xl font-medium text-gray-300">
                    {t('phone_page.phone')}
                </div>

                <div className="flex ">
                    <div className="inline-block border w-60">
                        <div
                            className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                            onClick={() => {
                                setCallingContact(undefined)
                                setSelectedContact(undefined)
                                setSelectedId('')
                            }}
                        >
                            <span>{t('phone_page.dial')}</span>
                            <span className="w-6">
                                <PhoneIcon className="w-7 h-7" />
                            </span>
                        </div>
                    </div>

                    <div className="inline-block border w-60">
                        <div
                            className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                            onClick={() => setModalAddContact(true)}
                        >
                            <span>{t('phone_page.new contact')}</span>
                            <span className="w-6">
                                <PlusCircleIcon className="w-7 h-7" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden gap-4 h-full">
                <div className="flex flex-col w-1/4 overflow-hidden border h-full">
                    <div className="flex flex-row  justify-between">
                        <div className="text-gray-300 font-medium text-2xl">
                            {t('phone_page.contacts')}
                        </div>
                    </div>

                    <hr />
                    <div
                        className="overflow-auto flex-grow h-full"
                        ref={contactsFeedContainerRef}
                    >
                        {loadingContacts ? (
                            <div className="h-full flex justify-center items-center">
                                <LoadingWheel className="w-16 h-16" />
                            </div>
                        ) : contacts && contacts.length > 0 ? (
                            <ContactsFeed
                                contacts={contacts}
                                handleSelectContact={handleSelectedContact}
                                selectedId={selectedId}
                            />
                        ) : (
                            <div className="h-full flex justify-center items-center text-gray-300 font-medium text-2xl">
                                {t('phone_page.no contacts')}
                            </div>
                        )}
                    </div>
                    <hr />
                    <div className="shrink-0 my-2 self-center items-end  flex">
                        <ScrollFeedButtons
                            scrollContainerRef={contactsFeedContainerRef}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-3/4 overflow-hidden border">
                    <div className="text-gray-300 font-medium text-2xl ">
                        {t('phone_page.call')}
                    </div>
                    <hr />
                    {!call && !incomingCall && selectedContact ? (
                        <div className="overflow-auto h-full">
                            <div
                                className={`flex flex-col overflow-auto h-full `}
                                ref={detailsContainerRef}
                            >
                                <div className="flex text-gray-300 font-medium text-4xl justify-end w-full ">
                                    <button
                                        className="text-gray-300 hover:text-gray-400 mr-3"
                                        onClick={() => {
                                            setModalAddContact(true)
                                            setUpdateContact(selectedContact)
                                        }}
                                    >
                                        <PencilIcon className="w-12 h-12" />
                                    </button>
                                    <button
                                        className="text-gray-300 hover:text-gray-400 mr-1"
                                        onClick={() =>
                                            setModalDeleteContact(true)
                                        }
                                    >
                                        <TrashIcon className="w-12 h-12" />
                                    </button>
                                </div>
                                <div className="flex text-gray-300 font-medium text-4xl justify-center w-full mt-5">
                                    {selectedContact.name}
                                </div>
                                <div className="flex text-gray-300 font-medium text-4xl justify-center w-full mt-5">
                                    {selectedContact.number}
                                </div>
                                <div className="flex justify-center mt-10">
                                    <button
                                        className="flex items-center justify-center border-4 rounded-xl w-36 h-36 border-gray-300 hover:bg-zinc-600/70"
                                        onClick={() => {
                                            setCallingContact(selectedContact)
                                            setSelectedContact(undefined)
                                            setSelectedId('')
                                        }}
                                    >
                                        <PhoneIcon className="w-20 h-20 self-center text-gray-300" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <CallComponent
                            callingContact={callingContact}
                            contacts={contacts}
                        />
                    )}
                </div>
            </div>
            {modalAddContact && (
                <AddContactModal
                    handleModalClose={() => {
                        setModalAddContact(false)
                        setUpdateContact(undefined)
                    }}
                    handleAddContact={handleAddContact}
                    updateContact={updateContact}
                />
            )}
            {modalDeleteContact && (
                <ConfirmationModal
                    message={
                        t('phone_page.are you sure delete') +
                        selectedContact?.name
                    }
                    succesButtonMessage={t('phone_page.delete')}
                    onSuccess={handleDeleteContact}
                    onCancel={() => {
                        setModalDeleteContact(false)
                        setErrorDeleteContact('')
                    }}
                    errorMessage={errorDeleteContact}
                />
            )}

            {ready || (
                
                <DeviceProblemModal message={t('phone_page.device problem')} />

            )}
        </div>
    )
}

export default PhoneScreen
