import React, { useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import api from '../../../../api'
import { Album } from './AlbumsScreen'
import { useTranslation } from 'react-i18next'

interface CreateAlbumModalProps {
    setAlbums: (album: Album[]) => void
    albums: Album[]
}

const CreateAlbumModal: React.FC<CreateAlbumModalProps> = ({
    setAlbums,
    albums,
}) => {
    const [newAlbumName, setNewAlbumName] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { t } = useTranslation()

    const modalClose = () => {
        setIsModalOpen(false)
        setNewAlbumName('')
    }

    const handleModalSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault()
        try {
            const response = await api.post('/photos/albums', {
                Name: newAlbumName,
            })
            if (response.data) {
                if (response.data.data && response.data.status === 'success') {
                    let sortedAlbums = [...albums, response.data.data]
                    sortedAlbums = sortedAlbums.sort((a: Album, b: Album) =>
                        a.title.localeCompare(b.title)
                    )
                    setAlbums(sortedAlbums)
                }
            }
        } catch (error) {
            console.error('Error creating album:', error)
        }
        modalClose()
    }

    return (
        <>
            <button
                className="flex items-center gap-1 px-4 py-2 text-white text-xl bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                onClick={() => {
                    setIsModalOpen(true)
                }}
            >
                <PlusIcon className="h-5 w-5" />
                {t('album_page.add album')}
            </button>

            {isModalOpen ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 ">
                    <div
                        className="absolute inset-0 bg-black opacity-50"
                        onClick={modalClose}
                    ></div>
                    <div className="bg-white rounded-lg p-6 z-20 w-80">
                        <h2 className="text-3xl font-semibold mb-4">
                            {t('album_page.add album')}
                        </h2>
                        <form onSubmit={handleModalSubmit}>
                            <div className="mb-4">
                                <label
                                    htmlFor="albumName"
                                    className="block text-gray-700 text font-medium mb-2"
                                >
                                    {t('album_page.album name')}
                                </label>
                                <input
                                    type="text"
                                    id="albumName"
                                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                                    value={newAlbumName}
                                    onChange={(e) =>
                                        setNewAlbumName(e.target.value)
                                    }
                                />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="mr-2 px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none text-xl"
                                    onClick={modalClose}
                                >
                                    {t('album_page.cancel')}
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none text-xl"
                                >
                                    {t('album_page.add')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default CreateAlbumModal
