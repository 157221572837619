import React, { useEffect, useCallback } from 'react'
import {
    MinusCircleIcon,
    PlusCircleIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon,
} from '@heroicons/react/24/outline'

import { useRadioDispatch } from '../context/radio/RadioContext'

import { useVolumeTarget, useSetVolume, useVolume, usePrevVolume, useIsMuted } from '../context/volume/VolumeContext'

/* interface VolumeControlProps {
    initialVolume: number
} */

const VolumeControl: React.FC = () => {
    const volumeStep = 5
    const volume = useVolume()
    const setVolume = useSetVolume()
    //const [volume, setVolume] = useState(initialVolume)
    const [prevVolume, setPrevVolume] = usePrevVolume()
    const [isMuted, setIsMuted] = useIsMuted()

    const radioDispatch = useRadioDispatch()

    const volumeTarget = useVolumeTarget()

    useEffect(() => {
        if (isMuted) {
            //setPrevVolume(volume)
            setVolume(0)
            if (volumeTarget === 'radio') {
                radioDispatch({ type: 'SET_VOLUME', volume: 0 })
            }
        } else {
            setVolume(prevVolume)
            if (volumeTarget === 'radio') {
                radioDispatch({ type: 'SET_VOLUME', volume: prevVolume/ 100 })
            }
        }
    }, [isMuted])

    const handleVolumeIncrease = useCallback(() => {
        if (isMuted) {
            handleMuteToggle()
        }
        if (volume < 100) {
            setVolume(volume + volumeStep)
            setPrevVolume(volume + volumeStep)
            if (volumeTarget === 'radio') {
                radioDispatch({ type: 'VOLUME_UP' })
            } /* else if (volumeTarget === 'movies') {
            moviesDispatch({ type: 'VOLUME_UP' });
          }*/
        }
    }, [isMuted, volume, volumeTarget, radioDispatch /*, moviesDispatch*/])

    const handleVolumeDecrease = useCallback(() => {
        if (isMuted) {
            handleMuteToggle()
        }
        if (volume > 0) {
            setVolume(volume - volumeStep)
            setPrevVolume(volume - volumeStep)
            if (volumeTarget === 'radio') {
                radioDispatch({ type: 'VOLUME_DOWN' })
            } /*else if (volumeTarget === 'movies') {
            moviesDispatch({ type: 'VOLUME_DOWN' });
          }*/
        }
    }, [isMuted, volume, volumeTarget, radioDispatch /*, moviesDispatch*/])

    const handleMuteToggle = useCallback(() => {
        setIsMuted(!isMuted)
    }, [isMuted])

    const barPrimaryColor = '#25fa00'
    const barSecondaryColor = '#898a88'
    const barThickness = '10px'
    const progressFill = {
        borderRadius: '50%',
        inset: '0',
        background: `conic-gradient(${barPrimaryColor} ${volume}%, ${barSecondaryColor} 0)`,
        zIndex: 0,
        WebkitMask: `radial-gradient(farthest-side,#0000 calc(99% - ${barThickness}),#000 calc(100% - ${barThickness}))`,
        mask: `radial-gradient(farthest-side,#0000 calc(99% - ${barThickness}),#000 calc(100% - ${barThickness}))`,
    }

    return (
        <div className="flex flex-col overflow-hidden">
            <div className="flex justify-end">
                <span onClick={handleVolumeIncrease}>
                    <PlusCircleIcon className="aspect-square text-white -mb-2 h-14 md:h-16 2xl:h-20 cursor-pointer" />
                </span>
            </div>
            <div className="flex justify-end">
                <div className="inline-grid place-items-center">
                    {/* Progress bar */}
                    <div
                        style={progressFill}
                        className="aspect-square w-36 md:w-44 xl:w-52 2xl:w-72 relative"
                    ></div>
                    <div className="absolute text-center">
                        {!isMuted ? (
                            <SpeakerWaveIcon
                                className="text-white w-14 md:w-16 xl:w-20 2xl:w-28 cursor-pointer" 
                                onClick={handleMuteToggle}
                            />
                        ) : (
                            <SpeakerXMarkIcon
                                className="text-white w-14 md:w-16 xl:w-20 2xl:w-28 cursor-pointer"
                                onClick={handleMuteToggle}
                            />
                        )}
                        <span className="font-medium text-white text-2xl md:text-3xl xl:text-4xl 2xl:text-6xl">
                            {volume}%
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <span onClick={handleVolumeDecrease}>
                    <MinusCircleIcon className="aspect-square text-white -mt-2 h-14 md:h-16 2xl:h-20 cursor-pointer" />
                </span>
            </div>
        </div>
    )
}

export default VolumeControl
