import React, { useState, useEffect } from 'react'
import { Client, Message, Conversation } from '@twilio/conversations'
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useSmsDevice } from '../../../../context/Twilio/SmsDeviceContext'

interface Props {
    client: Client | null
    conversations: Conversation[]
    selectedConvo: Conversation | null
    messages: Map<string, Message[]>
    selectedId: string
    handleSelectConversation: (id: string) => void
    findContact: (number: string) => string
}

const ConversationFeed = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            client,
            conversations,
            selectedConvo,
            messages,
            selectedId,
            handleSelectConversation,
            findContact,
        },
        ref
    ) => {
        const { t } = useTranslation()
        const {updateUnreadMessages} = useSmsDevice()
        const [lastMessages, setLastMessages] = useState<{
            [key: string]: string
        }>({})

        const [sortedConversations, setSortedConversations] = useState<
            Conversation[]
        >([])

        const getLastMessage = (conversation: Conversation): Message | null => {
            const messageArray = messages.get(conversation.sid)
            if (messageArray && messageArray.length > 0) {
                return messageArray[messageArray.length - 1]
            }
            return null
        }

        const checkFriendlyName = (conversation: Conversation): string => {
            try {
                if (conversation?.friendlyName && client?.user) {
                    //The name is set by the user who created the conversation, it is set to the identety of the user he is sending a message to
                    //If the user is the one who did not created the conversation, we need to find the other users identety and show it as the name
                    if (client.user.identity === conversation.friendlyName) {
                        for (const participant of Array.from(
                            conversation._participants.values()
                        )) {
                            if (participant.identity !== client.user.identity) {
                                participant.lastReadMessageIndex
                                return findContact(participant.identity || '')
                            }
                        }
                    } else {
                        return findContact(conversation.friendlyName)
                    }
                }
            } catch (e) {
                console.error(e)
            }

            return t('sms_page.no name')
        }

        const getLastMessageString = (conversation: Conversation): string => {
            try {
                const lastMessage = getLastMessage(conversation)
                return lastMessage ? lastMessage.body || '' : ''
            } catch (e) {
                console.error(e)
                return ''
            }
        }

        useEffect(() => {
            try {
                const sortedConversations = conversations.sort(
                    (convA, convB) => {
                        const lastMessageA = getLastMessage(convA)
                        const lastMessageB = getLastMessage(convB)

                        if (
                            lastMessageA &&
                            lastMessageB &&
                            lastMessageA.dateCreated &&
                            lastMessageB.dateCreated
                        ) {
                            return (
                                lastMessageB.dateCreated.getTime() -
                                lastMessageA.dateCreated.getTime()
                            )
                        } else if (lastMessageA) {
                            return -1
                        } else if (lastMessageB) {
                            return 1
                        }
                        return 0
                    }
                )

                setSortedConversations(sortedConversations)
            } catch (e) {
                console.error(e)
            }
        }, [lastMessages])

        useEffect(() => {
            const fetchLastMessages = () => {
                const messagesMap: { [key: string]: string } = {}
                for (const conversation of conversations) {
                    const lastMessage = getLastMessageString(conversation)
                    messagesMap[conversation.sid] = lastMessage
                }
                setLastMessages(messagesMap)
            }

            fetchLastMessages()
        }, [messages])

        const setAllMessagesRead = async () => {
            try {
                if (selectedConvo) {
                    await selectedConvo.setAllMessagesRead()
                }
            } catch (e) {
                console.error(e)
            }
            updateUnreadMessages()	

        }

        useEffect(() => {
            setAllMessagesRead()
        }, [selectedConvo, messages])

        const getUnreadCount = (conversation: Conversation): number => {
            const messageArray = messages.get(conversation.sid)

            if (conversation.sid === selectedConvo?.sid || !messageArray) {
                return 0
            }
            if (messageArray.length <= 0) {
                return 0
            }
            if (
                conversation.lastReadMessageIndex !== null &&
                conversation.lastReadMessageIndex !== undefined
            ) {
                return (
                    messageArray[messageArray.length - 1].index -
                    conversation.lastReadMessageIndex
                )
            }

            if (conversation.lastReadMessageIndex === null) {
                return messageArray[messageArray.length - 1].index
            }
            return 0
        }
        const formatNotification = (number: number): string => {
            if (number > 99) {
                return '99+'
            }
            return number.toString()
        }

        const unreadMessages = (conversation: Conversation) => {
            const unreadCount = getUnreadCount(conversation)
            if (unreadCount > 0) {
                return (
                    <>
                        <div className="flex flex-col absolute right-1 top-4 text-center text-blue-400 text-xl font-medium">
                            <ChatBubbleLeftIcon className="h-14 w-14 animate-pulse" />
                        </div>
                        <span className="absolute text-blue-400 text-xl right-3 top-6 text-center w-10 animate-pulse truncate">
                            {formatNotification(unreadCount)}
                        </span>
                    </>
                )
            }
            return null // Return null if there are no unread messages
        }

        return (
            <div className="overflow-auto flex flex-col" ref={ref}>
                {sortedConversations.map(
                    (conversation: Conversation, index: number) => (
                        <div
                            key={index}
                            className={`flex flex-col justify-between h-32 border-b p-2 relative scroll-child cursor-pointer break-words ${
                                selectedId === conversation.sid
                                    ? 'bg-zinc-600/90'
                                    : 'bg-zinc-600/50 hover:bg-zinc-600/70'
                            }`}
                            onClick={() =>
                                handleSelectConversation(conversation.sid)
                            }
                        >
                            <div className="text-blue-400 text-2xl mt-4 truncate h-10 mr-14">
                                {checkFriendlyName(conversation)}
                            </div>
                            <div className="text-blue-950 text-xl truncate h-10 mr-14">
                                {lastMessages[conversation.sid] || ''}
                            </div>
                            {unreadMessages(conversation)}
                        </div>
                    )
                )}
            </div>
        )
    }
)

ConversationFeed.displayName = 'ContactsFeed'

export default ConversationFeed
