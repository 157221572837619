import React, { useState, useEffect, useRef } from 'react'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom'
import {
    useVolume,
    useVolumeTarget,
    useSetVolumeTarget,
} from '../../../../context/volume/VolumeContext'
import ShareButton from './ShareButton'
import api from '../../../../api'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

interface MediaItem {
    ID: string
    Filename: string
    BaseURL: string
    Description: string
    MediaMetadata: {
        CreationTime: string
        Height: string
        Width: string
    }
    MimeType: string
    ProductURL: string
}

const PhotosScreen: React.FC = () => {
    const { id } = useParams<{ id: string }>()

    const scrollRef = React.useRef<HTMLDivElement>(null)
    const [selectedMediaItem, setSelectedMediaItem] =
        useState<MediaItem | null>(null)
    const [mediaItems, setMediaItems] = useState<MediaItem[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const videoRef = useRef<HTMLVideoElement | null>(null)

    const volumeTarget = useVolumeTarget()
    const setVolumeTarget = useSetVolumeTarget()
    const volume = useVolume()
    const { t } = useTranslation()

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await api.get('/photos/albums/' + id)
                if (response.data) {
                    setMediaItems(response.data)
                }
                setLoading(false)
            } catch (error) {
                console.error('Error fetching photos:', error)
                setLoading(false)
            }
        }

        fetchPhotos()
    }, [])

    useEffect(() => {
        if (videoRef.current != null) {
            videoRef.current.volume = volume / 100
        }
    }, [volume, selectedMediaItem])

    const handleVideoPlay = () => {
        setVolumeTarget('video')
    }


    useEffect(() => {
        if (volumeTarget !== 'video') {
            if (videoRef.current) {
                videoRef.current.pause()
            }
        }
    }, [volumeTarget])

    const openModal = (mediaItem: MediaItem) => {
        setSelectedMediaItem(mediaItem)
    }

    const closeModal = () => {
        setSelectedMediaItem(null)
    }

    return (
        <div className=" relative flex flex-col gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex justify-between">
                <div className="text-6xl font-medium text-gray-300">Photos</div>
                <ShareButton />
            </div>
            <div
                className="flex gap-6 flex-wrap h-full overflow-auto"
                ref={scrollRef}
            >
                {loading ? (
                    <div className="flex justify-center items-center w-full h-full">
                        <LoadingWheel className="h-20 w-20" />
                    </div>
                ) : mediaItems.length !== 0 ? (
                    mediaItems.map((mediaItem, index) => (
                        <span
                            key={index}
                            className="drop-shadow-md aspect-square h-[47%] -mt-2 bg-orange-600/60 cursor-pointer"
                            onClick={() => openModal(mediaItem)}
                        >
                            <div className="flex flex-col gap-4 justify-center items-center drop-shadow-md h-full text-gray-300 scroll-child">
                                <div
                                    className="w-full h-5/6 bg-center bg-cover"
                                    style={{
                                        backgroundImage: `url(${mediaItem.BaseURL})`,
                                    }}
                                ></div>
                                <p className="text-center text-lg lg:text-2xl 2xl:text-5xl font-medium truncate pb-3 px-3 ">
                                    {mediaItem.Filename}
                                </p>
                            </div>
                        </span>
                    ))
                ) : (
                    <div className="flex justify-center items-center w-full h-full">
                        <p className="text-xl p-3 bg-white rounded">
                            {t('album_page.album empty')}
                        </p>{' '}
                    </div>
                )}
            </div>
            <div className="inline-block">&nbsp;</div>

            {selectedMediaItem && (
                <div
                    className="absolute inset-0 flex items-center justify-center z-50 "
                    onClick={closeModal}
                >
                    <div className="absolute inset-0 bg-black opacity-80"></div>

                    <div className="relative z-10 max-w-3xl mx-auto ">
                        {selectedMediaItem.MimeType.startsWith('video/') ? (
                            <>
                                <video
                                    className=" max-h-full m-auto "
                                    ref={videoRef}
                                    width="80%"
                                    style={{
                                        maxWidth: '50%',
                                        transform: 'scale(2)',
                                    }}
                                    onClick={(event: React.MouseEvent) => {
                                        event.stopPropagation()
                                    }}
                                    onPlay={handleVideoPlay}
                                    controls
                                >
                                    <source
                                        src={selectedMediaItem.BaseURL + '=dv'}
                                        type={selectedMediaItem.MimeType}
                                    />
                                </video>
                            </>
                        ) : (
                            <img
                                className="max-h-full m-auto "
                                src={selectedMediaItem.BaseURL}
                                alt="Selected"
                                onClick={(event: React.MouseEvent) => {
                                    event.stopPropagation()
                                }}
                            />
                        )}
                    </div>
                    <div className="absolute top-0 right-0 z-20">
                        <button
                            className="text-gray-300 p-2 rounded-full hover:bg-gray-700 transition duration-200"
                            onClick={closeModal}
                        >
                            <XCircleIcon className="h-20" />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PhotosScreen
