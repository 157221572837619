import React, { useEffect, useState } from 'react'
import { useSpotify } from '../../../../context/spotify/SpotifyPlayerProvider'
import { TrackCard } from './Cards/Track'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import CardPlayButton from './Buttons/CardPlayButton'
import {
    MusicalNoteIcon,
    HeartIcon as HeartIconSolid,
} from '@heroicons/react/24/solid'
import {
    HeartIcon as HeartIconOutline,
    TrashIcon,
    PencilIcon,
} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import ConfirmationModal from '../../../common/ConfirmationModal'
import axios from 'axios';
import { useTranslation } from 'react-i18next'

export enum PageType {
    Playlist = 'playlist',
    Album = 'album',
    Artist = 'artist',
}

interface DetailsProps {
    feedRef: React.RefObject<HTMLDivElement>
    setExtraScroll?: (number: number) => void
    pageType: PageType
}

const DetailsScreen: React.FC<DetailsProps> = ({
    feedRef,
    setExtraScroll,
    pageType,
}) => {
    const [playlist, setPlaylist] =
        useState<SpotifyApi.SinglePlaylistResponse>()
    const [album, setAlbum] = useState<SpotifyApi.SingleAlbumResponse>()
    const [artist, setArtist] = useState<SpotifyApi.SingleArtistResponse>()

    const [tracks, setTracks] = useState<SpotifyApi.TrackObjectFull[]>([])
    const [uri, setUri] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [image, setImage] = useState<string>('')
    const [owner, setOwner] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [albumArtist, setAlbumArtist] = useState<string>('')

    const [saved, setSaved] = useState<boolean>(false)

    const [owned, setOwned] = useState<boolean>(false)

    const [deleteError, setDeleteError] = useState<string>('')
    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    const { t } = useTranslation()

    const location = useLocation()
    useEffect(() => {
        getData()
    }, [location.pathname])

    const {
        spotifyApi,
        setUpdatePlaylist,
        setCreateModal,
        createModal,
        trackAddModal,
        errorHandling
    } = useSpotify()

    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    const saveElement = async () => {
        try {
            if (id && spotifyApi) {
                switch (pageType) {
                    case PageType.Playlist:
                        {
                            const response = saved
                                ? await spotifyApi.unfollowPlaylist(id)
                                : await spotifyApi.followPlaylist(id)
                            if (response.statusCode === 200) {
                                isSaved()
                            }
                        }
                        break
                    case PageType.Album:
                        {
                            const response = saved
                                ? await spotifyApi.removeFromMySavedAlbums([id])
                                : await spotifyApi.addToMySavedAlbums([id])
                            if (response.statusCode === 200) {
                                isSaved()
                            }
                        }
                        break
                    case PageType.Artist:
                        {
                            const response = saved
                                ? await spotifyApi.unfollowArtists([id])
                                : await spotifyApi.followArtists([id])

                            if (
                                response.statusCode === 200 ||
                                response.statusCode === 204
                            ) {
                                isSaved()
                            }
                        }
                        break
                    default:
                        break
                }
            }
        } catch (e) {
            console.error(e)
            
        }
    }

    const isSaved = async () => {
        try {
            if (id && spotifyApi) {
                switch (pageType) {
                    case PageType.Playlist:
                        {
                            const meResponse = await spotifyApi.getMe()

                            if (playlist?.owner.id === meResponse.body.id) {
                                setOwned(true)
                                break
                            }
                            if (
                                meResponse.body.id &&
                                playlist?.owner.id &&
                                id
                            ) {
                                const response =
                                    await spotifyApi.areFollowingPlaylist(
                                        playlist?.owner.id,
                                        id,
                                        [meResponse.body.id]
                                    )
                                if (response.body[0]) {
                                    setSaved(true)
                                } else {
                                    setSaved(false)
                                }
                            }
                        }
                        break
                    case PageType.Album:
                        {
                            const response =
                                await spotifyApi.containsMySavedAlbums([id])
                            if (response.body[0]) {
                                setSaved(true)
                            } else {
                                setSaved(false)
                            }
                        }
                        break
                    case PageType.Artist:
                        {
                            const response =
                                await spotifyApi.isFollowingArtists([id])
                            if (response.body[0]) {
                                setSaved(true)
                            } else {
                                setSaved(false)
                            }
                        }
                        break
                    default:
                        break
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        isSaved()
    }, [playlist, album, artist, id])

    const getData = () => {
        switch (pageType) {
            case PageType.Playlist:
                getPlaylist()
                break
            case PageType.Album:
                getAlbum()
                break
            case PageType.Artist:
                getArtist()
                break
            default:
                break
        }
    }

    useEffect(() => {
        getData()
        setExtraScroll && setExtraScroll(150)
    }, [])

    useEffect(() => {
        if (!createModal || !trackAddModal) {
            getData()
            if (!createModal) {
                setUpdatePlaylist(null)
            }
        }
    }, [createModal, trackAddModal])

    const getPlaylist = async () => {
        try {
            if (id) {
                const response = await spotifyApi?.getPlaylist(id)
                if (response?.body) {
                    const playlist = response.body
                    setPlaylist(response.body)
                    const filteredTracks = response.body.tracks.items
                        .map((item) => item.track)
                        .filter(
                            (track): track is SpotifyApi.TrackObjectFull =>
                                track !== null
                        )

                    setTracks(filteredTracks)

                    setUri(playlist.uri)
                    setName(playlist.name)
                    setImage(
                        playlist.images[0]?.url ? playlist.images[0].url : ''
                    )
                    setOwner(playlist.owner.display_name || '')
                    setDescription(playlist.description || '')
                }
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }

    const deletePlaylist = async () => {
        try {
            if (id && spotifyApi) {
                const response = await spotifyApi.unfollowPlaylist(id)
                if (response.statusCode === 200) {
                    navigate('/fun/music')
                }
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            setDeleteError(t('music_page.error deleting playlist') || 'Error')


        }
    }

    useEffect(() => {
        setDeleteError('')
    }, [deleteError])

    const getAlbum = async () => {
        try {
            if (id) {
                const response = await spotifyApi?.getAlbum(id)
                if (response?.body) {
                    const album = response.body
                    setAlbum(response.body)
                    setTracks(
                        response.body.tracks.items.map((item) => {
                            const fullItem = item as SpotifyApi.TrackObjectFull
                            fullItem.album = album
                            return fullItem
                        })
                    )
                    setUri(album.uri)
                    setName(album.name)
                    setImage(album.images[0]?.url ? album.images[0].url : '')
                    setAlbumArtist(album.artists[0].name)
                    setDescription(album.label)
                }
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }

    const getArtist = async () => {
        try {
            if (id) {
                const response = await spotifyApi?.getArtist(id)
                if (response?.body) {
                    setArtist(response.body)
                    const artist = response.body
                    setUri(artist.uri)
                    setName(artist.name)
                    setImage(artist.images[0]?.url ? artist.images[0].url : '')
                    setDescription(artist.genres.join(', '))
                }

                const responseTracks = await spotifyApi?.getArtistTopTracks(
                    id,
                    t('music_page.US')
                )
                if (responseTracks?.body.tracks) {
                    setTracks(responseTracks.body.tracks)
                }
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }

    return (
        <>
            <div className="px-4 space-y-3 h-full overflow-auto" ref={feedRef}>
                <div className="flex items-center pt-10 ">
                    {image ? (
                        <img src={image} alt={name} className="w-64 h-64" />
                    ) : (
                        <MusicalNoteIcon className="w-64 h-64" />
                    )}
                    <div className="w-full pl-10">
                        <h1 className="text-white font-semibold text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                            {name}
                        </h1>
                        {owner && (
                            <div className="flex items-center space-x-2 mt-2 text-2xl">
                                <span className="mr-1">{t("music_page.owned by")}</span>

                                <div className="font-bold">
                                    {playlist?.owner.display_name}
                                </div>
                            </div>
                        )}
                        {albumArtist && (
                            <div className="flex items-center space-x-2 mt-2 text-2xl">
                                <span className="mr-1">{t("music_page.artist")}:</span>

                                <div className="font-bold">{albumArtist}</div>
                            </div>
                        )}
                        {description && (
                            <div
                                className={`text-gray-200 text-2xl`}
                                dangerouslySetInnerHTML={{
                                    __html: playlist?.description ?? '',
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="flex justify-between ">
                    <CardPlayButton uri={uri} className={'w-20 h-20 '} />
                    {owned ? (
                        <div className="flex text-gray-300 font-medium text-4xl items-center">
                            <button
                                className="text-gray-300 hover:text-gray-400 mr-3"
                                onClick={() => {
                                    if (playlist) {
                                        setUpdatePlaylist(playlist)
                                    }
                                    setCreateModal && setCreateModal(true)
                                }}
                            >
                                <PencilIcon className="w-12 h-12" />
                            </button>
                            <button
                                className="text-gray-300 hover:text-gray-400 mr-1"
                                onClick={() => setDeleteModal(true)}
                            >
                                <TrashIcon className="w-12 h-12" />
                            </button>
                        </div>
                    ) : (
                        <button
                            aria-label="save this playlist"
                            role="checkbox"
                            className={` text-white
                            transition duration-300 ease`}
                            onClick={saveElement}
                        >
                            {saved ? (
                                <HeartIconSolid className="w-20 h-20" />
                            ) : (
                                <HeartIconOutline className="w-20 h-20" />
                            )}
                        </button>
                    )}{' '}
                </div>
                <div className="">
                    <h2 className="text-3xl text-white">
                     {t("music_page.tracks")}
                    </h2>
                    {tracks.length > 0 && (
                        <>
                            {tracks.map((track, index) => (
                                <TrackCard
                                    key={index}
                                    track={track}
                                    ownerOfPLaylist={owned}
                                    position={index}
                                    snapshotId={playlist?.snapshot_id}
                                    playlistId={playlist?.id}
                                    updateData={getData}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
            {deleteModal && (
                <ConfirmationModal
                    message={`${t("music_page.are you sure you want to delete playlist")}: ${name}?`

                }
                    succesButtonMessage={t("music_page.delete")}
                    errorMessage={deleteError}
                    onSuccess={() => {
                        deletePlaylist()
                    }}
                    onCancel={() => {
                        setDeleteModal(false)
                    }}
                />
            )}
        </>
    )
}

export default DetailsScreen
