import React from 'react'
import SectionLayout from './SectionLayout'
import { CogIcon, ScaleIcon, HeartIcon } from '@heroicons/react/24/outline'
import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import CalendarScreen from './Personal/Calendar/CalendarScreen'
import { useSettings } from '../../hooks/useSettings'
import VitalsScreen from './Health/Vitals/VitalsScreen'
import BarometerScreen from './Health/Barometer/BarometerScreen'

const HealthScreen: React.FC = () => {
    const [settings] = useSettings()
    const { t } = useTranslation()

    const cards = [
        {
            name: t('health_screen.settings'),
            href: '/settings',
            icon: <CogIcon />,
            enabled: true,
        },
        {
            name: t('health_screen.vitalData'),
            href: 'vitals',
            icon: <ScaleIcon />,
            enabled: true,
        },
        {
            name: t('health_screen.calendar'),
            href: 'calendar',
            icon: <CalendarDaysIcon />,
            enabled: true,
        },
        {
            name: t('health_screen.barometer'),
            href: 'barometer',
            icon: <HeartIcon />,
            enabled: true,
        },
    ]

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <SectionLayout
                        section_title={t('footer.health')}
                        main_color="bg-pink-600/60"
                        cards={cards}
                    />
                }
            />

            <Route path="calendar">
                <Route
                    index
                    element={
                        <CalendarScreen
                            canEdit={false}
                            calendarId={settings ? settings?.calendarId : ''}
                            title={t('calendar_page.medical calendar')}
                        />
                    }
                />
            </Route>
            <Route path="vitals" element={<VitalsScreen />} />
            <Route path="barometer" element={<BarometerScreen />} />
        </Routes>
    )
}

export default HealthScreen
