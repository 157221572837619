import { createContext, useContext } from 'react'

import { AuthState, DispatchContextValue } from './Types'

export const AuthContext = createContext<AuthState>({
    authenticated: false,
    user: null,
    loading: true,
})

export const DispatchAuthContext = createContext<DispatchContextValue>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => {},
})

export const useAuthState = () => useContext(AuthContext)
export const useAuthDispatch = () => {
    const dispatchContext = useContext(DispatchAuthContext)
    if (dispatchContext === null) {
        throw new Error('useAuthDispatch must be used within an AuthProvider')
    }
    return dispatchContext.dispatch
}
