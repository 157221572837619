import React, { useEffect, useState } from 'react'
import {
    PauseIcon,
    PlayIcon,
    ArrowPathIcon,
    ForwardIcon,
    BackwardIcon,
    MusicalNoteIcon,
} from '@heroicons/react/24/solid'

import { useSpotify } from '../../../../context/spotify/SpotifyPlayerProvider'
import { SpotifyTrack } from 'react-spotify-web-playback'
import AddTrackButton from './Buttons/AddTrackButton'
import ShuffleIcon from '../../../common/SpecialIcons/ShuffleIcon'

import { useTranslation } from 'react-i18next'

type RepeatState = 'track' | 'context' | 'off'

const Controller = () => {
    const { player, state, play, setPlay, spotifyApi } = useSpotify()
    const [currentTrack, setCurrentTrack] = useState<SpotifyTrack | undefined>(
        undefined
    )
    const { t } = useTranslation()

    useEffect(() => {
        if (state) {
            setCurrentTrack(state.track)
        }
    }, [state])

    const skipToPrevious = () => {
        player?.previousTrack()
    }

    const togglePlay = () => {
        setPlay(!play)
    }

    const skipToNext = () => {
        player?.nextTrack()
    }

    const setShuffle = async () => {
        try {
            if (state && spotifyApi) {
                if (state?.shuffle) {
                    await spotifyApi.setShuffle(false)
                } else {
                    await spotifyApi.setShuffle(true)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const setRepeat = async () => {
        try {
            if (state && spotifyApi) {
                let repeat: RepeatState = 'off'
                switch (state?.repeat) {
                    case 'off':
                        repeat = 'context'
                        break
                    case 'context':
                        repeat = 'track'
                        break
                    case 'track':
                        repeat = 'off'
                        break
                    default:
                        break
                }
                await spotifyApi.setRepeat(repeat)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="bg-gray-50 px-4 py-2 shadow-lg flex justify-between h-32">
            <div className="flex items-center space-x-3 w-1/3">
                {currentTrack && currentTrack?.name ? (
                    <>
                        {currentTrack.image ? (
                            <img
                                src={currentTrack.image}
                                alt={currentTrack.name}
                                className="w-20 h-20"
                            />
                        ) : (
                            <MusicalNoteIcon className="w-20 h-20" />
                        )}
                        <div className="flex flex-col">
                            <div className="text-xl font-bold truncate w-40 md:w-48 lg:w-96">
                                {currentTrack.name}
                            </div>
                            {currentTrack.artists &&
                            currentTrack.artists.length > 0 ? (
                                <div className="text-md truncate w-40 md:w-48 lg:w-96">
                                    {currentTrack.artists[0].name}
                                </div>
                            ) : (
                                <div className="text-xs truncate w-40 md:w-48 lg:w-96">
                                    {t('music_page.unknown artist')}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="text-2xl truncate ">
                        {t('music_page.no current track')}
                    </div>
                )}
            </div>

            <div className="flex justify-center w-1/3 items-center">
                <div className="flex flex-col items-center">
                    <div className="flex  space-x-3">
                        <button
                            aria-label="toggle shuffle"
                            onClick={setShuffle}
                            className={`text-xl  ${
                                state?.shuffle
                                    ? 'text-green-600 hover:text-green-500'
                                    : ' text-black hover:text-gray-800'
                            }`}
                        >
                            <ShuffleIcon className="h-10 w-10" />
                        </button>
                        <button
                            aria-label="skip previous"
                            onClick={skipToPrevious}
                        >
                            <BackwardIcon className="h-14 w-14 text-black hover:text-gray-800" />
                        </button>
                        <button
                            aria-label="toggle play"
                            onClick={togglePlay}
                            className="text-black hover:text-gray-800"
                        >
                            {play ? (
                                <PauseIcon className="h-14 w-14 " />
                            ) : (
                                <PlayIcon className="h-14 w-14" />
                            )}
                        </button>
                        <button aria-label="skip next" onClick={skipToNext}>
                            <ForwardIcon className="h-14 w-14 text-black hover:text-gray-800" />
                        </button>
                        <button
                            aria-label="change repeat mode"
                            onClick={setRepeat}
                            className={`text-xl relative ${
                                state?.repeat !== 'off' ? 'text-green-600' : ''
                            }`}
                        >
                            {state?.repeat === 'track' ? (
                                <div className="relative h-10 w-10">
                                    <ArrowPathIcon className="h-10 w-10" />
                                    <div className="absolute top-1 inset-0">
                                        1
                                    </div>
                                </div>
                            ) : (
                                <ArrowPathIcon className="h-10 w-10" />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <div className="w-1/3 flex justify-end items-center">
                {state?.track.uri && <AddTrackButton uri={state?.track.uri} />}
            </div>
        </div>
    )
}

export default Controller
