import React from 'react'

interface ShuffleProps {
    className?: string
}

const ShuffleIcon: React.FC<ShuffleProps> = ({ className }) => {
    return (
        <svg
            className={className} 
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <rect width="256" height="256" fill="none" />
            <path
                d="M32,72H83.9a7.8,7.8,0,0,1,6.5,3.4l75.2,105.2a7.8,7.8,0,0,0,6.5,3.4H232"
                fill='none'
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
            />
            <polyline
                points="208 48 232 72 208 96"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
            />
            <polyline
                points="208 160 232 184 208 208"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
            />
            <path
                d="M32,184H83.9a7.8,7.8,0,0,0,6.5-3.4l13-18.2"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
            />
            <path
                d="M152.6,93.6l13-18.2a7.8,7.8,0,0,1,6.5-3.4H232"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
            />
        </svg>
    )
}

export default ShuffleIcon
