import React from 'react'


import UnreadMails from './UnreadMails'
import { useSettings } from '../../../hooks/useSettings'
import UnreadMessages from './UnreadMessages'
import CurrentEvents from './CurrentEvents'
import { useTranslation } from 'react-i18next'
/* const notificationArray = [  //dummy notifications example for structure
    {
        icon: <UserIcon />,
        primaryText: 'Andreas is coming to visit',
        secondaryText: 'Tuesday, 12.February',
        color: 'bg-red-600',
    },
    {
        icon: <HeartIcon />,
        primaryText: 'Codivian, 1 pill',
        secondaryText: 'Thursday, 14.February',
        color: 'bg-orange-600',
    },
    {
        icon: <PhoneIcon />,
        singleText: 'Phone',
    },
    {
        icon: <EnvelopeIcon />,
        singleText: 'SMS',
    },
    {
        icon: <VideoCameraIcon />,
        singleText: 'Video-call',
    },
    {
        icon: <VideoCameraIcon />,
        singleText: 'Video-call',
    },
] */

const NotificationsFeed = React.forwardRef<HTMLDivElement>(
    (_: unknown, ref) => {
        const [showingNotifications, setShowingNotifications] =
            React.useState(false)

        const [settings, ,] = useSettings()
        const { t } = useTranslation()

        return (
            <div className="flex flex-col gap-4 overflow-auto w-full" ref={ref}>
                <UnreadMails
                    color="bg-green-400/[.4]"
                    className="scroll-child"
                    setShowingNotifications={setShowingNotifications}
                />
                {settings?.phoneNumber && (
                    <UnreadMessages
                        color="bg-green-400/[.4]"
                        className="scroll-child"
                        setShowingNotifications={setShowingNotifications}
                    />
                )}
                <CurrentEvents
                    calendarId={settings?.calendarId || ''}
                    className="scroll-child"
                    setShowingNotifications={setShowingNotifications}
                />
                <CurrentEvents
                    calendarId={'primary'}
                    className="scroll-child"
                    setShowingNotifications={setShowingNotifications}
                />
                {/* {notificationArray //dummy notifications example for structure
                    ? notificationArray.map((notification, index) => (
                          <NotificationItem
                              key={index}
                              singleText={notification.singleText}
                              primaryText={notification.primaryText}
                              secondaryText={notification.secondaryText}
                              icon={notification.icon}
                              color={notification.color}
                              className="scroll-child"
                          />
                      ))
                    : null} */}

                {!showingNotifications && (
                    <div className={` bg-neutral-400/[.4] flex p-1 h-16`}>
                        <div className="flex items-center justify-center w-full left-16 lg:left-20">
                            <p className="truncate text-white font-medium text-2xl ">
                               { t("notifications.no notifications")}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        )
    }
)
NotificationsFeed.displayName = 'NotificationsFeed'

export default NotificationsFeed
