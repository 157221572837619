const radioStations = [
    //Slovene
    {
        title: 'Radio 1',
        src: 'http://live1.radio1.si/Radio1CE',
        img: 'https://www.freeradiotune.com/wp-content/uploads/2012/11/Radio-1-Ljubljana.jpg',
    },
    {
        title: 'Radio City',
        src: 'https://stream1.radiocity.si/CityMp3128.mp3',
        img: 'http://db.radioline.fr/pictures/radio_5c5d65fe49de15b040ee8eebf3b63bf1/logo200.jpg?size=200',
    },
    {
        title: 'Radio Maribor',
        src: 'http://mp3.rtvslo.si/rmb',
        img: 'https://cdn-radiotime-logos.tunein.com/s36090q.png',
    },
    {
        title: 'Radio Marš',
        src: 'http://frekvenca.eu:8000/radiomars.mp3',
        img: 'https://cdn-radiotime-logos.tunein.com/s9303q.png',
    },
    {
        title: 'Rock Maribor',
        src: 'http://live.rockmaribor.si/RockMB',
        img: 'https://cdn-radiotime-logos.tunein.com/s262916q.png',
    },
    {
        title: 'Stajerski Val',
        src: 'http://stream.stajerskival.si:8004/;',
        img: 'https://cdn-radiotime-logos.tunein.com/s1386q.png',
    },

    // German
    {
        title: 'ERF Plus Österreich',
        src: 'http://stream.erf.at/erfplus_96',
        img: 'https://cdn-radiotime-logos.tunein.com/s9504q.png',
    },
    {
        title: 'Radio Wien',
        src: 'https://orf-live.ors-shoutcast.at/wie-q2a',
        img: 'https://cdn-profiles.tunein.com/s44255/images/logoq.png',
    },
    {
        title: 'Freies Radio Salzkammergut',
        src: 'https://streaming.xaok.org/frs.mp3',
        img: 'https://cdn-radiotime-logos.tunein.com/s68914q.png',
    },
    {
        title: 'FreiRad',
        src: 'https://stream.freirad.at/live.mp3',
        img: 'https://cdn-radiotime-logos.tunein.com/s1580q.png',
    },
    {
        title: 'Hitradio OE3',
        src: 'https://orf-live.ors-shoutcast.at/oe3-q2a',
        img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Hitradio_%C3%963.svg/1200px-Hitradio_%C3%963.svg.png',
    },
    {
        title: 'Radio FM4',
        src: 'https://orf-live.ors-shoutcast.at/fm4-q2a',
        img: 'https://tubestatic.orf.at/mojo/1_3/storyserver//tube/fm4/images/fm4.logo.svg',
    },
    {
        title: 'Orange 94.0',
        src: 'https://securestream.o94.at/live.mp3',
        img: 'https://cdn-radiotime-logos.tunein.com/s18031q.png',
    },
    {
        title: 'Radio Alpina',
        src: 'https://alpina.fluidstream.eu/alpina.mp3',
        img: 'https://cdn-radiotime-logos.tunein.com/s68933q.png',
    },

    {
        title: 'Radio Harmonie',
        src: 'http://stream.radioharmonie.at:9630/',
        img: 'https://cdn-radiotime-logos.tunein.com/s68939q.png',
    },
    {
        title: 'Radio NJOY 91.3',
        src: 'http://94.136.28.9:8005/njoyradio-hd',
        img: 'https://cdn-radiotime-logos.tunein.com/s153013q.png',
    },
    {
        title: 'CR 94.4',
        src: 'https://cr944.at:50443/cr944.mp3',
        img: 'https://cdn-radiotime-logos.tunein.com/s15560q.png',
    },
]

export default radioStations
