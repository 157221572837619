import React from 'react'
import {
    Participant,
    RemoteAudioTrack,
    LocalAudioTrack,
    RemoteVideoTrack,
    LocalVideoTrack,
} from 'twilio-video'
import { UserIcon, CursorArrowRippleIcon } from '@heroicons/react/24/outline'
import NetworkQualityLevel from './NetworkQualityLevel'
import useIsTrackSwitchedOff from '../../hooks/Tracks/useIsTrackSwitchedOff'
import usePublications from '../../hooks/usePublications'
import useTrack from '../../hooks/Tracks/useTrack'
import useParticipantIsReconnecting from '../../hooks/Participants/useParticipantIsReconnecting'
import useIsTrackEnabled from '../../hooks/Tracks/useIsTrackEnabled'
import MuteMicIcon from '../../../../../common/SpecialIcons/MuteMicIcon'
import { useTranslation } from 'react-i18next'

interface ParticipantInfoProps {
    participant: Participant
    children: React.ReactNode
    onClick?: () => void
    isSelected?: boolean
    isLocalParticipant?: boolean
    hideParticipant?: boolean
    isDominantSpeaker?: boolean
}

const ParticipantInfo: React.FC<ParticipantInfoProps> = ({
    participant,
    onClick,
    isSelected,
    children,
    isLocalParticipant,
    isDominantSpeaker,
}) => {
    const publications = usePublications(participant)

    const audioPublication = publications.find((p) => p.kind === 'audio')
    const videoPublication = publications.find(
        (p) => !p.trackName.includes('screen') && p.kind === 'video'
    )

    const isVideoEnabled = Boolean(videoPublication)
    const { t } = useTranslation()

    const videoTrack = useTrack(videoPublication) as
        | LocalVideoTrack
        | RemoteVideoTrack
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack)

    const audioTrack = useTrack(audioPublication) as
        | LocalAudioTrack
        | RemoteAudioTrack
        | undefined
    const isParticipantReconnecting = useParticipantIsReconnecting(participant)

    const isAudioTrackEnabled = useIsTrackEnabled(
        audioTrack as LocalAudioTrack | RemoteAudioTrack
    )

    return (
        <div
            className={` relative flex items-center overflow-hidden mb-2 h-full border-4
        ${isDominantSpeaker ? 'border-green-500' : 'border-gray-100'}`}
            onClick={onClick}
            data-cy-participant={participant.identity}
        >
            <div className="relative z-2 flex flex-col justify-between w-full h-full p-2 bg-transparent">
                <NetworkQualityLevel participant={participant} />
                <div className="absolute bottom-0 left-0 w-full z-10">
                    <span className="bg-black bg-opacity-50 text-white p-1 pr-2 flex items-center z-50">
                        {isAudioTrackEnabled || ( 
                            <MuteMicIcon className="w-6 h-6 text-red-500" />
                        )}

                        <p className="text-white text-xl">
                            {participant.identity}
                            {isLocalParticipant && t('video_page.you')}
                        </p>
                    </span>
                </div>
                <div>{isSelected && <CursorArrowRippleIcon />}</div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full">
                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div className="flex items-center justify-center bg-black absolute top-0 right-0 bottom-0 left-0 ">
                        <UserIcon className="w-10 h-10 text-white" />
                    </div>
                )}
                {isParticipantReconnecting && (
                    <div className=" flex items-center justify-center bg-black bg-opacity-75 absolute top-0 right-0 bottom-0 left-0 z-10 text-xl">
                        <p className="text-white text-2xl">
                            {t('video_page.reconnecting')}...
                        </p>
                    </div>
                )}
                {children}
            </div>
        </div>
    )
}

export default ParticipantInfo
