import React, { useState, useEffect } from 'react'

import SpotifyWebApi from 'spotify-web-api-node'
import { useSpotify } from '../../../../context/spotify/SpotifyPlayerProvider'
import { HorizontalAlbumFeed } from './Cards/Album'
import { HorizontalPlaylistFeed, SavedPlaylistsFeed } from './Cards/Playlist'
import { HorizontalArtistFeed } from './Cards/Artist'
import axios from 'axios'

import { useTranslation } from 'react-i18next'

interface FeedProps {
    feedRef: React.RefObject<HTMLDivElement>
}

const Feed: React.FC<FeedProps> = ({ feedRef }) => {
    const { token, spotifyApi, createModal, errorHandling } = useSpotify()
    const [savedAlbums, setSavedAlbums] = useState<
        SpotifyApi.SavedAlbumObject[] | undefined
    >([])
    const [savedPlaylists, setSavedPlaylists] = useState<
        SpotifyApi.PlaylistObjectSimplified[] | undefined
    >([])
    const [featuredPlaylists, setFeaturedPlaylists] = useState<
        SpotifyApi.PlaylistObjectSimplified[] | undefined
    >([])
    const [followedArtists, setFollowedArtists] = useState<
        SpotifyApi.ArtistObjectFull[] | undefined
    >([])

    const { t } = useTranslation()

    const getSavedAlbums = async (
        spotifyApi: SpotifyWebApi
    ): Promise<SpotifyApi.SavedAlbumObject[]> => {
        try {
            const response = await spotifyApi.getMySavedAlbums({ limit: 50 })
            if (response?.body.items) {
                return response.body.items
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
        return []
    }

    const getSavedPlaylists = async (
        spotifyApi: SpotifyWebApi
    ): Promise<SpotifyApi.PlaylistObjectSimplified[]> => {
        try {
            const response = await spotifyApi?.getUserPlaylists({ limit: 50 })
            if (response?.body.items) {
                return response.body.items
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
        return []
    }

    const getFolowedArtists = async (
        spotifyApi: SpotifyWebApi
    ): Promise<SpotifyApi.ArtistObjectFull[]> => {
        try {
            const response = await spotifyApi?.getFollowedArtists({ limit: 50 })
            if (response?.body.artists.items) {
                return response.body.artists.items
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
        return []
    }

    const getFeaturedPlaylists = async (
        spotifyApi: SpotifyWebApi
    ): Promise<SpotifyApi.PlaylistObjectSimplified[]> => {
        try {
            const response = await spotifyApi?.getFeaturedPlaylists({
                country: t("music_page.US")|| "US", 
                limit: 50,
            })
            if (response?.body.playlists.items) {
                return response.body.playlists.items
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
        return []
    }

    const getData = async () => {
        if (spotifyApi && spotifyApi?.getAccessToken()) {
            setSavedAlbums(await getSavedAlbums(spotifyApi))
            setSavedPlaylists(await getSavedPlaylists(spotifyApi))
            setFollowedArtists(await getFolowedArtists(spotifyApi))
            setFeaturedPlaylists(await getFeaturedPlaylists(spotifyApi))
        }
    }

    useEffect(() => {
        getData()
    }, [token])

    const updateSavedPlaylists = async () => {
        if (!createModal && spotifyApi) {
            setSavedPlaylists(await getSavedPlaylists(spotifyApi))
        }
    }

    useEffect(() => {
        updateSavedPlaylists()
    }, [createModal])

    const sectionTitle = (title: string, show: boolean) => {
        if (show) {
            return (
                <div className="text-3xl mt-1 font-medium text-white">
                    {title}
                </div>
            )
        }
    }

    return (
        <div className="overflow-auto h-full" ref={feedRef}>
            <div className="">
                {sectionTitle(t('music_page.saved playlists'), true)}
                <SavedPlaylistsFeed playlists={savedPlaylists} />

                {sectionTitle(
                    t('music_page.featured playlists'),
                    featuredPlaylists?.length
                        ? featuredPlaylists?.length > 0
                        : false
                )}
                <HorizontalPlaylistFeed playlists={featuredPlaylists} />

                {sectionTitle(
                    t('music_page.saved albums'),
                    savedAlbums?.length ? savedAlbums?.length > 0 : false
                )}
                <HorizontalAlbumFeed albums={savedAlbums} />

                {sectionTitle(
                    t('music_page.followed artists'),
                    followedArtists?.length
                        ? followedArtists?.length > 0
                        : false
                )}
                <HorizontalArtistFeed artists={followedArtists} />
            </div>
        </div>
    )
}

export default Feed
