import React, { useState, useEffect, useRef } from 'react'
import SpotifyLogin from './SpotifyLogin'
import { useSpotify } from '../../../../context/spotify/SpotifyPlayerProvider'
import SpotifySearch from './Search'
import Feed from './Feed'
import Controller from './Controller'
import {
    MagnifyingGlassIcon,
    Bars3CenterLeftIcon,
} from '@heroicons/react/24/solid'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import { Route, Routes } from 'react-router-dom'
import DetailsScreen, { PageType } from './DetailsScreen'
import { useLocation, useNavigate } from 'react-router-dom'
import CreatePlaylistModal from './Modal/CreatePlaylistModal'
import TrackAddModal from './Modal/TrackAddModal'
import { useTranslation } from 'react-i18next'

function MusicScreen() {
    const [searchPage, setSearchPage] = useState<boolean>(false)
    const [extraScroll, setExtraScroll] = useState<number>(0)
    const containerRef = useRef<HTMLDivElement>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { token, createModal, setCreateModal, trackAddModal, userPremium } =
        useSpotify()

    useEffect(() => {
        if (location.pathname === '/fun/music') {
            setSearchPage(false)
        } else {
            setSearchPage(true)
        }
    }, [location])

    const handleModalClose = () => {
        setCreateModal(false)
    }

    return (
        <>
            {!token ? (
                <SpotifyLogin />
            ) : (
                <>
                    {userPremium ? (
                        <div className="relative flex flex-col  pb-4 pb-4 pl-4 pt-6 md:pl-4 md:pt-6 lg:pl-6 lg:pt-8 xl:pl-8 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
                            <div className="flex items-center justify-between pb-1">
                                <div className="w-full text-5xl font-medium text-gray-300">
                                    {t('music_page.music')}
                                </div>

                                <div className="inline-block border w-60">
                                    <div
                                        className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                                        onClick={() => {
                                            if (searchPage) {
                                                navigate('/fun/music')
                                            } else {
                                                navigate('/fun/music/search')
                                            }
                                        }}
                                    >
                                        {searchPage ? (
                                            <>
                                                <span>{t('music_page.feed')}</span>
                                                <span className="w-6">
                                                    <Bars3CenterLeftIcon />
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span>{t('music_page.search')}</span>
                                                <span className="w-6">
                                                    <MagnifyingGlassIcon />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="ml-6">
                                    <ScrollFeedButtons
                                        scrollContainerRef={containerRef}
                                        extraScroll={extraScroll}
                                    />
                                </div>
                            </div>

                            <>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Feed feedRef={containerRef} />
                                        }
                                    />

                                    <Route
                                        path="search"
                                        element={
                                            <SpotifySearch
                                                feedRef={containerRef}
                                                setExtraScroll={setExtraScroll}
                                            />
                                        }
                                    />

                                    <Route
                                        path="playlist/:id"
                                        element={
                                            <DetailsScreen
                                                feedRef={containerRef}
                                                pageType={PageType.Playlist}
                                                setExtraScroll={setExtraScroll}
                                            />
                                        }
                                    />
                                    <Route
                                        path="album/:id"
                                        element={
                                            <DetailsScreen
                                                feedRef={containerRef}
                                                pageType={PageType.Album}
                                                setExtraScroll={setExtraScroll}
                                            />
                                        }
                                    />
                                    <Route
                                        path="artist/:id"
                                        element={
                                            <DetailsScreen
                                                feedRef={containerRef}
                                                pageType={PageType.Artist}
                                                setExtraScroll={setExtraScroll}
                                            />
                                        }
                                    />
                                </Routes>
                            </>

                            <Controller />
                            {createModal && (
                                <CreatePlaylistModal
                                    handleModalClose={handleModalClose}
                                />
                            )}

                            {trackAddModal && <TrackAddModal />}
                        </div>
                    ) : (
                        <div className="flex h-full w-full text-white items-center justify-center text-3xl p-10 text-center">
                            {t('music_page.premium warning')}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default MusicScreen
