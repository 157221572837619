import React from 'react'
import Calendar from 'react-calendar'
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/outline'
import './Calendar.css'
import { isSameDay, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Event } from './EventUtils'

interface CustomCalendarProps {
    events: Event[]
    handleDayClick: (value: Date) => void
    getColortById: (id: string | undefined) => string | undefined
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({
    events,
    handleDayClick,
    getColortById,
}) => {
    const { t } = useTranslation()

    const tileContent = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const filteredEvents = events.filter((event) => {
                const startDate = parseISO(event.start.dateTime)
                const endDate = parseISO(event.end.dateTime)
                return (
                    isSameDay(startDate, date) ||
                    (startDate < date && endDate >= date)
                )
            })

            return (
                <>
                    {filteredEvents.map((event) => (
                        <div key={event.id} className="text-start mt-1 w-full">
                            <div
                                className={` overflow-hidden truncate p-1 rounded ${getColortById(
                                    event.colorId
                                )}`}
                            >
                                {event.summary
                                    ? event.summary
                                    : 'Event (no title)'}
                            </div>
                        </div>
                    ))}
                </>
            )
        }
        return null
    }

    return (
        <div className="w-full h-full flex flex-col ">
            <Calendar
                tileContent={tileContent}
                className="w-full  h-full shadow-lg  text-gray-300 rounded "
                onClickDay={handleDayClick}
                locale={t('calendar_page.en-GB') || 'en-GB'}
                prevLabel={<ChevronLeftIcon className="w-6 h-6" />}
                prev2Label={<ChevronDoubleLeftIcon className="w-6 h-6" />}
                nextLabel={<ChevronRightIcon className="w-6 h-6" />}
                next2Label={<ChevronDoubleRightIcon className="w-6 h-6" />}
            />
        </div>
    )
}

export default CustomCalendar
