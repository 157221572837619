import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react'
import { Call, Device } from '@twilio/voice-sdk'
import api from '../../api'
import { useSetVolumeTarget } from '../volume/VolumeContext'

type TwilioDeviceContextType = {
    device: Device | null
    ready: boolean
    call: Call | null
    setCall: React.Dispatch<React.SetStateAction<Call | null>>
    incomingCall: Call | null
    setIncomingCall: React.Dispatch<React.SetStateAction<Call | null>>
}

const TwilioDeviceContext = createContext<TwilioDeviceContextType>({
    device: null,
    ready: false,
    call: null,
    setCall: () => null,
    incomingCall: null,
    setIncomingCall: () => null,
})

export const useTwilioDevice = () => useContext(TwilioDeviceContext)

interface TwilioDeviceProviderProps {
    children: React.ReactNode
}

export const TwilioDeviceProvider: React.FC<TwilioDeviceProviderProps> = ({
    children,
}) => {
    const tokenRef = useRef<string>('') // Using a ref to store the token
    const [device, setDevice] = useState<Device | null>(null)
    const [ready, setReady] = useState<boolean>(false)
    const [call, setCall] = useState<Call | null>(null)
    const [incomingCall, setIncomingCall] = useState<Call | null>(null)
    const setVolumeTarget = useSetVolumeTarget()

    const initializeDevice = async () => {
        try {
            const response = await api.get('/phone/token')
            if (
                response.data &&
                response.data.data &&
                response.data.data.token
            ) {
                tokenRef.current = response.data.data.token

                const newDevice = new Device(tokenRef.current, {
                    logLevel: 5,
                    codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
                })

                newDevice.on('registered', () => {
                    setReady(true)
                })

                newDevice.on('error', (error) => {
                    console.error('Device Error: ' + error.message)
                    setReady(false)
                })

                newDevice.on('tokenWillExpire', async () => {
                    try {
                        const response = await api.get('/phone/token')
                        if (
                            device &&
                            response.data &&
                            response.data.data &&
                            response.data.data.token
                        ) {
                            device.updateToken(response.data.data.token)
                        }
                    } catch (err) {
                        console.error(err)
                    }
                })
                newDevice.register()

                setDevice(newDevice)
            } else {
                setReady(false)
            }
        } catch (err) {
            console.error(err)
            setReady(false)
        }
    }

    useEffect(() => {
        initializeDevice()

        return () => {
            if (device) {
                device.destroy()
                setReady(false)
            }
        }
    }, []) // Run once on component mount

    useEffect(() => {
        if (device && ready) {
            
            device.on('incoming', async (call: Call) => {
                setIncomingCall(call)
                call.on('disconnect', () => {
                    setIncomingCall(null)
                    setCall(null)
                })
                call.on('cancel', () => {
                    setIncomingCall(null)
                    setCall(null)
                })
                call.on('reject', () => {
                    setIncomingCall(null)
                    setCall(null)
                })
            })
        }
    }, [device, ready])

    useEffect(() => {
        if (call || incomingCall) {
            setVolumeTarget('phone')
        }
    }, [call, incomingCall])

    return (
        <TwilioDeviceContext.Provider
            value={{
                device,
                ready,
                call,
                setCall,
                incomingCall,
                setIncomingCall,
            }}
        >
            {children}
        </TwilioDeviceContext.Provider>
    )
}
