import React, { useRef, useEffect } from 'react'
import { IVideoTrack } from '../../utils/types'
import { Track } from 'twilio-video'
import useMediaStreamTrack from '../../hooks/Tracks/useMediaStreamTrack'
import useVideoTrackDimensions from '../../hooks/Video/useVideoTrackDimensions'

interface VideoTrackProps {
    track: IVideoTrack
    isLocal?: boolean
    priority?: Track.Priority | null
}

export default function VideoTrack({
    track,
    isLocal,
    priority,
}: VideoTrackProps) {
    const ref = useRef<HTMLVideoElement | null>(null)

    const mediaStreamTrack = useMediaStreamTrack(track)
    const dimensions = useVideoTrackDimensions(track)
    const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0)

    useEffect(() => {
        const el = ref.current
        if (el) {
            el.muted = true
            if ('setPriority' in track && priority) {
                track.setPriority(priority)
            }
            track.attach(el)
            return () => {
                track.detach(el)

                // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
                // See: https://github.com/twilio/twilio-video.js/issues/1528
                el.srcObject = null

                if ('setPriority' in track && priority) {
                    // Passing `null` to setPriority will set the track's priority to that which it was published with.
                    track.setPriority(null)
                }
            }
        }
    }, [track, priority])

    // The local video track is mirrored if it is not facing the environment.
    const isFrontFacing =
        mediaStreamTrack?.getSettings().facingMode !== 'environment'
    const style = {
        transform: isLocal && isFrontFacing ? 'scaleX(-1)' : '',
        objectFit:
            isPortrait || track.name.includes('screen')
                ? ('contain' as const)
                : ('cover' as const),
    }

    return <video className="h-full w-full" ref={ref} style={style} />
}
