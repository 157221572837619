import React, { useRef } from 'react'
import Radio from './oldradio.png'
import RadioItem from './RadioItem'
import { useSetVolumeTarget } from '../../../../context/volume/VolumeContext'
import {
    useRadioState,
    useRadioDispatch,
} from '../../../../context/radio/RadioContext'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'

const RadioScreen: React.FC = () => {
    const { stations, selectedStationSrc, isPlaying } = useRadioState()
    const dispatch = useRadioDispatch()
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const setVolumeTarget = useSetVolumeTarget()

    return (
        <div className="flex flex-col gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl overflow-hidden">
            <div className="w-full text-6xl font-medium text-gray-300">
                Radio
            </div>
            <div className="flex overflow-hidden">
                <div className="w-1/2 flex flex-col justify-center">
                    <img
                        src={Radio}
                        alt="Radio"
                        className="h-1/2 object-contain"
                    />
                </div>
                <div className="w-1/2 flex flex-col">
                    <div
                        className="flex flex-col overflow-auto gap-2"
                        ref={scrollContainerRef}
                    >
                        {stations.map((station) => {
                            const isSelected =
                                station.src === selectedStationSrc
                            return (
                                <RadioItem
                                    key={station.src}
                                    title={station.title}
                                    //src={station.src}
                                    img={station.img}
                                    selected={isSelected}
                                    isPlaying={isPlaying && isSelected}
                                    onClick={() => {
                                        if (
                                            station.src === selectedStationSrc
                                        ) {
                                            setVolumeTarget('radio')
                                            dispatch({
                                                type: isPlaying
                                                    ? 'PAUSE'
                                                    : 'PLAY',
                                            })
                                        } else {
                                            setVolumeTarget('radio')
                                            dispatch({
                                                type: 'SELECT_STATION',
                                                src: station.src,
                                            })
                                        }
                                    }}
                                    className="scroll-child"
                                />
                            )
                        })}
                    </div>
                    <div className="mt-2 self-center">
                        <ScrollFeedButtons
                            scrollContainerRef={scrollContainerRef}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RadioScreen
