// SettingsProvider.tsx
import React, { useEffect, useReducer } from 'react'
import { DispatchSettingsContext, SettingsContext } from './SettingsContext'
import settingsReducer from './SettingsReducer'
import { Settings } from './Types'
import api from '../../api'

export const getDefaultSettings = (): Settings => {
    return {
        calendarId: '',
        language: 'en',
        colorBlindMode: 'None',
        useEyeTracker: true,
        pinCode: '0000',
        location: 'Ljubljana',
        newsCategories: ['general'],
        newsLanguages: ['en'],
        newsProviders: ['bbc', 'cnn'],
        phoneNumber: '',
    }
}

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
    const [settings, dispatch] = useReducer(
        settingsReducer,
        getDefaultSettings()
    )

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const res = await api.get('/settings/get')

                if (res.status !== 200) {
                    return
                }

                dispatch({
                    type: 'UPDATE_SETTINGS',
                    payload: res.data.data,
                })
            } catch (err) {
                console.error(err)
            }
        }

        loadSettings()
    }, [])

    return (
        <SettingsContext.Provider value={settings}>
            <DispatchSettingsContext.Provider value={{ dispatch }}>
                {children}
            </DispatchSettingsContext.Provider>
        </SettingsContext.Provider>
    )
}

export default SettingsProvider
