import React, { useEffect, useState } from 'react'
import { useSpotify } from '../../../../context/spotify/SpotifyPlayerProvider'
import { TrackCard } from './Cards/Track'
import { HorizontalPlaylistFeed, PlaylistGridFeed } from './Cards/Playlist'
import { HorizontalAlbumFeed, AlbumGridFeed } from './Cards/Album'
import { HorizontalArtistFeed, ArtistGridFeed } from './Cards/Artist'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

interface SearchProps {
    feedRef: React.RefObject<HTMLDivElement>
    setExtraScroll?: (number: number) => void
}

enum SearchType {
    All = 'all',
    Track = 'track',
    Playlist = 'playlist',
    Album = 'album',
    Artist = 'artist',
}

const SpotifySearch: React.FC<SearchProps> = ({ feedRef, setExtraScroll }) => {
    const [searchParams, setSearchParams] = useState('')
    const [trackResults, setTrackResults] = useState<
        SpotifyApi.TrackObjectFull[]
    >([])
    const [playlistResults, setPlaylistResults] = useState<
        SpotifyApi.PlaylistObjectSimplified[]
    >([])
    const [albumResults, setAlbumResults] = useState<
        SpotifyApi.AlbumObjectSimplified[]
    >([])
    const [artistResults, setArtistResults] = useState<
        SpotifyApi.ArtistObjectFull[]
    >([])

    const [searchType, setSearchType] = useState<SearchType>(SearchType.All)

    const { spotifyApi, errorHandling } = useSpotify()

    const { t } = useTranslation()

    const limit = () => {
        if (searchType === SearchType.All) {
            return 4
        }
        return 50
    }

    const search = () => {
        switch (searchType) {
            case SearchType.All:
                searchTracks()
                searchPlaylists()
                searchAlbums()
                searchArtists()
                setExtraScroll && setExtraScroll(150)
                break
            case SearchType.Track:
                searchTracks()
                setExtraScroll && setExtraScroll(150)
                break
            case SearchType.Playlist:
                searchPlaylists()
                setExtraScroll && setExtraScroll(0)
                break
            case SearchType.Album:
                searchAlbums()
                setExtraScroll && setExtraScroll(0)
                break
            case SearchType.Artist:
                searchArtists()
                setExtraScroll && setExtraScroll(0)
                break
            default:
                break
        }
    }

    useEffect(() => {
        return () => {
            setExtraScroll && setExtraScroll(0)
        }
    }, [])

    useEffect(() => {
        setTrackResults([])
        setPlaylistResults([])
        setAlbumResults([])
        setArtistResults([])
        if (searchParams === '') return
        search()
    }, [searchType])

    const noResults = () => {
        if (
            trackResults.length === 0 &&
            playlistResults.length === 0 &&
            albumResults.length === 0 &&
            artistResults.length === 0
        ) {
            return (
                <div className="text-3xl mt-1 font-medium text-white flex justify-center mt-10">
                    {t('music_page.no results')}
                </div>
            )
        }
    }

    const searchTracks = async () => {
        try {
            const response = await spotifyApi?.searchTracks(searchParams, {
                limit: limit(),
            })
            if (response?.body.tracks) {
                setTrackResults(response.body.tracks.items)
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }

    const searchPlaylists = async () => {
        try {
            const response = await spotifyApi?.searchPlaylists(searchParams, {
                limit: limit(),
            })
            if (response?.body.playlists?.items) {
                setPlaylistResults(response?.body.playlists?.items)
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }

    const searchAlbums = async () => {
        try {
            const response = await spotifyApi?.searchAlbums(searchParams, {
                limit: limit(),
            })
            if (response?.body.albums?.items) {
                setAlbumResults(response?.body.albums?.items)
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }
    const searchArtists = async () => {
        try {
            const response = await spotifyApi?.searchArtists(searchParams, {
                limit: limit(),
            })
            if (response?.body.artists?.items) {
                setArtistResults(response?.body.artists?.items)
            }
        } catch (error) {
            console.error('Error searching Spotify:', error)
            if (axios.isAxiosError(error)) {
                errorHandling(error, true)
            }
        }
    }

    const sectionTitle = (title: string, show: boolean) => {
        if (show) {
            return (
                <div className="text-2xl mt-1 font-medium text-white">
                    {title}
                </div>
            )
        }
    }

    const searchTypeButton = (name: string, type: SearchType) => {
        const selected = searchType === type
        return (
            <button
                className={`${
                    selected
                        ? ' bg-white text-black '
                        : ' bg-gray-700 hover:bg-gray-600 text-white '
                } py-1 px-4 text-xl rounded-2xl`}
                onClick={() => setSearchType(type)}
            >
                {name}
            </button>
        )
    }

    return (
        <>
            <div className="flex items-center space-x-2 mt-5">
                <input
                    type="text"
                    placeholder={t('music_page.enter search parameters') || 'Search'}
                    className="p-2 border rounded-lg w-full text-2xl"
                    value={searchParams}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && search()
                    }}
                    onChange={(e) => setSearchParams(e.target.value)}
                />
                <button
                    className="bg-green-500 text-2xl hover:bg-green-600 text-white px-4 py-2 rounded-lg h-full"
                    onClick={search}
                >
                    {t('music_page.search')}
                </button>
            </div>
            <div className="flex items-center space-x-2 my-1">
                {searchTypeButton(t('music_page.all'), SearchType.All)}
                {searchTypeButton(t('music_page.track'), SearchType.Track)}
                {searchTypeButton(
                    t('music_page.playlist'),
                    SearchType.Playlist
                )}
                {searchTypeButton(t('music_page.album'), SearchType.Album)}
                {searchTypeButton(t('music_page.artist'), SearchType.Artist)}
            </div>
            <div className="h-full overflow-auto " ref={feedRef}>
                {noResults()}
                <div className="mt-4">
                    {sectionTitle(t("music_page.tracks"), trackResults.length > 0)}
                    {trackResults.length > 0 && (
                        <>
                            {trackResults.map((track, index) => (
                                <TrackCard key={index} track={track} />
                            ))}
                        </>
                    )}
                </div>
                <div>
                    {sectionTitle(
                        t('music_page.playlists'),
                        playlistResults.length > 0
                    )}
                    {searchType === SearchType.Playlist ? (
                        <PlaylistGridFeed playlists={playlistResults} />
                    ) : (
                        <HorizontalPlaylistFeed playlists={playlistResults} />
                    )}
                </div>
                <div>
                    {sectionTitle(
                        t('music_page.albums'),
                        albumResults.length > 0
                    )}
                    {searchType === SearchType.Album ? (
                        <AlbumGridFeed albums={albumResults} />
                    ) : (
                        <HorizontalAlbumFeed albums={albumResults} />
                    )}
                </div>
                <div>
                    {sectionTitle(
                        t('music_page.artists'),
                        artistResults.length > 0
                    )}
                    {searchType === SearchType.Artist ? (
                        <ArtistGridFeed artists={artistResults} />
                    ) : (
                        <HorizontalArtistFeed artists={artistResults} />
                    )}
                </div>
            </div>
        </>
    )
}

export default SpotifySearch
