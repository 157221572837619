import React, { useState } from 'react'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useSettings } from '../hooks/useSettings'
import GeneralForm from '../components/settingsComponents/GeneralForm'
import NewsForm from '../components/settingsComponents/NewsForm'
import { NewspaperIcon } from '@heroicons/react/24/solid'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link, Route, Routes } from 'react-router-dom'
import LoadingWheel from '../components/common/LoadingWheel'
import { parseHistory } from '../components/layout/Footer'

const subNavigation = [
    { name: 'General', href: '', icon: Cog6ToothIcon },
    { name: 'News', href: 'news', icon: NewspaperIcon },
]
type FormData = {
    language?: string
    colorBlindMode?: string
    useEyeTracker?: boolean
    pinCode?: string
    location?: string
    newsCategories?: string[]
    newsLanguages?: string[]
    newsProviders?: string[]
    [key: string]: string | boolean | string[] | undefined
}

export default function SettingsPage() {
    const [settings, updateSettings] = useSettings()
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const [formData, setFormData] = useState<FormData>({
        language: settings?.language,
        colorBlindMode: settings?.colorBlindMode,
        useEyeTracker: settings?.useEyeTracker,
        pinCode: settings?.pinCode,
        location: settings?.location,
        newsCategories: settings?.newsCategories,
        newsLanguages: settings?.newsLanguages,
        newsProviders: settings?.newsProviders,
        phoneNumber: settings?.phoneNumber,
    })

    const navigate = useNavigate()
    const location = useLocation()

    const handleChangeSwitch = (name: string, checked: boolean) => {
        setFormData({
            ...formData,
            [name]: checked,
        })
    }

    const handleChangeSelect = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleStringArrayChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target

        // Check if the value is already in the array
        let valueIndex: number
        let selectArray: string[] = []
        if (Array.isArray(formData[name])) {
            valueIndex = (formData[name] as string[]).indexOf(value)
            selectArray = formData[name] as string[]
        } else {
            valueIndex = -1
        }

        if (valueIndex !== undefined && valueIndex !== -1) {
            // Value is already in the array, remove it
            const updatedArray = selectArray.filter(
                (item: string) => item !== value
            )

            setFormData({
                ...formData,
                [name]: updatedArray,
            })
        } else {
            // Value is not in the array, add it
            setFormData({
                ...formData,
                [name]: [...(selectArray || []), value],
            })
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true)
        setError('')
        setSuccessMessage('')

        try {
            const status = await updateSettings(formData)
            if (status === 200) {
                // Display success message
                setSuccessMessage('Settings updated successfully')
            } else {
                setError('Failed to update settings')
            }
        } catch (error) {
            setError('An error occurred')
        }

        setTimeout(() => {
            setSuccessMessage('')
            setError('')
        }, 4000)

        setLoading(false)
    }

    /* const handleReset = () => {
        const confirmed = window.confirm('Are you sure you want to reset?')

        if (confirmed) {
            resetSettings()
        }
    } */

    const handleBack = () => {
        const previousLocation = parseHistory()
        if (previousLocation.length > 0) {
            navigate(previousLocation[previousLocation.length - 1])
        } else {
            navigate('/')
        }
    }

    return (
        <main className="relative  mx-auto max-w-screen-xl pt-10 md:pt-16 lg:pt-20 xl:pt-28 px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow-lg border">
                <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                    <aside className="py-6 lg:col-span-3">
                        <nav className="space-y-1">
                            {subNavigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={
                                        (location.pathname ===
                                            '/settings/' + item.href ||
                                        (location.pathname === '/settings' &&
                                            item.href === '')
                                            ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                                            : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900') +
                                        ' group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                                    }
                                    aria-current={
                                        location.pathname === item.href
                                            ? 'page'
                                            : undefined
                                    }
                                >
                                    <item.icon
                                        className={
                                            (location.pathname === item.href
                                                ? 'text-teal-500 group-hover:text-teal-500'
                                                : 'text-gray-400 group-hover:text-gray-500') +
                                            ' flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                        }
                                        aria-hidden="true"
                                    />
                                    <span className="truncate">
                                        {item.name}
                                    </span>
                                </Link>
                            ))}
                        </nav>
                    </aside>
                    <form
                        className="divide-y divide-gray-200 lg:col-span-9"
                        onSubmit={handleSubmit}
                    >
                        <div className="divide-y divide-gray-200 pt-6">
                            <div className="px-4 sm:px-6">
                                <div>
                                    <h2 className="text-2xl font-medium leading-6 text-gray-900">
                                        Settings
                                    </h2>
                                    <p className="mt-1 text-sm text-gray-500"></p>
                                </div>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <GeneralForm
                                                formData={formData}
                                                handleChangeSwitch={
                                                    handleChangeSwitch
                                                }
                                                handleChangeSelect={
                                                    handleChangeSelect
                                                }
                                                handleChange={handleChange}
                                            />
                                        }
                                    />
                                    <Route
                                        path="news"
                                        element={
                                            <NewsForm
                                                formData={formData}
                                                handleStringArrayChange={
                                                    handleStringArrayChange
                                                }
                                            />
                                        }
                                    />
                                </Routes>
                            </div>
                            <div className="flex flex-row justify-between">
                                <div className="mt-4 flex justify-end py-4 px-4 ">
                                    <button
                                        type="button"
                                        onClick={handleBack}
                                        className="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                    >
                                        Go back
                                    </button>
                                </div>
                                <div className="mt-4 flex justify-end py-4 px-4 ">
                                    {/* <button
                                        type="button"
                                        onClick={handleReset}
                                        className="inline-flex justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium shadow-sm hover:bg-red-700 text-white focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                    >
                                        Reset settings
                                    </button> */}
                                    <div className="flex items-center">
                                        {loading ? (
                                            <LoadingWheel className="w-6 h-6" />
                                        ) : error ? (
                                            <p className="text-red-500 text-base ml-2">
                                                {error}
                                            </p>
                                        ) : null}
                                        {successMessage ? (
                                            <p className="text-green-500 text-base ml-2">
                                                {successMessage}
                                            </p>
                                        ) : null}
                                    </div>

                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className={`ml-1 inline-flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow-sm ${
                                            loading
                                                ? ' bg-gray-500 hover:bg-gray-600 '
                                                : ' bg-teal-600 hover:bg-teal-800 '
                                        }`}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}
