import React, { useEffect, useState } from 'react'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import api from '../../../api'
import { Event } from '../../sections/Personal/Calendar/EventUtils'
import { getColorById } from '../../sections/Personal/Calendar/CalendarScreen'
import { formatShortDate } from '../../sections/Personal/Calendar/CalendarScreen'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface Props {
    className: string
    calendarId: string
    setShowingNotifications: (showing: boolean) => void
}

const CurrentEvents: React.FC<Props> = ({
    className,
    calendarId,
    setShowingNotifications,
}) => {
    const [events, setEvents] = useState<Event[]>([])
    const { t } = useTranslation()

    const fetchEvents = async (): Promise<void> => {
        try {
            const response = await api.get('/calendar/get/' + calendarId)
            if (response.data && response.data.data) {
                if (response.data.data.items) {
                    const currentTime = new Date()
                    const filteredEvents = response.data.data.items.filter(
                        (event: Event) => {
                            if (event.start && event.end) {
                                const eventStart = new Date(
                                    event.start.dateTime
                                )
                                const eventEnd = new Date(event.end.dateTime)
                                // Check if the event is happening at the current time
                                return (
                                    eventStart <= currentTime &&
                                    currentTime <= eventEnd
                                )
                            }
                            return false
                        }
                    )

                    setEvents(
                        filteredEvents.sort((a: Event, b: Event) => {
                            if (a.start && a.end) {
                                const aDate = new Date(a.start.dateTime)
                                const bDate = new Date(b.start.dateTime)
                                if (aDate > bDate) {
                                    return 1
                                }
                            }
                            return -1
                        })
                    )

                    if (filteredEvents.length > 0) {
                        setShowingNotifications(true)
                    }
                }
            } else {
                setEvents([])
            }
        } catch (error) {
            console.error('Error fetching Events:', error)
        }
    }

    useEffect(() => {
        fetchEvents() // Initial fetch

        // Set up an interval to fetch events every 5 minutes (300,000 milliseconds)
        const intervalId = setInterval(() => {
            fetchEvents()
        }, 300000)

        // Cleanup the interval when the component unmounts
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    return (
        <>
            {events.length !== 0 &&
                events.map((event: Event, index) => (
                    <Link
                        key={index}
                        to={
                            calendarId === 'primary'
                                ? '/personal/calendar'
                                : '/health/calendar'
                        }
                        className="w-full"
                    >
                        <div
                            className={`relative ${getColorById(
                                event.colorId
                            )} flex ${className} p-1`}
                        >
                            <div className="h-16 p-2 -ml-1 aspect-square lg:h-20">
                                <CalendarDaysIcon />
                            </div>
                            <div className="absolute top-0 bottom-0 right-0 flex flex-col justify-center left-16 lg:left-20">
                                <p className="font-medium truncate  text-xl lg:text-2xl">
                                    {event.summary
                                        ? event.summary
                                        : t('notifications.event no title')}
                                </p>
                                <p className="truncate  text-base lg:text-lg">
                                    <span>
                                        {formatShortDate(
                                            parseISO(event.start.dateTime)
                                        )}
                                        {' - '}
                                    </span>
                                    <span>
                                        {formatShortDate(
                                            parseISO(event.end.dateTime)
                                        )}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
        </>
    )
}

export default CurrentEvents
