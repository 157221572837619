import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslations from './locales/en/translation.json'
import deTranslations from './locales/de/translation.json'
import slTranslations from './locales/sl/translation.json'

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
        en: {
            translations: enTranslations,
        },
        de: {
            translations: deTranslations,
        },
        sl: {
            translations: slTranslations,
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
})

i18n.languages = ['en', 'de', 'sl']

export default i18n
