import React, { FormEvent } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

interface RoomNameScreenProps {
    name: string
    roomName: string
    setName: (name: string) => void
    setRoomName: (roomName: string) => void
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void
}

const RoomNameScreen: React.FC<RoomNameScreenProps> = ({
    name,
    roomName,
    setRoomName,
    handleSubmit,
}) => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-center justify-center h-full mt-[-3.8rem]">
            <form
                onSubmit={handleSubmit}
                className="flex bg-white px-8 py-4 flex-col rounded-lg "
            >
                <span className="text-lg flex  max-w-md items-center">
                    <InformationCircleIcon className="shrink-0 w-10 h-10 mr-1" />
                    {t('video_page.room instructions')}
                </span>
                <hr className="border-gray-500 border-1" />
                <label htmlFor="roomName" className="text-lg font-medium">
                    {t('video_page.room name')}
                </label>
                <input
                    type="text"
                    id="roomName"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    required
                />

                <label htmlFor="username" className="text-lg font-medium">
                    {t('video_page.username')}
                </label>
                <span className="text-lg underline">{name}</span>

                <button
                    type="submit"
                    className="px-6 py-2 bg-blue-500 text-white text-2xl rounded-lg hover:bg-blue-600 focus:outline-none focus:ring mt-5"
                >
                    {t('video_page.next')}
                </button>
            </form>
        </div>
    )
}

export default RoomNameScreen
