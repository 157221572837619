import React from 'react'
import { Link } from 'react-router-dom'
import CardPlayButton from '../Buttons/CardPlayButton'
import AddTrackButton from '../Buttons/AddTrackButton'
import RemoveTrackButton from '../Buttons/RemoveTrackButton'
import { MusicalNoteIcon } from '@heroicons/react/24/solid'

export const formatDurationMS = (duration_ms: number): string => {
    const seconds = Math.floor(duration_ms / 1000) % 60
    const minutes = Math.floor(duration_ms / 1000 / 60)
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
    )}`
}
type PlaylistTrackProps = {
    track: SpotifyApi.TrackObjectFull
    ownerOfPLaylist?: boolean
    playlistId?: string
    position?: number
    snapshotId?: string
    updateData?: () => void
}

const TrackCard: React.FC<PlaylistTrackProps> = ({
    track,
    ownerOfPLaylist,
    playlistId,
    position,
    snapshotId,
    updateData,
}) => {
    return (
        <div className="scroll-child flex items-center p-2 h-20 space-x-3 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
            <CardPlayButton uri={track.uri} className="w-14 h-14 shrink-0" />
            <Link
                to={'/fun/music/album/' + track.album.id}
                className="flex shrink-0"
            >
                {track.album.images.length === 0 ? (
                    <MusicalNoteIcon className="w-14 h-14" />
                ) : (
                    <img
                        src={track.album.images[0]?.url}
                        alt={track.album.name}
                        className="w-14 h-14"
                    />
                )}
            </Link>
            <div className="flex-1 space-y-0 pl-1 ">
                <div className="font-bold text-lg truncate  max-w-xs md:max-w-[35rem] lg:max-w-3xl">
                    {track.name}
                </div>
                <div className="text-md truncate text-gray-500 max-w-sm md:max-w-[35rem] lg:max-w-3xl">
                    <Link to={'/fun/music/artist/' + track.artists[0].id}>
                        <span>{track.artists[0].name}</span>
                    </Link>
                </div>
            </div>
            <div className="flex items-center shrink-0">
                {ownerOfPLaylist &&
                playlistId &&
                snapshotId &&
                (position || position === 0) &&
                updateData ? (
                    <RemoveTrackButton
                        playlistId={playlistId}
                        position={position}
                        snapshotId={snapshotId}
                        updateData={updateData}
                    />
                ) : (
                    <AddTrackButton uri={track.uri} />
                )}
                <div className="text-lg ml-2">
                    {formatDurationMS(track.duration_ms)}
                </div>
            </div>
        </div>
    )
}

const TrackSkeleton: React.FC<{ hasThumbnail: boolean }> = () => {
    return (
        <div className="flex p-2 h-16 space-x-4">
            <div className="w-5" />
            <div className="w-10 h-10 bg-gray-200 dark:bg-gray-600" />
            <div className="flex-1 space-y-3">
                <div className="h-4 bg-gray-200 dark:bg-gray-600" />
                <div className="h-4 bg-gray-200 dark:bg-gray-600" />
            </div>
            <div className="h-4 bg-gray-200 dark:bg-gray-600">00:00</div>
        </div>
    )
}

export { TrackCard, TrackSkeleton }
