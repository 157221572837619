import React, { useState } from 'react'

import useRoomState from './hooks/Room/useRoomState'
import JoinRoomScreen from './JoinRoomScreen'
import Room from './Components/Room/RoomView'
import DeviceSettingsModal from './Components/DeviceSettings/DeviceSettingsModal'
import useVideoContext from './hooks/Video/useVideoContext'
import InviteUsersModal from './Components/MenuBar/InviteUsersModal'

import usePrompt from './hooks/usePrompt'
import { useTranslation } from 'react-i18next'

const VideoApp: React.FC = () => {
    const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false)
    const [inviteModalOpen, setInviteModalOpen] = useState(false)

    const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false)
    const { room } = useVideoContext()

    const roomState = useRoomState()

    const { t } = useTranslation()

    return (
        <div className="relative flex flex-col overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            {roomState === 'disconnected' ? (
                <>
                    <div className="w-full text-5xl font-medium text-gray-300 mt-3 ml-3">
                        {'Video Calls'}
                    </div>

                    <JoinRoomScreen
                        setDeviceSettingsOpen={setDeviceSettingsOpen}
                    />
                </>
            ) : (
                <>
                    <Room
                        setDeviceSettingsOpen={setDeviceSettingsOpen}
                        setInviteModalOpen={setInviteModalOpen}
                    />

                    {inviteModalOpen && room && (
                        <InviteUsersModal
                            open={inviteModalOpen}
                            onClose={() => setInviteModalOpen(false)}
                            roomName={room?.name}
                        />
                    )}
                </>
            )}

            {deviceSettingsOpen && (
                <DeviceSettingsModal
                    open={deviceSettingsOpen}
                    onClose={() => setDeviceSettingsOpen(false)}
                />
            )}

            {usePrompt({
                when: room !== null && roomState !== 'disconnected',
                message: t('video_page.leave message'),
                onConfirm: () => {
                    room?.disconnect()
                },
                onCancel: () => {
                    //console.log('cancel')
                },
                showLeaveConfirmation,
                setShowLeaveConfirmation,
            })}
        </div>
    )
}

export default VideoApp
