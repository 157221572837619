import React, { useCallback, useRef } from 'react'

import {
    VideoCameraIcon,
    VideoCameraSlashIcon,
} from '@heroicons/react/24/outline'

import useDevices from '../../hooks/useDevices'
import useLocalVideoToggle from '../../hooks/Tracks/useLocalVideoToggle'
import { useTranslation } from 'react-i18next'

export default function ToggleVideoButton(props: {
    disabled?: boolean
    className?: string
}) {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
    const lastClickTimeRef = useRef(0)
    const { hasVideoInputDevices } = useDevices()

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now()
            toggleVideoEnabled()
        }
    }, [toggleVideoEnabled])

    const { t } = useTranslation()

    return (
        <button
            className={`flex items-center ${props.className}`}
            onClick={toggleVideo}
            disabled={!hasVideoInputDevices || props.disabled}
            data-cy-audio-toggle
        >
            {isVideoEnabled ? (
                <VideoCameraIcon className="mr-2 w-8 h-8" />
            ) : (
                <VideoCameraSlashIcon className="mr-2 w-8 h-8" />
            )}
            <span className="w-28">
                {!hasVideoInputDevices
                    ? t('video_page.no video')
                    : isVideoEnabled
                    ? t('video_page.stop video')
                    : t('video_page.start video')}
            </span>
        </button>
    )
}
