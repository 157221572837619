import React, { useEffect, useState } from 'react'
import api from '../../../../api'

import { useTranslation } from 'react-i18next'
import { BarometerInput } from './BarometerInput'
import { BarometerHistory } from './BarometerHistory'

export interface Vital {
    data: string
    date: Date
    id: string
}

export interface Feeling {
    id: string
    pain: string
    feeling: string
    date: Date
}

const BarometerScreen: React.FC = () => {
    const { t } = useTranslation()

    const [data, setData] = useState<Feeling[]>([])

    const [indexPain, setIndexPain] = useState(-1)
    const [indexFeel, setIndexFeel] = useState(-1)

    const [inputValuePain, setInputValuePain] = useState('')
    const [inputValueFeel, setInputValueFeel] = useState('')

    const [canSubmit, setCanSubmit] = useState(true)

    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setErrorMessage('')
    }, [inputValueFeel, inputValuePain])

    const handleSubmit = async () => {
        const currentDate = new Date()

        if (inputValuePain === '' || inputValueFeel === '') {
            setErrorMessage(t('barometer_page.cannot submit empty form') || '')
            return
        }

        try {
            const response = await api.post('/feeling/add', {
                pain: parseInt(inputValuePain),
                feeling: parseInt(inputValueFeel),
                date: currentDate.toISOString(),
            })

            if (response.status === 200) {
                setIndexPain(-1)
                setIndexFeel(-1)
                setInputValuePain('')
                setInputValueFeel('')

                fetchData()
            } else {
                console.error('Error submitting data')
            }
        } catch (error) {
            console.error('An error occurred while submitting data:', error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (data?.length > 0) {
            const latestEntry = data[0]
            const lastSubmissionTime = new Date(latestEntry.date)
            const currentTime = new Date()

            const timeDifference =
                (currentTime.getTime() - lastSubmissionTime.getTime()) /
                (1000 * 60 * 60)

            if (timeDifference < 3) {
                setCanSubmit(false)
            } else {
                setCanSubmit(true)
            }
        } else {
            setCanSubmit(true)
        }
    }, [data])

    const fetchData = async () => {
        try {
            const res = await api.get('/feeling/get')

            if (res.status === 200) {
                const sortedData = res.data.data.data.sort(
                    (a: Feeling, b: Feeling) => {
                        const dateA = new Date(a.date)
                        const dateB = new Date(b.date)

                        return dateB.getTime() - dateA.getTime()
                    }
                )

                setData(sortedData)
            } else {
                console.error('Error retrieving data')
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error)
        }
    }

    return (
        <div className="relative flex flex-col  gap-2 pb-4 px-4 pt-6 md:pt-6  lg:pt-8 xl:px-8 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between mb-3">
                <div className="w-full text-5xl font-medium text-gray-300">
                    Barometer
                </div>
            </div>
            <div className="flex overflow-hidden gap-4 h-full">
                <div className="flex flex-col gap-8 w-2/3 h-full items-center justify-center ">
                    <BarometerInput
                        clickedIndex={indexPain}
                        setClickedIndex={setIndexPain}
                        setInputValue={setInputValuePain}
                        reversed={true}
                        title={t('barometer_page.pain_paragraph')}
                    />
                    <BarometerInput
                        clickedIndex={indexFeel}
                        setClickedIndex={setIndexFeel}
                        setInputValue={setInputValueFeel}
                        title={t('barometer_page.feel_paragraph')}
                    />

                    <div className="flex justify-center flex-col gap-4  ">
                        <button
                            onClick={canSubmit ? handleSubmit : undefined}
                            className={`  uppercase font-bold py-4 px-8 rounded mx-auto border-white border ${
                                canSubmit
                                    ? 'bg-pink-600 hover:bg-pink-700 text-white'
                                    : 'bg-gray-400 text-gray-500'
                            }`}
                        >
                            {t('barometer_page.add')}
                        </button>
                        {!canSubmit && (
                            <div className="text-white font-medium text-lg text-center">
                                {t('barometer_page.submit_limit')}
                            </div>
                        )}
                        {errorMessage ? (
                            <div className="bg-white text-red-500 text-lg h-10 flex  items-center justify-center rounded-lg font-medium px-2">
                                {errorMessage}
                            </div>
                        ) : (
                            <div className="h-10" />
                        )}
                    </div>
                </div>
                <div className="h-full w-1/3 ">
                    <BarometerHistory
                        data={data}
                        setData={(data: Feeling[]) => setData(data)}
                    />
                </div>
            </div>
        </div>
    )
}

export default BarometerScreen
