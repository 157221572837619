import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const GameScreen: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()

    const games = [
        {
            title: 'Sudoku',
            link: 'https://cdn.htmlgames.com/LifeSudoku/',
        },
        {
            title: t('game_page.chess'),
            link: 'https://cdn.htmlgames.com/Chess/',
        },
        {
            title: 'Mahjong',
            link: 'https://cdn.htmlgames.com/MahjongTower/',
        },
        {
            title: t('game_page.solitaire'),
            link: 'https://cdn.htmlgames.com/BunnySolitaire/',
        },
        {
            title: '2048',
            link: 'https://cdn.htmlgames.com/Reach2048/',
        },
    ]

    const selectedGame = games.find((game) => game.title === id)

    if (!selectedGame) {
        return <div>Game not found</div>
    }

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-6xl font-medium text-gray-300">
                {selectedGame.title}
            </h1>
            <iframe
                src={selectedGame.link}
                title={selectedGame.title}
                height="600"
                width="800"
                allow="autoplay; fullscreen"
                allowFullScreen
            />
        </div>
    )
}

export default GameScreen
