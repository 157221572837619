import React from 'react'
import { MusicalNoteIcon } from '@heroicons/react/24/solid'

interface Props {
    title: string
    //src: string;
    img: string
    selected: boolean
    isPlaying: boolean
    onClick: () => void
    className?: string
}

const RadioItem: React.FC<Props> = ({
    title,
    /* src,*/ img,
    selected,
    isPlaying,
    onClick,
    className,
}) => {
    return (
        <div
            onClick={onClick}
            className={`relative bg-opacity-60 hover:bg-opacity-80 w-full cursor-pointer ${
                selected ? 'bg-sky-700/60' : 'bg-black'
            } flex items-center ${className} px-4 py-2 h-fit`}
        >
            <img src={img} alt={title} className="w-16 lg:w-20" />
            <p className="ml-4 text-xl lg:text-2xl truncate text-gray-300">
                {title}
            </p>
            {selected && isPlaying && (
                <div className="w-8">
                    <MusicalNoteIcon className="absolute right-6 top-5 w-10 h-8 text-gray-300 animate-pulse" />
                    <MusicalNoteIcon className="absolute right-2 top-9 w-10 h-14 text-gray-300 animate-pulse" />
                </div>
            )}
        </div>
    )
}

export default RadioItem
