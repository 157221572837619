import React, { useState } from 'react'
import api from '../../../../api'
import { useTranslation } from 'react-i18next'
import { Contact } from './PhoneScreen'
import LoadingWheel from '../../../common/LoadingWheel'

interface CreateAlbumModalProps {
    handleAddContact: (contacts: Contact[]) => void
    handleModalClose: () => void
    updateContact: Contact | undefined
}

const AddContactModal: React.FC<CreateAlbumModalProps> = ({
    handleAddContact,
    handleModalClose,
    updateContact,
}) => {
    const [name, setName] = useState<string>(
        updateContact ? updateContact.name : ''
    )
    const [number, setNumber] = useState<string>(
        updateContact ? updateContact.number : ''
    )
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { t } = useTranslation()

    const modalClose = () => {
        setName('')
        setNumber('')
        handleModalClose()
    }

    const handleModalSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault()
        setIsLoading(true)
        setErrorMessage('')

        const numberRegex =
            /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!numberRegex.test(number) && !emailRegex.test(number)) {
            const s = t('phone_page.format error')
            setErrorMessage(s)
            setIsLoading(false)
            return
        }

        try {
            const response = updateContact
                ? await api.put('/phone/contact/' + updateContact.id, {
                      Id: updateContact.id,
                      Name: name,
                      Number: number,
                  })
                : await api.post('/phone/contact', {
                      Name: name,
                      Number: number,
                  })
            if (response.data && response.data.data) {
                handleAddContact(response.data.data.contacts)
                modalClose()

            }
        } catch (error) {
            if (updateContact) {
                console.error('Error updating contact:', error)
                const s = t('phone_page.update error')
                setErrorMessage(s)
            } else {
                console.error('Error creating contact:', error)
                const s = t('phone_page.create error')
                setErrorMessage(s)
            }
        }

        setIsLoading(false)
    }

    return (
        <div className="absolute inset-0 flex items-center justify-center z-50 ">
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={modalClose}
            ></div>
            <div className="bg-white rounded-lg p-6 z-20 w-80">
                <h2 className="text-3xl font-semibold mb-4">
                    {updateContact
                        ? t('phone_page.update the contact')
                        : t('phone_page.add a contact')}
                </h2>
                <form onSubmit={handleModalSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="albumName"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('phone_page.name')}
                        </label>
                        <input
                            type="text"
                            id="albumName"
                            className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="albumName"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('phone_page.number or email')}
                        </label>
                        <input
                            type="text"
                            id="albumName"
                            className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                            value={number}
                            onChange={(e) => {
                                setNumber(e.target.value)
                                setErrorMessage('')
                            }}
                            required
                        />
                    </div>

                    {errorMessage && (
                        <div className="text-red-500 mb-4">{errorMessage}</div>
                    )}

                    <div className="flex justify-between">
                        <button
                            type="button"
                            className="mr-2 px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none text-xl"
                            onClick={modalClose}
                        >
                            {t('phone_page.cancel')}
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none text-xl"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <LoadingWheel className="w-6 h-6" />
                            ) : updateContact ? (
                                t('phone_page.update')
                            ) : (
                                t('phone_page.add')
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddContactModal
