import React from 'react'
import useVideoContext from '../../hooks/Video/useVideoContext'
import { useTranslation } from 'react-i18next'

export default function EndCallButton(props: { className?: string }) {
    const { room } = useVideoContext()
    const { t } = useTranslation()

    const handleClick = () => {
        if (room) {
            room.disconnect()
        }
    }

    return (
        <button
            onClick={handleClick}
            className={`text-xl font-medium text-white px-4 py-2 rounded bg-red-500 hover:bg-red-700 
        ${props.className}`}
        >
            {t('video_page.disconnect')}
        </button>
    )
}
