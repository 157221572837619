import React, { useState, useEffect } from 'react'
import { PhoneIcon, PhoneXMarkIcon } from '@heroicons/react/24/outline'
import { Contact } from './PhoneScreen'
import { useTwilioDevice } from '../../../../context/Twilio/TwilioDeviceContext'
import { useTranslation } from 'react-i18next'

interface Props {
    callingContact?: Contact | undefined
    contacts?: Contact[]
}

const CallComponent: React.FC<Props> = ({ callingContact, contacts }) => {
    const [callInput, setCallInput] = useState(callingContact?.number || '')
    const [callInputError, setCallInputError] = useState('')
    const { device, ready, call, setCall, incomingCall, setIncomingCall } =
        useTwilioDevice()
    const [caller, setCaller] = useState<string | null>(null)
    const [callStatus, setCallStatus] = useState<string | null>(null)
    const [dtmfDigits, setDtmfDigits] = useState<string>('')
    const { t } = useTranslation()

    const findMatchingContact = (caller: string) => {
        if (!contacts) {
            return caller
        }
        for (const contact of contacts) {
            if (contact.number === caller) {
                return contact.name
            }
        }
        return caller
    }

    useEffect(() => {
        if (incomingCall) {
            setCaller(incomingCall.parameters.From)
            const s = t('phone_page.incoming call')
            setCallStatus(s)
        }
    }, [incomingCall])

    const rejectCall = () => {
        if (incomingCall) {
            incomingCall.reject()
            setIncomingCall(null)
        }
    }

    const hangupCall = () => {
        if (call) {
            const s = t('phone_page.hanging up')
            setCallStatus(s)
            call.disconnect()
        }
    }

    const acceptCall = () => {
        if (incomingCall) {
            incomingCall.accept()
            setCaller(incomingCall.parameters.From)
            setCall(incomingCall)
            const s = t('phone_page.call in progress')
            setCallStatus(s)
            setIncomingCall(null)
        }
    }

    const makeOutgoingCall = async (to: string) => {
        if (device && ready) {
            const params = { To: to }
            setCaller(to)
            const outgoingCall = await device.connect({ params })
            outgoingCall.on('disconnect', () => {
                setCall(null)
            })
            outgoingCall.on('cancel', () => {
                setCall(null)
            })

            outgoingCall.on('accept', () => {
                const s = t('phone_page.call in progress')
                setCallStatus(s)
            })

            setCall(outgoingCall)
            const s = t('phone_page.calling')
            setCallStatus(s)
        }
    }

    const handleAnswerButton = async () => {
        if (incomingCall) {
            acceptCall()
        } else {
            if (!callInput) {
                const s = t('phone_page.input error')
                setCallInputError(s)
                return
            }
            await makeOutgoingCall(callInput)
        }
    }
    const handleCancelButton = () => {
        if (incomingCall) {
            rejectCall()
        }
        if (call) {
            hangupCall()
        }
    }

    return (
        <div className={`flex flex-col overflow-auto h-full`}>
            <div className="flex flex-col justify-center mt-10">
                <div className="flex justify-center">
                    {incomingCall || call ? (
                        <div>
                            <div className="flex text-gray-300 font-medium text-4xl justify-center w-full mt-4 mb-2 ">
                                {caller
                                    ? findMatchingContact(caller)
                                    : t('phone_page.unknown caller')}
                            </div>
                            <div className="flex text-gray-300 font-medium text-2xl justify-center w-full mt-4 mb-2 ">
                                {callStatus ? callStatus : 'calling'}
                            </div>
                        </div>
                    ) : (
                        <input
                            type="text"
                            value={callInput}
                            onChange={(e) => {
                                setCallInput(e.target.value)
                                setCallInputError('')
                            }}
                            className="px-4 py-2 border border-gray-300 rounded-lg text-xl text-gray-700 focus:outline-none focus:ring focus:ring-indigo-500 w-72 mt-5"
                            placeholder={
                                t('phone_page.enter number or email') || ''
                            }
                        />
                    )}
                </div>
                {call && (
                    <div className="flex justify-center mt-5">
                        <input
                            type="text"
                            value={dtmfDigits}
                            onChange={(e) => setDtmfDigits(e.target.value)}
                            className="px-4 py-2 border border-gray-300 rounded-lg text-xl text-gray-700 focus:outline-none focus:ring focus:ring-indigo-500 w-24"
                            placeholder="DTMF"
                        />
                        <button
                            className="ml-3 px-4 py-2 bg-blue-500 text-white rounded-lg"
                            onClick={() => {
                                setDtmfDigits('')
                                call.sendDigits(dtmfDigits)
                            }}
                        >
                            {t('phone_page.send DTMF')}
                        </button>
                    </div>
                )}
                <div className="flex justify-center">
                    {(incomingCall || !call) && (
                        <button
                            className={`flex items-center justify-center border-4 rounded-xl w-28 h-28 border-gray-300 bg-green-600 hover:bg-green-800/70 mt-10`}
                            onClick={handleAnswerButton}
                        >
                            <PhoneIcon className="w-20 h-20 self-center text-gray-300" />
                        </button>
                    )}{' '}
                    {(incomingCall || call) && (
                        <button
                            className={`flex items-center justify-center border-4 rounded-xl w-28 h-28 border-gray-300 bg-red-500 hover:bg-red-700/70 mt-10 ${
                                incomingCall && 'ml-10'
                            }`}
                            onClick={handleCancelButton}
                        >
                            <PhoneXMarkIcon className="w-20 h-20 self-center text-gray-300" />
                        </button>
                    )}
                </div>
                {callInputError && (
                    <div className="flex text-red-500 mt-2 justify-center bg-white rounded w-68 text-lg p-2 mx-auto">
                        {callInputError}
                    </div>
                )}
            </div>
        </div>
    )
}

CallComponent.displayName = 'ContactsFeed'

export default CallComponent
