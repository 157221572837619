import React, { useEffect, useReducer } from 'react'
import authReducer from './AuthReducer'
import { useCookies } from 'react-cookie'
import api from '../../api'
import { AuthContext, DispatchAuthContext } from './AuthContext'

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authenticated: false,
        loading: true,
    })

    const [sessionid] = useCookies(['mysession'])

    useEffect(() => {
        const loadUser = async () => {
            try {
                if (sessionid === null || sessionid === undefined) {
                    return
                }
                const res = await api.get('/users/me')
                const user = res.data.data
                if (res.status !== 200) {
                    return
                }
                dispatch({ type: 'LOGIN', payload: user })
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err)
            } finally {
                dispatch({ type: 'STOP_LOADING' })
            }
        }

        loadUser()
    }, [])

    return (
        <AuthContext.Provider value={state}>
            <DispatchAuthContext.Provider value={{ dispatch }}>
                {children}
            </DispatchAuthContext.Provider>
        </AuthContext.Provider>
    )
}
