import React from 'react'
import { UserIcon } from '@heroicons/react/24/outline'
import { LocalVideoTrack } from 'twilio-video'
import VideoTrack from '../Participant/VideoTrack'
import useVideoContext from '../../hooks/Video/useVideoContext'
import useIsTrackEnabled from '../../hooks/Tracks/useIsTrackEnabled'
import { LocalAudioTrack, RemoteAudioTrack } from 'twilio-video'
import MuteMicIcon from '../../../../../common/SpecialIcons/MuteMicIcon'

export default function LocalVideoPreview(props: { identity: string }) {
    const { localTracks } = useVideoContext()
    const videoTrack = localTracks.find(
        (track) => !track.name.includes('screen') && track.kind === 'video'
    ) as LocalVideoTrack

    const audioTrack = localTracks.find(
        (track) => track.kind === 'audio'
    ) as LocalAudioTrack

    const isAudioTrackEnabled = useIsTrackEnabled(
        audioTrack as LocalAudioTrack | RemoteAudioTrack
    )

    return (
        <div className="relative overflow-hidden bg-black h-48 w-80">
            <div className="w-full h-full">
                {videoTrack ? (
                    <VideoTrack track={videoTrack} isLocal />
                ) : (
                    <div className="flex items-center justify-center bg-black  h-full">
                        <UserIcon className="w-10 h-10 text-white" />
                    </div>
                )}
            </div>
            <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white py-1 px-2 flex items-center w-full">
                {isAudioTrackEnabled || (
                    <MuteMicIcon className="w-8 h-8 text-red-500" />
                )}

                <span className="ml-1">{props.identity}</span>
            </div>
        </div>
    )
}
