import React from 'react'
import newsPlaceholder from './news-placeholder.jpg'
import { useTranslation } from 'react-i18next'

interface Props {
    title: string | undefined
    description: string | undefined
    image: string | undefined
    author: string | undefined
    url: string | undefined
    category: string | undefined
    source: string | undefined
    className?: string
}

const NewsDetails: React.FC<Props> = ({
    title,
    description,
    image,
    author,
    url,
    category,
    source,
    className,
}) => {
    const openPopupWindow = () => {
        if (url) {
            const width = 600
            const height = 500
            const left = window.innerWidth / 2 + width * 2
            const top = window.innerHeight / 2 - height

            const options = `
        width=${width},
        height=${height},
        left=${left},
        top=${top},
        resizable=yes,
        scrollbars=yes
      `

            window.open(url, '_blank', options)
        }
    }
    const { t } = useTranslation()

    return (
        <div
            className={`flex justify-center bg-white p-2 overflow-auto ${className}`}
        >
            <div className="w-2/5">
                <div
                    className=" bg-cover bg-no-repeat bg-center mr-4 h-80"
                    style={{
                        backgroundImage: `url(${
                            image ? image : newsPlaceholder
                        })`,
                    }}
                />

                {source && <p className="mt-4 text-xl font-bold">{source}</p>}
                {category && <p className="mt-2">Category: {category}</p>}
                {author && <p className="mt-2">Author: {author}</p>}
                {url && (
                    <div className="mt-4">
                        <button
                            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            onClick={openPopupWindow}
                        >
                            {t('media_page.read more')}
                        </button>
                    </div>
                )}
            </div>
            <div className="w-3/5 ml-2">
                {title && (
                    <h2 className="text-3xl font-medium mb-3 lg:text-4xl xl:text-5xl">
                        {title}
                    </h2>
                )}
                {description && (
                    <p className="text-lg lg:text-xl">{description}</p>
                )}
            </div>
        </div>
    )
}

export default NewsDetails
