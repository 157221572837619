import React, { useState } from 'react'

import { useSpotify } from '../../../../../context/spotify/SpotifyPlayerProvider'
import { useTranslation } from 'react-i18next'

type RemoveTrackButtonProps = {
    className?: string
    playlistId: string
    position: number
    snapshotId: string
    updateData: () => void
}

const RemoveTrackButton: React.FC<RemoveTrackButtonProps> = ({
    className,
    playlistId,
    position,
    snapshotId,
    updateData,
}) => {
    const { spotifyApi } = useSpotify()

    const [error, setError] = useState<string | undefined>(undefined)

    const { t } = useTranslation()


    const removeTrack = async () => {
        try {
            if (spotifyApi) {
                const response =
                    await spotifyApi.removeTracksFromPlaylistByPosition(
                        playlistId,
                        [position],
                        snapshotId
                    )
                if (response.statusCode === 200) {
                    updateData()
                }
            }
        } catch (err) {
            console.error(err)

            setError(t('music_page.error') || 'Error')
            setTimeout(() => {
                setError(undefined)
            }, 3000)
        }
    }

    return (
        <>
            <button
                className={
                    'bg-red-600 p-3 rounded-lg text-white font-semibold truncate ' +
                    className
                }
                onClick={() => {
                    removeTrack()
                }}
            >
                {error ? error : t('music_page.remove from playlist') 
                }
            </button>
        </>
    )
}

export default RemoveTrackButton
