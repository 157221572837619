import React from 'react'
import { GalleryView } from './GalleryView'
import { ParticipantAudioTracks } from './ParticipantAudioTracks'
import MenuBar from '../MenuBar/MenuBar'
import TopBar from '../MenuBar/TopBar'
import { PaginationProvider } from '../../hooks/usePagination'

export default function Room({
    setDeviceSettingsOpen,
    setInviteModalOpen,
}: {
    setDeviceSettingsOpen: (open: boolean) => void
    setInviteModalOpen: (open: boolean) => void
}) {
    return (
        <>
            {/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
            <PaginationProvider>
                <TopBar
                    setDeviceSettingsOpen={setDeviceSettingsOpen}
                    setInviteModalOpen={setInviteModalOpen}
                />
                <ParticipantAudioTracks />

                <GalleryView />
                <MenuBar />
            </PaginationProvider>
        </>
    )
}
