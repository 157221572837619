import React, { createContext, ReactNode, useCallback } from 'react'
import {
    CreateLocalTrackOptions,
    ConnectOptions,
    LocalAudioTrack,
    LocalVideoTrack,
    Room,
    TwilioError,
} from 'twilio-video'
export type ErrorCallback = (error: TwilioError | Error) => void
import { SelectedParticipantProvider } from '../Participants/useSelectedParticipant'

import useHandleRoomDisconnection from '../Room/useHandleRoomDisconnection'
import useHandleTrackPublicationFailed from '../Room/useHandleTrackPublicationFailed'
import useLocalTracks from '../Tracks/useLocalTracks'
import useRestartAudioTrackOnDeviceChange from '../Tracks/useRestartAudioTrackOnDeviceChange'
import useRoom from '../Room/useRoom'

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
    room: Room | null
    localTracks: (LocalAudioTrack | LocalVideoTrack)[]
    isConnecting: boolean
    connect: (token: string) => Promise<void>
    onError: ErrorCallback
    getLocalVideoTrack: (
        newOptions?: CreateLocalTrackOptions
    ) => Promise<LocalVideoTrack>
    isAcquiringLocalTracks: boolean
    removeLocalVideoTrack: () => void
    getAudioAndVideoTracks: () => Promise<void>
}

// eslint-disable-next-line
export const VideoContext = createContext<IVideoContext>(null!)

interface VideoProviderProps {
    options?: ConnectOptions
    onError: ErrorCallback
    children: ReactNode
}

export function VideoProvider({
    options,
    children,
    onError = (error: Error) => console.error(error),
}: VideoProviderProps) {
    const onErrorCallback: ErrorCallback = useCallback(
        (error) => {
            console.error(`ERROR: ${error.message}`, error)
            onError(error)
        },
        [onError]
    )

    const {
        localTracks,
        getLocalVideoTrack,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
    } = useLocalTracks()
    const { room, isConnecting, connect } = useRoom(
        localTracks,
        onErrorCallback,
        options
    )

    //const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

    // Register callback functions to be called on room disconnect.
    useHandleRoomDisconnection(
        room,
        onError,
        removeLocalAudioTrack,
        removeLocalVideoTrack
        /*  isSharingScreen,
    toggleScreenShare */
    )
    useHandleTrackPublicationFailed(room, onError)
    useRestartAudioTrackOnDeviceChange(localTracks)

    /*   const videoTrack = localTracks.find(track => !track.name.includes('screen') && track.kind === 'video') as
    | LocalVideoTrack
    | undefined; */

    return (
        <VideoContext.Provider
            value={{
                room,
                localTracks,
                isConnecting,
                onError: onErrorCallback,
                getLocalVideoTrack,
                connect,
                isAcquiringLocalTracks,
                removeLocalVideoTrack,
                getAudioAndVideoTracks,
            }}
        >
            <SelectedParticipantProvider room={room}>
                {children}
            </SelectedParticipantProvider>
        </VideoContext.Provider>
    )
}
