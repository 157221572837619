import React from 'react'
import { Contact } from './PhoneScreen'

interface Props {
    contacts: Contact[]
    selectedId: string
    handleSelectContact: (id: string) => void
}

const ContactsFeed = React.forwardRef<HTMLDivElement, Props>(
    ({ contacts, selectedId, handleSelectContact }, ref) => {
        return (
            <div className="overflow-auto flex flex-col " ref={ref}>
                {contacts.map((contact: Contact, index: number) => (
                    <div
                        key={index}
                        className={`flex flex-col justify-left h-32 border-b p-2 relative scroll-child cursor-pointer break-words ${
                            selectedId === contact.id
                                ? 'bg-zinc-600/90'
                                : 'bg-zinc-600/50 hover:bg-zinc-600/70'
                        }`}
                        onClick={() => handleSelectContact(contact.id)}
                    >
                        <div className="text-blue-400 text-2xl mt-4 truncate h-10">
                            {contact.name}
                        </div>
                        <div className="text-blue-950 text-xl truncate h-10">
                            {contact.number}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
)
ContactsFeed.displayName = 'ContactsFeed'

export default ContactsFeed
