import React, { useState, useEffect, useRef } from 'react'
import { Message, Conversation, Client } from '@twilio/conversations'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'

import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

interface Props {
    client: Client | null
    selectedConvo: Conversation | null
    convoMessages: Message[]
    findContact: (identity: string) => string
}

const ChatComponent: React.FC<Props> = ({
    client,
    selectedConvo,
    convoMessages,
    findContact,
}) => {
    const [loadingSendMessage, setLoadingSendMessage] = useState<boolean>(false)
    const [sendError, setSendError] = useState<string>('')
    const [newMessage, setNewMessage] = useState<string>('')
    const messagesContainerRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    const sendMessage = async () => {
        setLoadingSendMessage(true)
        try {
            if (selectedConvo) {
                await selectedConvo.sendMessage(newMessage)
                setNewMessage('')
            }
        } catch (error) {
            setSendError('Error sending message')

            setTimeout(() => {
                setSendError('')
            }, 3000)
        }
        setLoadingSendMessage(false)
    }

    const scrollMessagesToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTo({
                top: messagesContainerRef.current.scrollHeight,
            })
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            if (!loadingSendMessage && newMessage.trim() !== '') {
                sendMessage()
            }
        }
    }

    useEffect(() => {
        scrollMessagesToBottom()
    }, [convoMessages])

    function makeLinksClickable(text: string): (JSX.Element | string)[] {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        const parts = text.split(urlRegex)

        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        className="text-blue-800"
                        key={index}
                        href={part}
                        rel="noopener noreferrer"
                    >
                        {part}
                    </a>
                )
            } else {
                return <span key={index}>{part}</span>
            }
        })
    }
    return (
        <>
            <div className="flex flex-col  overflow-hidden h-full  ">
                <div
                    className="flex flex-col overflow-auto flex-grow py-2  "
                    ref={messagesContainerRef}
                >
                    {convoMessages?.length !== 0 ? (
                        convoMessages.map((message, index) => {
                            let isAuthor = false

                            if (client) {
                                if (
                                    client.user &&
                                    client.user.identity === message.author
                                ) {
                                    isAuthor = true
                                }
                            }

                            return (
                                <div
                                    key={message.sid}
                                    className={`${
                                        isAuthor
                                            ? 'bg-blue-500 text-white ml-auto '
                                            : 'bg-gray-200 '
                                    } p-2 m-1 rounded-lg max-w-[40%]  break-all ${
                                        index === 0 && ' mt-auto'
                                    } scroll-child  `} // if fron ne different side , use different color
                                >
                                    <p className="text-2xl">
                                        {makeLinksClickable(message.body || '')}
                                    </p>
                                    <span
                                        className={`text-s  ${
                                            isAuthor
                                                ? 'text-gray-300'
                                                : 'text-gray-500'
                                        }`}
                                    >
                                        {findContact(message.author || '')} -{' '}
                                        {message.dateCreated?.toLocaleString()}
                                    </span>
                                </div>
                            )
                        })
                    ) : (
                        <div className="flex justify-center items-center h-full text-3xl  text-white mt-4">
                            {t('sms_page.no messages yet')}
                        </div>
                    )}
                </div>
                <div className=" m-2  flex items-end">
                    <ScrollFeedButtons
                        scrollContainerRef={messagesContainerRef}
                    />
                    <div className="flex gap-2 ml-4 w-full ">
                        {/* Input for new message */}
                        <input
                            type="text"
                            className="flex-grow border border-gray-300 rounded-md px-4 py-2 text-xl h-14"
                            placeholder={t('sms_page.type message') || ''}
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                        {/* Button to send the message */}
                        <button
                            className={`flex items-center justify-center text-center ${
                                sendError
                                    ? 'bg-red-500 text-lg'
                                    : 'bg-blue-500 text-xl'
                            } text-white rounded-md px-4 py-2 w-40 `}
                            onClick={() =>
                                sendError ? undefined : sendMessage()
                            }
                        >
                            {loadingSendMessage ? (
                                <LoadingWheel className="ml-1  w-8 h-8" />
                            ) : (
                                <>
                                    {sendError ? (
                                        <span className="truncate">
                                            {sendError}
                                        </span>
                                    ) : (
                                        <>
                                            {t('sms_page.send')}
                                            <PaperAirplaneIcon className="ml-1  w-8 h-8" />
                                        </>
                                    )}
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatComponent
