import React, { useEffect } from 'react'
import { Feeling } from './BarometerScreen'
import { useTranslation } from 'react-i18next'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import ConfirmationModal from '../../../common/ConfirmationModal'
import api from '../../../../api'
import { TrashIcon } from '@heroicons/react/24/outline'

interface BarometerHistoryProps {
    data: Feeling[]
    setData: (data: Feeling[]) => void
}

export const BarometerHistory: React.FC<BarometerHistoryProps> = ({
    data,
    setData,
}) => {
    const { t } = useTranslation()
    const contactsFeedContainerRef = React.useRef<HTMLDivElement>(null)
    const [selectedInput, setSelectedInput] = React.useState<Feeling>()
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
    const [errorDelete, setErrorDelete] = React.useState<string>('')
    useEffect(() => {
        setErrorDelete('')
    }, [deleteModalOpen])

    const handleDelete = async () => {
        try {
            const response = await api.delete(
                '/feeling/delete/' + selectedInput?.id
            )
            if (response.data && response.data.data) {
                setData(response.data.data.data)
                setSelectedInput(undefined)
                setDeleteModalOpen(false)
            }
            const s = t('barometer_page.delete error')
            setErrorDelete(s)
        } catch (error) {
            console.error('Error deleting vitals:', error)
            const s = t('barometer_page.delete error')
            setErrorDelete(s)
        }
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex justify-between w-full bg-white rounded-t-lg border-b  font-medium py-4 px-3 uppercase items-center">
                <p className="text-black text-xl ">
                    {t('barometer_page.history')}
                </p>
                <ScrollFeedButtons
                    scrollContainerRef={contactsFeedContainerRef}
                />
            </div>

            <div
                className="flex flex-col bg-white h-full  rounded-b-lg  overflow-auto"
                ref={contactsFeedContainerRef}
            >
                {data.length === 0 ? (
                    <p className="text-gray-700  my-4 h-full flex items-center justify-center text-2xl">
                        {t('barometer_page.no data available')}
                    </p>
                ) : (
                    <div role="list" className="divide-y divide-gray-100">
                        {data.map((d: Feeling, index) => {
                            const date = new Date(d.date)

                            const month = date.toLocaleString('en-US', {
                                month: 'short',
                            })
                            const day = date.getDate()
                            const hour = date.getHours()
                            const minutes = date.getMinutes()

                            const formattedMinutes =
                                minutes < 10 ? `0${minutes}` : minutes

                            const formattedDate = `${month} ${day}, ${hour}:${formattedMinutes}`
                            return (
                                <div
                                    key={index}
                                    className="scroll-child flex justify-between gap-x-3 p-4 "
                                >
                                    <div className="flex flex-col gap-4 w-2/3 justify-center">
                                        <div className="flex min-w-0 gap-x-4">
                                            <div className="min-w-0 flex-auto">
                                                <p className="text-lg font-semibold leading-6 text-gray-900">
                                                    {t('barometer_page.pain')}:{' '}
                                                    {d.pain}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col shrink-0">
                                            <p className="text-lg font-semibold leading-6 text-gray-900">
                                                {t('barometer_page.feel')}:{' '}
                                                {d.feeling}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center w-1/3">
                                        <p className="mt-1 text-md  text-gray-700 text-center justify-center my-auto ">
                                            {formattedDate}
                                        </p>
                                        <button
                                            className="h-10 w-10 text-red-500 hover:text-red-600"
                                            onClick={() => {
                                                setSelectedInput(d)
                                                setDeleteModalOpen(true)
                                            }}
                                        >
                                            <TrashIcon />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            {deleteModalOpen && (
                <ConfirmationModal
                    message={t('barometer_page.are you sure delete')}
                    succesButtonMessage={t('phone_page.delete')}
                    onSuccess={handleDelete}
                    onCancel={() => {
                        setDeleteModalOpen(false)
                        setErrorDelete('')
                    }}
                    errorMessage={errorDelete}
                />
            )}
        </div>
    )
}
