import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { Link } from 'react-router-dom'

const SettingsButton: React.FC = () => {
    return (
        <div className="flex justify-end">
            <Link to="/settings" className="text-white hover:text-gray-300">
                <Cog6ToothIcon className="h-16 w-16 mr-1" aria-hidden="true" />
            </Link>
        </div>
    )
}

export default SettingsButton
