import React from 'react'
import ScrollButtons from './ScrollButtons'

interface ScrollFeedButtonsProps {
    scrollContainerRef: React.RefObject<HTMLDivElement>
    extraScroll?: number
}

const ScrollFeedButtons: React.FC<ScrollFeedButtonsProps> = ({
    scrollContainerRef,
    extraScroll = 0,
}) => {
    const scrollToNotification = (direction: number) => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current
            const element = container.querySelector(
                `.scroll-child`
            ) as HTMLElement

            if (element) {
                const childHeight = element.offsetHeight
                const childMarginBottom = parseInt(
                    getComputedStyle(element).marginBottom,
                    10
                )
                const scrollOffset =
                    childHeight + childMarginBottom + extraScroll
                container.scrollBy({
                    top: scrollOffset * direction,
                    behavior: 'smooth',
                })
            }
        }
    }

    const scrollToNextNotification = () => {
        scrollToNotification(1)
    }

    const scrollToPreviousNotification = () => {
        scrollToNotification(-1)
    }

    return (
        <ScrollButtons
            scrollToPrevious={scrollToPreviousNotification}
            scrollToNext={scrollToNextNotification}
        />
    )
}

export default ScrollFeedButtons
