import React from 'react'
import { useTranslation } from 'react-i18next'

interface ConfirmationModalProps {
    message: string
    succesButtonMessage: string
    errorMessage?: string
    onSuccess: () => void
    onCancel: () => void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    message,
    succesButtonMessage,
    errorMessage,
    onSuccess,
    onCancel,
}) => {
    const handleSuccess = () => {
        onSuccess()
        // Close the modal or perform any other necessary actions
    }

    const handleCancel = () => {
        onCancel()
        // Close the modal or perform any other necessary actions
    }

    const { t } = useTranslation()

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/40">
            <div className="bg-white rounded-lg p-6 shadow-md">
                <p className="mb-4 text-xl">{message}</p>
                {errorMessage && (
                    <p className="mb-4 text-red-500">{errorMessage}</p>
                )}
                <div className="flex justify-between">
                    <button
                        className="px-4 py-2 bg-red-500 text-white rounded"
                        onClick={handleCancel}
                    >
                        {t('calendar_page.cancel')}
                    </button>
                    <button
                        className="px-4 py-2  bg-green-500 text-white rounded"
                        onClick={handleSuccess}
                    >
                        {succesButtonMessage}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal
