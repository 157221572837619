import React from 'react'

import useVideoContext from '../../hooks/Video/useVideoContext'
import useParticipantsContext from '../../hooks/Participants/useParticipantContext'
import { usePagination } from '../../hooks/usePagination'
import Pagination from '../Room/Pagination'

import { Cog6ToothIcon, UserPlusIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { Participant } from 'twilio-video'

export default function TopBar({
    setDeviceSettingsOpen,
    setInviteModalOpen,
}: {
    setDeviceSettingsOpen: (open: boolean) => void
    setInviteModalOpen: (open: boolean) => void
}) {
    const { t } = useTranslation()
    const { room } = useVideoContext()

    const { galleryViewParticipants } = useParticipantsContext()
    const { setCurrentPage, currentPage, totalPages } = usePagination([
        room?.localParticipant as Participant,
        ...galleryViewParticipants,
    ])

    return (
        <>
            <div className="h-14 py-4 px-4  md:px-8  lg:px-12  xl:px-16  bg-white">
                <div className="mx-auto h-full flex justify-center items-center">
                    <div className=" flex-1">
                        <p className="whitespace-nowrap text-xl">
                            {t('video_page.room name')} {room?.name}
                        </p>
                    </div>
                    <div className={'flex items-center '}>
                        {totalPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(newPage: number) =>
                                    setCurrentPage(newPage)
                                }
                            /> 
                        )}
                    </div>
                    <div className="flex-1 flex justify-end">
                        <button
                            className={`flex items-center border-solid border-2 border-black px-3 py-2 rounded-lg mr-5
                          
                               hover:bg-zinc-500/20 cursor-pointer
                     `}
                            onClick={() => setInviteModalOpen(true)}
                        >
                            <UserPlusIcon className="w-6 h-6" />
                            <span className="w-20">
                                {t('video_page.invite')}
                            </span>
                        </button>
                        <button
                            className={`flex items-center border-solid border-2 border-black px-3 py-2 rounded-lg
                               hover:bg-zinc-500/20 cursor-pointer  `}
                            onClick={() => setDeviceSettingsOpen(true)}
                        >
                            <Cog6ToothIcon className="w-6 h-6" />
                            <span className="w-20">
                                {t('video_page.devices')}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
