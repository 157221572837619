import React from 'react'
import Footer from './Footer'
import Sidebar from './Sidebar'
import bgImage from './background-image.svg'

interface Props {
    selected_tab: string
    content: React.ReactNode
    bg_gradient: string
}

const MainLayout: React.FC<Props> = ({
    selected_tab,
    content,
    bg_gradient,
}) => {
    return (
        <div className={bg_gradient + ' h-screen'}>
            <img
                src={bgImage}
                alt=""
                className="object-contain absolute right-0 top-0 h-screen pointer-events-none"
            />
            <div className="flex h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
                <div className="basis-4/5 z-content overflow-hidden">
                    {content}
                </div>
                <div className="basis-1/5 z-sidebar overflow-hidden">
                    <Sidebar />
                </div>
            </div>
            <Footer selectedTab={selected_tab} />
        </div>
    )
}

export default MainLayout
