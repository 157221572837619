import { useContext } from 'react'
import { Settings } from '../context/settings/Types'
import {
    SettingsContext,
    useSettingsDispatch,
} from '../context/settings/SettingsContext'
import { getDefaultSettings } from '../context/settings/SettingsProvider'
import api from '../api'

type UpdateSettingsFn = (updatedSettings: Partial<Settings>) => Promise<number>
type ResetSettingsFn = () => void

export const useSettings = (): [
    Settings | undefined,
    UpdateSettingsFn,
    ResetSettingsFn
] => {
    const settings = useContext(SettingsContext)
    const dispatch = useSettingsDispatch()

    const updateSettings: UpdateSettingsFn = async (updatedSettings) => {
        dispatch({ type: 'UPDATE_SETTINGS', payload: updatedSettings })

        const status = await saveSettingsToServer(updatedSettings)
        return status
    }

    const resetSettings: ResetSettingsFn = async () => {
        dispatch({ type: 'RESET_SETTINGS' })

        const response = await saveSettingsToServer(getDefaultSettings())
        return response
    }

    const saveSettingsToServer = async (settingsToSave: Partial<Settings>) => {
        const res = await api.put('/settings/update', settingsToSave)

        return res.status
    }

    return [settings, updateSettings, resetSettings]
}
