export const sloveniaNewsProviders: Record<string, string> = {
    'patriotic-vanguard': 'Patriotic Vanguard',
    delo: 'Delo',
    'primorske-novice': 'Primorske Novice',
    'slovenske-novice': 'Slovenske Novice',
    'najdi-si-novice': 'Najdi Si Novice',
    vecer: 'Vecer',
}

export const unitedKingdomNewsProviders: Record<string, string> = {
    guardian: 'The Guardian',
    bbc: 'BBC',
    independent: 'Independent',
    'london-evening-standard': 'London Evening Standard',
    'manchester-evening-news': 'Manchester Evening News',
    mail: 'Mail',
    metro: 'Metro',
    talksport: 'Talksport',
    bmmagazine: 'BM Magazine',
}

export const unitedStatesNewsProviders: Record<string, string> = {
    cnn: 'CNN',
    forextv: 'ForexTV',
    mercurynews: 'Mercury News',
    sports: 'Sports',
    sportsillustrated: 'Sports Illustrated',
    essentiallysports: 'Essentially Sports',
    bloomberg: 'Bloomberg',
    nytimes: 'The New York Times',
    'cbssports-com': 'CBS Sports',
    sportskeeda: 'Sportskeeda',
    fansided: 'Fansided',
    sportingnews: 'Sporting News',
    espn: 'ESPN',
    tmz: 'TMZ',
    investing: 'Investing',
    photos: 'Photos',
    nationalpost: 'National Post',
    nesn: 'NESN',
    foxnews: 'Fox News',
    skynews: 'Sky News',
    yahoo: 'Yahoo',
}

export const germanyNewsProviders: Record<string, string> = {
    dw: 'DW',
    'rp-online': 'RP Online',
    focus: 'Focus',
    ard: 'ARD',
    'sportschau-ard': 'Sportschau ARD',
    kreiszeitung: 'Kreiszeitung',
    hna: 'HNA',
    'op-online': 'OP Online',
    amerika21: 'Amerika21',
    haz: 'HAZ',
    'die-welt': 'Die Welt',
    'merkur-online': 'Merkur Online',
    handelsblatt: 'Handelsblatt',
    computerbild: 'Computerbild',
}

export const austriaNewsProviders: Record<string, string> = {
    kurier: 'Kurier',
    oe24: 'OE24',
    ots: 'OTS',
    'kurier-at': 'Kurier AT',
    orf: 'ORF',
    salzburg24: 'Salzburg24',
    volksblatt: 'Volksblatt',
    diepresse: 'Die Presse',
    thelocal: 'The Local',
}
