import React from 'react'
import { useAppState } from '../../hooks/VideoAppState'
import useDevices from '../../hooks/useDevices'
import { useTranslation } from 'react-i18next'

export default function AudioOutputList() {
    const { audioOutputDevices } = useDevices()
    const { activeSinkId, setActiveSinkId } = useAppState()

    const activeOutputLabel = audioOutputDevices.find(
        (device) => device.deviceId === activeSinkId
    )?.label

    const { t } = useTranslation()

    return (
        <div className="mx-auto">
            <h3 className="text-lg font-medium">
                {t('video_page.audio output')}
            </h3>

            {audioOutputDevices.length > 1 ? (
                <select
                    onChange={(e) => setActiveSinkId(e.target.value)}
                    value={activeSinkId}
                    className="border border-gray-300 rounded-md p-2 w-full"
                >
                    {audioOutputDevices.map((device) => (
                        <option value={device.deviceId} key={device.deviceId}>
                            {device.label}
                        </option>
                    ))}
                </select>
            ) : (
                <>
                    <p>
                        {activeOutputLabel ||
                            t('video_page.system default audio output')}
                    </p>
                </>
            )}
        </div>
    )
}
