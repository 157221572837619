import React from 'react'
import { PlayCircleIcon, PauseCircleIcon } from '@heroicons/react/24/solid'
import { useSpotify } from '../../../../../context/spotify/SpotifyPlayerProvider'

type CardPlayButtonProps = {
    uri: string
    className?: string
}

const CardPlayButton: React.FC<CardPlayButtonProps> = ({ uri, className }) => {
    const { uris, setUris, play, setPlay, state } = useSpotify()
    const handlePlay = () => {
        setUris([uri])
        setPlay(true)
    }

    return (
        <>
            {play &&
            (state?.track.uri === uri ||
                (uris.length === 1 && uris[0] === uri)) ? (
                <button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault()
                        setPlay(false)
                    }}
                    className={className}
                >
                    <PauseCircleIcon className="text-green-600" />
                </button>
            ) : (
                <button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault()
                        handlePlay()
                    }}
                    className={className}
                >
                    <PlayCircleIcon className="text-green-600" />
                </button>
            )}
        </>
    )
}

export default CardPlayButton
