import api from '../../api'

export const getNews = async () => {
    try {
        const response = await api.get('/news/get')
        if (response.data) {
            if (response.data.data) {
                return response.data.data.data ? response.data.data.data : []
            }
        }
    } catch (error) {
        console.error('Error fetching news:', error)
    }
}
