import React, { useEffect, useState } from 'react'
import { Steps } from '../../JoinRoomScreen'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import useVideoContext from '../../hooks/Video/useVideoContext'
import LoadingWheel from '../../../../../common/LoadingWheel'
import LocalVideoPreview from './LocalVideoPreview'
import ToggleAudioButton from '../MenuBar/ToggleAudioButton'
import ToggleVideoButton from '../MenuBar/ToggleVideoButton'
import { useTranslation } from 'react-i18next'
import useLocalTracks from '../../hooks/Tracks/useLocalTracks'
import { useSetVolumeTarget } from '../../../../../../context/volume/VolumeContext'

interface DeviceSelectionScreenProps {
    name: string
    roomName: string
    setStep: (step: Steps) => void
    getToken: (roomName: string) => Promise<string>
    setDeviceSettingsOpen: (open: boolean) => void
}

const DeviceSelectionScreen: React.FC<DeviceSelectionScreenProps> = ({
    name,
    roomName,
    setStep,
    getToken,
    setDeviceSettingsOpen,
}) => {
    const [isFetching, setIsFetching] = useState(false)
    const {
        connect: videoConnect,
        isAcquiringLocalTracks,
        isConnecting,
    } = useVideoContext()

    const { removeLocalAudioTrack, removeLocalVideoTrack } = useLocalTracks()

    const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting

    const { t } = useTranslation()

    const setVolumeTarget = useSetVolumeTarget()

    useEffect(() => {setVolumeTarget('videocall')}, [])

    const handleJoin = async () => {
        setIsFetching(true)
        const token = await getToken(roomName)
        setIsFetching(false)
        if (token) {
            videoConnect(token)
        }
    }
    if (isFetching || isConnecting) {
        return (
            <div className="flex flex-col items-center justify-center h-full mt-[-3.8rem]">
                <LoadingWheel className="w-28 h-28 mb-2" />
                <button className="text-3xl text-white font-bold" disabled>
                    {t('video_page.joining')}
                </button>
            </div>
        )
    }

    return (
        <div className="flex  items-center justify-center h-full mt-[-3.8rem]">
            <div className="flex flex-col bg-white rounded-lg p-3">
                <div className="text-2xl  "> Join {roomName}</div>
                <hr className="border-gray-500 border-1" />
                <div className="flex mt-3">
                    <LocalVideoPreview identity={name} />
                    <div className="flex flex-col ml-8 items-center justify-center">
                        <ToggleAudioButton
                            className={`w-48 border-solid border-2 border-black p-3 mb-5 rounded-lg ${
                                disableButtons
                                    ? 'bg-zinc-500/40 cursor-not-allowed'
                                    : ' hover:bg-zinc-500/20 cursor-pointer'
                            }`}
                            disabled={disableButtons}
                        />
                        <ToggleVideoButton
                            className={`w-48 border-solid border-2 border-black p-3 mb-5 rounded-lg ${
                                disableButtons
                                    ? 'bg-zinc-500/40 cursor-not-allowed'
                                    : ' hover:bg-zinc-500/20 cursor-pointer'
                            }`}
                            disabled={disableButtons}
                        />
                        <button
                            className={`flex items-center w-48 border-solid border-2 border-black p-3 rounded-lg 
                          
                               hover:bg-zinc-500/20 cursor-pointer
                     `}
                            onClick={() => setDeviceSettingsOpen(true)}
                        >
                            <Cog6ToothIcon className="w-8 h-8" />
                            <span className="w-20">
                                {t('video_page.devices')}
                            </span>
                        </button>
                    </div>
                </div>

                <div className="flex justify-between mt-8">
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold px-5 text-xl rounded "
                        onClick={() => {
                            removeLocalAudioTrack()
                            removeLocalVideoTrack()
                            setStep(Steps.roomNameStep)
                        }}
                    >
                        {t('video_page.cancel')}{' '}
                    </button>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-5 text-xl rounded"
                        onClick={handleJoin}
                    >
                        {t('video_page.join now')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeviceSelectionScreen
