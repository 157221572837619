import React, { createContext, useContext, useEffect, useState } from 'react'
import api from '../../api'
import { useSetVolumeTarget } from '../volume/VolumeContext'
import { useVolumeTarget } from '../volume/VolumeContext'
import { SpotifyPlayer, CallbackState } from 'react-spotify-web-playback'
import SpotifyWebApi from 'spotify-web-api-node'
import { useVolume } from '../volume/VolumeContext'
import axios, { AxiosError } from 'axios'

type SpotifyPlayerContextType = {
    token: string
    uris: string[]
    setUris: (uris: string[]) => void
    player: SpotifyPlayer | null
    setPlayer: (player: SpotifyPlayer) => void
    play: boolean
    setPlay: (play: boolean) => void
    state: CallbackState | null
    setState: (state: CallbackState) => void
    spotifyApi?: SpotifyWebApi
    createModal: boolean
    setCreateModal: (value: boolean) => void
    updatePlaylist: SpotifyApi.SinglePlaylistResponse | null
    setUpdatePlaylist: (
        playlist: SpotifyApi.SinglePlaylistResponse | null
    ) => void
    trackAddModal: boolean
    setTrackAddModal: (value: boolean) => void
    trackId: string
    setTrackId: (id: string) => void
    errorHandling: (error: AxiosError, checkForPremium: boolean) => void
    userPremium: boolean
}

const SpotifyPlayerContext = createContext<SpotifyPlayerContextType>({
    token: '',
    uris: [],
    setUris: () => null,
    player: null,
    setPlayer: () => null,
    play: false,
    setPlay: () => null,
    state: null,
    setState: () => null,
    spotifyApi: undefined,
    createModal: false,
    setCreateModal: () => null,
    updatePlaylist: null,
    setUpdatePlaylist: () => null,
    trackAddModal: false,
    setTrackAddModal: () => null,
    trackId: '',
    setTrackId: () => null,
    errorHandling: () => null,
    userPremium: false,
})

export const useSpotify = () => useContext(SpotifyPlayerContext)

interface SpotifyPlayerProviderProps {
    children: React.ReactNode
}

export const SpotifyPlayerProvider: React.FC<SpotifyPlayerProviderProps> = ({
    children,
}) => {
    const [token, setToken] = useState<string>('')
    const [uris, setUris] = useState<string[]>([])
    const [player, setPlayer] = useState<SpotifyPlayer | null>(null)
    const [play, setPlay] = useState<boolean>(false)
    const [state, setState] = useState<CallbackState | null>(null)
    const [spotifyApi] = useState<SpotifyWebApi>(new SpotifyWebApi())
    const [expiresIn, setExpiresIn] = useState<number>()
    const [createModal, setCreateModal] = useState<boolean>(false)
    const [trackAddModal, setTrackAddModal] = useState<boolean>(false)
    const [trackId, setTrackId] = useState<string>('')
    const [updatePlaylist, setUpdatePlaylist] =
        useState<SpotifyApi.SinglePlaylistResponse | null>(null)

    const [userPremium, setUserPremium] = useState<boolean>(false)

    const setVolumeTarget = useSetVolumeTarget()
    const volumeTarget = useVolumeTarget()

    const vol = useVolume()

    const errorHandling = (error: AxiosError, checkPremium: boolean) => {
        if (error.code === '401') {
            refreshToken()
        }
        if (error.code === '403' && checkPremium) {
            checkForPremium()
        }
    }

    const getToken = async () => {
        try {
            const response = await api.get('/spotify/token')
            if (response.data.data.access_token) {
                setToken(response.data.data.access_token)
                setExpiresIn(response.data.data.expires_in)
            }
        } catch (e) {
            console.error(e)
            setToken('')
        }
    }

    const checkForPremium = async () => {
        try {
            const response = await spotifyApi.getMe()
            if (response.body.product === 'premium') {
                setUserPremium(true)
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error(e)
            if ('message' in e) {
                if (e.message.includes('The access token expired')) {
                    refreshToken()
                }
            }
            if (axios.isAxiosError(e)) {
                errorHandling(e, false)
            }
        }
    }

    useEffect(() => {
        getToken()
    }, [])
    const refreshToken = async () => {
        try {
            const response = await api.get('/spotify/refresh')
            if (response.data.data.access_token) {
                setToken(response.data.data.access_token)
                setExpiresIn(response.data.data.expires_in)
            }
        } catch (e) {
            console.error(e)
            setToken('')
            setExpiresIn(undefined)
        }
    }

    useEffect(() => {
        if (!expiresIn) return
        const interval = setInterval(() => {
            refreshToken()
        }, (expiresIn - 60) * 1000)

        return () => clearInterval(interval)
    }, [expiresIn])

    useEffect(() => {
        if (player) {
            player.setVolume(vol / 100)
        }
    }, [vol, player])

    useEffect(() => {
        if (play) {
            setVolumeTarget('spotify')
        }
    }, [play])

    useEffect(() => {
        if (token) {
            spotifyApi.setAccessToken(token)
            checkForPremium()
        }
    }, [token])

    useEffect(() => {
        if (volumeTarget !== 'spotify') {
            setPlay(false)
        }
    }, [volumeTarget])

    return (
        <SpotifyPlayerContext.Provider
            value={{
                token,
                uris,
                setUris,
                player,
                setPlayer,
                play,
                setPlay,
                state,
                setState,
                spotifyApi,
                createModal,
                setCreateModal,
                updatePlaylist,
                setUpdatePlaylist,
                trackAddModal,
                setTrackAddModal,
                trackId,
                setTrackId,
                errorHandling,
                userPremium,
            }}
        >
            {children}
        </SpotifyPlayerContext.Provider>
    )
}
