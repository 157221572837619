import React from 'react'
import newsPlaceholder from './news-placeholder.jpg'
import { Link } from 'react-router-dom'

interface Props {
    index: number
    title: string | undefined
    description: string | undefined
    image: string | undefined
    author: string | undefined
    url: string | undefined
    category: string | undefined
    source: string | undefined
    className?: string
}

const NewsItem: React.FC<Props> = ({ index, title, image, className }) => {
    return (
        <div className={className}>
            <Link to={`/fun/media/${index}`}>
                <div className="relative bg-white text-primary box-border cursor-pointer overflow-hidden h-76 lg:h-86 xl:h-96 p-1">
                    <div
                        className="w-full bg-cover bg-no-repeat bg-center mb-2 h-60"
                        style={{
                            backgroundImage: `url(${
                                image ? image : newsPlaceholder
                            })`,
                        }}
                    />
                    <p className="text-xl lg:text-2xl xl:text-3xl p-1">
                        {title}
                    </p>
                    <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent" />
                </div>
            </Link>
        </div>
    )
}

export default NewsItem
