import React, { useEffect, useState, useRef } from 'react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import api from '../../../../api'
import LoadingWheel from '../../../common/LoadingWheel'
import CustomCalendar from './CustomCalendar'
import { useTranslation } from 'react-i18next'
import { isSameDay, parseISO } from 'date-fns'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import EventModal from './EventDetailsModal'
import { Event, eventColorMap } from './EventUtils'
import AddEventModal from './AddEventModal'

export const formatShortDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
        dayPeriod: 'short',
    }

    return (
        date.toLocaleString(undefined, options) +
        ', ' +
        date.toLocaleString(undefined, timeOptions)
    )
}
export const getColorById = (id: string | undefined): string | undefined => {
    return id
        ? eventColorMap.get(id) || eventColorMap.get('1')
        : eventColorMap.get('1')
}

interface EventScreenProps {
    calendarId: string
    canEdit: boolean
    title: string
}

const AlbumsScreen: React.FC<EventScreenProps> = ({
    calendarId,
    canEdit,
    title,
}) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [events, setEvents] = useState<Event[]>([])
    const [selectedDate, setSelcetedDate] = useState(new Date())
    const [selectedEvents, setSelectedEvents] = useState<Event[]>([])
    const scrollEventsContainerRef = useRef<HTMLDivElement>(null)
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null)
    const [editEvent, setEditEvent] = useState<Event | null>(null)
    const [errorLoading, setErrorLoading] = useState<string>('')
    const { t } = useTranslation()

    const addEvent = (event: Event) => {
        setEvents(
            [...events, event].sort((a: Event, b: Event) => {
                const aDate = new Date(a.start.dateTime)
                const bDate = new Date(b.start.dateTime)
                if (aDate > bDate) {
                    return 1
                }
                return -1
            })
        )
    }

    const replaceEvent = (event: Event) => {
        setEvents((prevEvents) => {
            const updatedEvents = prevEvents.map((prevEvent) =>
                prevEvent.id === event.id ? event : prevEvent
            )
            return updatedEvents.sort((a: Event, b: Event) => {
                const aDate = new Date(a.start.dateTime)
                const bDate = new Date(b.start.dateTime)
                if (aDate > bDate) {
                    return 1
                }
                return -1
            })
        })
    }

    const removeEvent = (event: Event) => {
        setEvents((prevEvents) =>
            prevEvents.filter((prevEvent) => prevEvent.id !== event.id)
        )
    }

    const fetchEvents = async (): Promise<void> => {
        setLoading(true)
        try {
            const response = await api.get('/calendar/get/' + calendarId)
            if (response.data && response.data.data) {
                if (response.data.data.items) {
                    const filteredEvents = response.data.data.items.filter(
                        (event: Event) => {
                            return event.start && event.end
                        }
                    )
                    setEvents(
                        filteredEvents.sort((a: Event, b: Event) => {
                            if (a.start && a.end) {
                                const aDate = new Date(a.start.dateTime)
                                const bDate = new Date(b.start.dateTime)
                                if (aDate > bDate) {
                                    return 1
                                }
                            }

                            return -1
                        })
                    )
                    setErrorLoading('')
                }
            } else {
                setErrorLoading(
                    t('calendar_page.events cannot be loaded') || ''
                )
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching Events:', error)
            setErrorLoading(t('calendar_page.events cannot be loaded') || '')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchEvents()
    }, [])

    const formatFullDate = (date: Date): string => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }

        /*const timeOptions: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h23',
            dayPeriod: 'short',
        }*/

        const dayOfWeek = date.getDay()
        const dayName = [
            t('days.sunday'),
            t('days.monday'),
            t('days.tuesday'),
            t('days.wednesday'),
            t('days.thursday'),
            t('days.friday'),
            t('days.saturday'),
        ]
        return (
            dayName[dayOfWeek] + ', ' + date.toLocaleString(undefined, options)
        )
    }

    const filterEvents = () => {
        const filteredEvents = events.filter((event) => {
            const startDate = parseISO(event.start.dateTime)
            const endDate = parseISO(event.end.dateTime)
            return (
                isSameDay(startDate, selectedDate) ||
                (startDate < selectedDate && endDate >= selectedDate)
            )
        })
        setSelectedEvents(filteredEvents)
    }

    useEffect(() => {
        filterEvents()
    }, [selectedDate, events])

    const handleDayClick = (value: Date) => {
        setSelcetedDate(value)
    }

    const handleEventClick = (event: Event) => {
        setSelectedEvent(event)
        setShowDetailsModal(true)
    }

    const handleAddClick = () => {
        setShowAddModal(true)
        setShowDetailsModal(false)
    }

    const editWindow = () => {
        setEditEvent(selectedEvent)
        setShowAddModal(true)
    }

    return (
        <div className="relative flex flex-col gap-2 pb-4 px-4 pt-6 md:px-8 md:pt-6 lg:px-12 lg:pt-8 xl:px-16 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between">
                <div className="flex  text-5xl font-medium text-gray-300 ">
                    <span className="truncate">{title}</span>
                    {loading ? (
                        <LoadingWheel className="w-8 h-8 ml-5" />
                    ) : (
                        errorLoading && (
                            <span className="flex items-center rounded text-red-500 h-full p-2 bg-white text-sm md:text-base ml-3 mt-auto mr-2  ">
                                {errorLoading}
                            </span>
                        )
                    )}
                </div>

                {canEdit && (
                    <div className="inline-block border w-72 md:w-64 rounded-sm">
                        <div
                            className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center "
                            onClick={handleAddClick}
                        >
                            <span>{t('calendar_page.new event')}</span>
                            <span className="w-6">
                                <PlusCircleIcon className="w-10 h-7" />
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex overflow-hidden gap-4 h-full">
                <div className={'flex w-2/3 h-full flex-shrink-0 grow-0'}>
                    <CustomCalendar
                        events={events}
                        handleDayClick={handleDayClick}
                        getColortById={getColorById}
                    />
                </div>
                <div className="flex flex-col w-1/3 border rounded bg-gray-600/50 overflow-hidden h-full">
                    <div className="flex text-gray-300 justify-center text-2xl pb-2">
                        {formatFullDate(selectedDate)}
                    </div>
                    <hr />
                    <div
                        className="overflow-auto flex-grow h-full mx-1"
                        ref={scrollEventsContainerRef}
                    >
                        {selectedEvents.length !== 0 ? (
                            selectedEvents.map((event: Event) => (
                                <div
                                    key={event.id}
                                    className={`flex h-32 justify-between p-2 my-1 cursor-pointer rounded text-xl overflow-hidden scroll-child ${getColorById(
                                        event.colorId
                                    )}`}
                                    onClick={() => handleEventClick(event)}
                                >
                                    <div className=" text-lg md:text-xl font-medium  break-all md:break-words mr-1 w-1/2 lg:w-2/3">
                                        {event.summary
                                            ? event.summary
                                            : 'Event no title'}
                                    </div>
                                    <div className="flex flex-col text-base md:text-lg">
                                        <span>
                                            {t('calendar_page.from')}:{' '}
                                            {formatShortDate(
                                                parseISO(event.start.dateTime)
                                            )}{' '}
                                        </span>
                                        <span>
                                            {t('calendar_page.to')}:{' '}
                                            {formatShortDate(
                                                parseISO(event.end.dateTime)
                                            )}{' '}
                                        </span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex text-xl justify-center text-white text-bold ">
                                {t('calendar_page.no events')}
                            </div>
                        )}
                    </div>
                    <hr></hr>
                    <div className="flex flex-col mr-2 items-center my-1">
                        <ScrollFeedButtons
                            scrollContainerRef={scrollEventsContainerRef}
                        />
                    </div>
                </div>
            </div>
            {showDetailsModal && selectedEvent && (
                <EventModal
                    calendarId={calendarId}
                    event={selectedEvent}
                    onClose={() => setShowDetailsModal(false)}
                    onEdit={() => editWindow()}
                    deleteEvent={removeEvent}
                    canEdit={canEdit}
                />
            )}
            {showAddModal && canEdit && (
                <AddEventModal
                    calendarId={calendarId}
                    onClose={() => {
                        setShowAddModal(false)
                        setEditEvent(null)
                    }}
                    addEvent={addEvent}
                    event={editEvent}
                    replaceEvent={replaceEvent}
                />
            )}
            <div className="inline-block">&nbsp;</div>
        </div>
    )
}

export default AlbumsScreen
