import React, { ChangeEventHandler, forwardRef, useState } from 'react'
import {
    ArrowUturnLeftIcon,
    TrashIcon,
    PaperAirplaneIcon,
} from '@heroicons/react/24/outline'
import { MailDetails } from './EmailScreen'
import api from '../../../../api'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

interface Props {
    mailId: string
    mailDetails: MailDetails
    closeReply: () => void
}

const ReplyEmail = forwardRef<HTMLDivElement, Props>(
    ({ mailId, mailDetails, closeReply }, ref) => {
        const [body, setBody] = useState('')
        const [bodyError, setBodyError] = useState(false)
        const [loading, setLoading] = useState(false)
        const [successMessage, setSuccessMessage] = useState('')
        const [errorMessage, setErrorMessage] = useState('')
        const { t } = useTranslation()

        const handleBodyChange: ChangeEventHandler<HTMLTextAreaElement> = (
            event
        ) => {
            const body = event.target.value
            setBody(body)
            setBodyError(body.trim() === '')
        }

        const handleClear = () => {
            setBody('')
            closeReply()
            setSuccessMessage('')
            setErrorMessage('')
        }

        const sendReply = async () => {
            try {
                setBodyError(body.trim() === '')
                if (body.trim() === '') {
                    return
                }

                setLoading(true)
                setSuccessMessage('')
                setErrorMessage('')

                const emailData = {
                    Body:
                        body +
                        '\n' +
                        mailDetails.senderEmail +
                        ' wrote: \n' +
                        (mailDetails.bodyPlain
                            ? mailDetails.bodyPlain
                            : mailDetails.bodyHTML),
                }

                const response = await api.post(
                    '/mail/reply/' + mailId,
                    emailData
                )

                if (
                    response.status === 200 &&
                    response.data.status === 'success'
                ) {
                    setSuccessMessage(
                        t('email_page.reply sent successfully!') || ''
                    )
                } else {
                    setErrorMessage(t('email_page.failed to send reply') || '')
                }
                setBody('')
            } catch (error) {
                console.error('Error sending Reply:', error)
                setErrorMessage(t('email_page.failed to send reply') || '')
            } finally {
                setLoading(false)
            }
        }

        return (
            <div id="reply" className="p-2 pt-4 " ref={ref}>
                {successMessage && (
                    <p className="flex text-green-500 text-lg px-2 justify-end mb-2 h-8">
                        {successMessage}
                    </p>
                )}
                {errorMessage && (
                    <p className="flex text-red-500 text-lg px-2 justify-end mb-2 h-8">
                        {errorMessage}
                    </p>
                )}
                {!successMessage && !errorMessage && <p className="h-10"></p>}
                <div className="flex flex-col lg:flex-row  items-center w-full">
                    <div className="flex items-center w-full lg:w-2/3 truncate">
                        <ArrowUturnLeftIcon className="h-6 w-6 mr-2 shrink-0" />
                        <div className="text-gray-500 text-lg truncate">
                            {t('email_page.replying to')}:{' '}
                            {mailDetails.senderEmail}
                        </div>
                    </div>
                    <div className="flex w-full lg:w-1/3 ">
                        <button
                            className="flex items-center text-white mr-4 ml-auto text-lg hover:bg-red-600 bg-red-500 rounded py-2 px-4 shrink-0"
                            onClick={handleClear}
                        >
                            <TrashIcon className="h-6 w-6 mr-1" />
                            {t('email_page.cancel')}
                        </button>
                        <button
                            className="flex items-center  text-white text-lg hover:bg-green-600 mr-2 bg-green-500 rounded py-2 px-4 shrink-0 curson-pointer"
                            onClick={!loading ? sendReply : undefined}
                            disabled={bodyError}
                        >
                            {loading ? (
                                <div className="relative">
                                    <LoadingWheel className="h-6 w-6 mr-6 ml-6" />
                                </div>
                            ) : (
                                <>
                                    <PaperAirplaneIcon className="h-6 w-6 mr-1" />
                                    {t('email_page.send')}
                                </>
                            )}
                        </button>
                    </div>
                </div>
                <div>
                    <textarea
                        placeholder={t('email_page.email body') || ''}
                        value={body}
                        onChange={handleBodyChange}
                        className={`border ${
                            bodyError
                                ? 'border-red-500 focus:outline-red-500'
                                : 'border-gray-300'
                        } px-2 rounded mt-4 w-full h-full text-lg `}
                        rows={10}
                    />
                    {bodyError ? (
                        <p className="text-red-500 text-md px-2">
                            {t('email_page.body is required')}
                        </p>
                    ) : (
                        <p className="mb-6"></p>
                    )}
                </div>
            </div>
        )
    }
)

ReplyEmail.displayName = 'ReplyEmail'

export default ReplyEmail
