import React, { useState } from 'react'
import { VideoProvider } from './hooks/Video/VideoProvider'
import { ParticipantProvider } from './hooks/Participants/ParticipantProvider'
import VideoApp from './VideoApp'
import useConnectionOptions from './hooks/useConnectionOptions'
import AppStateProvider from './hooks/VideoAppState'
import ErrorDialog from './Components/Room/ErrorDialog'

const VideoScreen: React.FC = () => {
    const [error, setError] = useState<Error | null>(null)
    const connectionOptions = useConnectionOptions()

    return (
        <AppStateProvider>
            <VideoProvider options={connectionOptions} onError={setError}>
                <ErrorDialog
                    dismissError={() => setError(null)}
                    error={error}
                />
                <ParticipantProvider>
                    <VideoApp />
                </ParticipantProvider>
            </VideoProvider>
        </AppStateProvider>
    )
}

export default VideoScreen
