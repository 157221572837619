import React, { useRef } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import { useTranslation } from 'react-i18next'

const ChatInstructions: React.FC = () => {
    const instructionContainerRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    return (
        <div className=" flex flex-col  overflow-hidden h-full  ">
            <div
                className="  p-6 text-white overflow-auto text-2xl h-full"
                ref={instructionContainerRef}
            >
                <div className="scroll-child h-full">
                    <div className="flex items-center ">
                        <InformationCircleIcon className="w-20 h-20" />
                        <h2 className="text-3xl font-bold mb-4">
                            {t('sms_page.how to instruction title')}
                        </h2>
                    </div>
                    <ol className="list-decimal pl-6 space-y-4">
                        <li>
                            <span className=" font-medium">
                                {t('sms_page.how to instruction body 1')}
                            </span>
                            <ol className="list-decimal pl-6 space-y-2 ">
                                <li>
                                    {t('sms_page.how to instruction body 2')}
                                </li>
                                <li>
                                    {t('sms_page.how to instruction body 3')}
                                </li>
                                <li>
                                    {t('sms_page.how to instruction body 4')}
                                </li>
                                <li>
                                    {t('sms_page.how to instruction body 5')}
                                </li>
                                <li>
                                    {t('sms_page.how to instruction body 6')}
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className=" font-medium">
                                {t('sms_page.start instruction title')}
                            </span>
                            <ol className="list-decimal pl-6 space-y-2">
                                <li>
                                    {t('sms_page.start instruction body 1')}
                                </li>
                                <li>
                                    {t('sms_page.start instruction body 2')}
                                </li>
                                <li>
                                    {t('sms_page.start instruction body 3')}
                                </li>
                                <li>
                                    {t('sms_page.start instruction body 4')}
                                </li>
                                <li>
                                    {t('sms_page.start instruction body 5')}
                                </li>
                                <li>
                                    {t('sms_page.start instruction body 6')}
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <hr />
            <div className="shrink-0 my-2 self-center items-end  flex">
                <ScrollFeedButtons
                    scrollContainerRef={instructionContainerRef}
                />
            </div>
        </div>
    )
}

export default ChatInstructions
