// components/Pagination.tsx

import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

interface PaginationProps {
    currentPage: number
    totalPages: number
    onPageChange: (newPage: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {
    return (
        <div className="flex  ">
            <button
                className="px-3 py-1 border rounded-l-md hover:bg-gray-200 disabled:opacity-50"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <ChevronLeftIcon className="w-6 h-6" />
            </button>
            <div className="flex items-center">
                <span className="ml-2">{currentPage}</span>
                <span className="mx-2">of {totalPages}</span>
            </div>
            <button
                className="px-3 py-1 border rounded-r-md hover:bg-gray-200 disabled:opacity-50"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <ChevronRightIcon className="w-6 h-6" />
            </button>
        </div>
    )
}

export default Pagination
