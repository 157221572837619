import React, {
    forwardRef,
    Ref,
    ChangeEventHandler,
    useState,
    useEffect,
} from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import api from '../../../../api'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

interface Props {
    ref: Ref<HTMLDivElement> | undefined
}

const CreateEmail = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [recipientEmail, setRecipientEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [recipientEmailError, setRecipientEmailError] = useState('')
    const [subjectError, setSubjectError] = useState(false)
    const [bodyError, setBodyError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const { t } = useTranslation()

    const handleRecipientChange: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        const email = event.target.value
        setRecipientEmail(email)
        if (!validateEmail(email)) {
            setRecipientEmailError(t('email_page.invalid email') || '')
        } else {
            setRecipientEmailError('')
        }
        if (email === '') {
            setRecipientEmailError(t('email_page.email required') || '')
        }
    }

    const handleSubjectChange: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        const subject = event.target.value
        setSubject(subject)
        setSubjectError(subject.trim() === '')
    }

    const handleBodyChange: ChangeEventHandler<HTMLTextAreaElement> = (
        event
    ) => {
        const body = event.target.value
        setBody(body)
        setBodyError(body.trim() === '')
    }

    const sendMail = async () => {
        try {
            setSubjectError(subject.trim() === '')
            setBodyError(body.trim() === '')
            if (recipientEmail === '') {
                setRecipientEmailError(t('email_page.email required') || '')
                return
            }
            if (
                recipientEmailError !== '' ||
                subject.trim() === '' ||
                body.trim() === ''
            ) {
                return
            }

            setLoading(true)
            setSuccessMessage('')
            setErrorMessage('')

            const emailData = {
                RecipientEmail: recipientEmail,
                Subject: subject,
                Body: body,
            }

            const response = await api.post('/mail/create', emailData)

            if (response.status === 200 && response.data.status === 'success') {
                setSuccessMessage(t('email_page.send success') || '')
            } else {
                setErrorMessage(t('email_page.send failure') || '')
            }

            clearFields()
        } catch (error) {
            console.error('Error sending email:', error)
            setErrorMessage(t('email_page.send failure') || '')
        } finally {
            setLoading(false)
        }
    }

    const clearFields = () => {
        setRecipientEmail('')
        setSubject('')
        setBody('')
    }

    const validateEmail = (email: string) => {
        // Regular expression for email validation
        const emailRegex = new RegExp(
            /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
        return emailRegex.test(email)
    }

    useEffect(() => {
        let successTimeout: NodeJS.Timeout
        let errorTimeout: NodeJS.Timeout

        if (successMessage) {
            successTimeout = setTimeout(() => {
                setSuccessMessage('')
            }, 3000)
        }

        if (errorMessage) {
            errorTimeout = setTimeout(() => {
                setErrorMessage('')
            }, 3000)
        }

        return () => {
            clearTimeout(successTimeout)
            clearTimeout(errorTimeout)
        }
    }, [successMessage, errorMessage])

    return (
        <div className="relative overflow-auto h-full">
            <div className={`flex overflow-auto h-full bg-white`} ref={ref}>
                <div className="flex flex-col scroll-child w-full break-all">
                    <div className="flex flex-row w-full flex-shrink">
                        <div className="w-full">
                            <div
                                className={`flex flex-row text-gray-500 font-medium text-2xl px-2 items-center ${
                                    recipientEmailError !== ''
                                        ? 'border-red-500'
                                        : ''
                                }`}
                            >
                                <span
                                    className={`flex-shrink-0 mt-2 text-gray-500 text-2xl ${
                                        recipientEmailError !== ''
                                            ? 'text-red-500'
                                            : ''
                                    }`}
                                    style={{ minWidth: '125px' }}
                                >
                                    {t('email_page.recipient')}:
                                </span>
                                <input
                                    type="text"
                                    placeholder={
                                        t('email_page.recipient') || ''
                                    }
                                    value={recipientEmail}
                                    onChange={handleRecipientChange}
                                    className={`p-2 rounded mt-2 text-gray-900 text-2xl border-none focus:ring-transparent  w-full ${
                                        recipientEmailError !== ''
                                            ? 'border-red-500'
                                            : ''
                                    }`}
                                />
                            </div>
                            {recipientEmailError !== '' ? (
                                <>
                                    <hr className="border-red-500" />
                                    <p className="text-red-500 text-sm px-2">
                                        {recipientEmailError}
                                    </p>
                                </>
                            ) : (
                                <hr className="border-gray-500" />
                            )}
                            <div
                                className={`flex flex-row text-gray-500 font-medium text-2xl px-2 items-center ${
                                    subjectError ? 'border-red-500' : ''
                                }`}
                            >
                                <span
                                    className={`flex-shrink-0 mt-2 text-gray-500 text-2xl ${
                                        subjectError ? 'text-red-500' : ''
                                    }`}
                                    style={{ minWidth: '125px' }}
                                >
                                    {t('email_page.subject')}:
                                </span>
                                <input
                                    type="text"
                                    placeholder={t('email_page.subject') || ''}
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    className={`p-2 rounded mt-2 text-gray-900 text-2xl border-none focus:ring-transparent w-full ${
                                        subjectError ? 'border-red-500' : ''
                                    }`}
                                />
                            </div>
                            {subjectError ? (
                                <>
                                    <hr className="border-red-500" />
                                    <p className="text-red-500 text-sm px-2">
                                        {t('email_page.subject required')}
                                    </p>
                                </>
                            ) : (
                                <hr className="border-gray-500" />
                            )}
                        </div>
                        <button
                            className="bg-zinc-400 w-16 w-min-16 h-full rounded mr-1 justify-center items-center flex-shrink-0 hover:bg-gray-500"
                            onClick={sendMail}
                            disabled={loading}
                        >
                            <PaperAirplaneIcon />
                        </button>
                    </div>

                    <div className="w-full p-2 h-full mb-8">
                        <textarea
                            placeholder={t('email_page.email body') || ''}
                            value={body}
                            onChange={handleBodyChange}
                            className={`border ${
                                bodyError
                                    ? 'border-red-500 focus:border-red-500 focus:border-2 ring-transparent focus:ring-transparent'
                                    : 'border-gray-300 focus:border-gray-300 focus:border-2 ring-transparent focus:ring-transparent'
                            } p-2 rounded mt-2 w-full h-full text-lg`}
                            rows={10}
                        />
                        {bodyError && (
                            <p className="text-red-500 text-sm px-2">
                                {t('email_page.body required')}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {loading && (
                <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50 h-full">
                    <LoadingWheel className="w-16 h-16" />
                </div>
            )}
            {successMessage && (
                <div className="absolute inset-0 bg-green-600 bg-opacity-75 flex justify-center items-center z-50">
                    <p className="text-white text-medium text-3xl">
                        {successMessage}
                    </p>
                </div>
            )}
            {errorMessage && (
                <div className="absolute inset-0 bg-red-500 bg-opacity-75 flex justify-center items-center z-50">
                    <p className="text-white text-medium  text-3xl">
                        {errorMessage}
                    </p>
                </div>
            )}
        </div>
    )
})

CreateEmail.displayName = 'CreateEmail'
export default CreateEmail
