import React, { useEffect, useRef, useState } from 'react'

import CardPlayButton from '../Buttons/CardPlayButton'
import { MusicalNoteIcon } from '@heroicons/react/24/solid'
import HorizontalScrollButtons from '../../../../common/scrollComponents/HorizontalScrollButtons'
import { Link } from 'react-router-dom'


type AlbumCardProps = {
    album: SpotifyApi.AlbumObjectSimplified
    className?: string
}

const AlbumCard: React.FC<AlbumCardProps> = ({ album, className}) => {
    return (
        <Link to={"/fun/music/album/"+ album.id} className={"scroll-child w-44 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 "+ className}>
            <div className="relative h-32 w-32">
                {album.images.length === 0 ? ( 
                    <MusicalNoteIcon />
                ) : (
                    <img
                        className="h-32 w-32 rounded-sm"
                        src={album.images[0].url}
                        alt={album.name}
                    />
                )}
                <CardPlayButton
                    uri={album.uri}
                    className="absolute bottom-[-1rem] right-[-1rem] w-16 h-16"
                />
            </div>
            <p className="font-bold overflow-hidden whitespace-nowrap overflow-ellipsis break-all">
                {album.name}
            </p>
            <p className="text-sm overflow-hidden overflow-ellipsis capitalize">
                {album.type}
            </p>
        </Link>
    )
}

const HorizontalAlbumFeed: React.FC<{
    albums:
        | SpotifyApi.SavedAlbumObject[]
        | SpotifyApi.AlbumObjectSimplified[]
        | undefined
}> = ({ albums }) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [isScrollBarVisible, setIsScrollBarVisible] = useState(false)

    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current
            const isScrollBarVisible =
                container.scrollWidth > container.clientWidth
            setIsScrollBarVisible(isScrollBarVisible)
        }
    }, [albums])
    return (
        <>
            {albums && albums.length > 0 && (
                <div className="flex scroll-child">
                    <div
                        className="overflow-x-auto whitespace-nowrap"
                        ref={containerRef}
                    >
                        <div
                            className={`flex space-x-4  pt-2  ${
                                isScrollBarVisible ? 'pb-1' : 'pb-3'
                            }`}
                        >
                            {albums.map((savedAlbum) => {
                                const album =
                                    'album' in savedAlbum
                                        ? savedAlbum.album
                                        : savedAlbum
                                return (
                                    <AlbumCard key={album.id} album={album} />
                                )
                            })}
                        </div>
                    </div>
                    {isScrollBarVisible && (
                        <HorizontalScrollButtons
                            scrollContainerRef={containerRef}
                            extraScroll={16}
                        />
                    )}
                </div>
            )}
        </>
    )
}

type AlbumGridFeedProps = {
  albums: SpotifyApi.AlbumObjectSimplified[];
};

const AlbumGridFeed: React.FC<AlbumGridFeedProps> = ({ albums }) => {
  return (
    <div className="flex flex-wrap">
      {albums.map((album) => (
        <AlbumCard key={album.id} album={album} className='mr-3 mb-3'/>
      ))}
    </div>
  );
};

export { AlbumCard, HorizontalAlbumFeed , AlbumGridFeed}
