import React from 'react'
import { LocalAudioTrack } from 'twilio-video'
import useDevices from '../../hooks/useDevices'
import useMediaStreamTrack from '../../hooks/Tracks/useMediaStreamTrack'
import useVideoContext from '../../hooks/Video/useVideoContext'
import { SELECTED_AUDIO_INPUT_KEY } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

export default function AudioInputList() {
    const { audioInputDevices } = useDevices()
    const { localTracks } = useVideoContext()

    const localAudioTrack = localTracks.find(
        (track) => track.kind === 'audio'
    ) as LocalAudioTrack
    const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack
    const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
    const localAudioInputDeviceId =
        srcMediaStreamTrack?.getSettings().deviceId ||
        mediaStreamTrack?.getSettings().deviceId

    function replaceTrack(newDeviceId: string) {
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId)
        localAudioTrack?.restart({ deviceId: { exact: newDeviceId } })
    }

    const { t } = useTranslation()

    return (
        <div>
            <h3 className="text-lg font-medium mb-1">
                {t('video_page.audio input')}
            </h3>
            <div className="flex items-center">
                <div className="w-full ">
                    {audioInputDevices.length > 1 ? (
                        <select
                            onChange={(e) => replaceTrack(e.target.value)}
                            value={localAudioInputDeviceId || ''}
                            className="border border-gray-300 rounded-md p-2 w-full"
                        >
                            {audioInputDevices.map((device) => (
                                <option
                                    value={device.deviceId}
                                    key={device.deviceId}
                                >
                                    {device.label}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <p>
                            {localAudioTrack?.mediaStreamTrack.label ||
                                t('video_page.no local audio')}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}
