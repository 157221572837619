import React from 'react'
import ToggleAudioButton from './ToggleAudioButton'
import ToggleVideoButton from './ToggleVideoButton'

import EndCallButton from './EndCallButton'
import useParticipants from '../../hooks/Participants/useParticipants'
import useRoomState from '../../hooks/Room/useRoomState'
import { useTranslation } from 'react-i18next'

export default function MenuBar() {
    const roomState = useRoomState()
    const isReconnecting = roomState === 'reconnecting'
    const participants = useParticipants()
   
    const { t } = useTranslation()

    return (
        <>
            <footer className="h-20 py-4 px-4 md:px-8  lg:px-12  xl:px-16 bg-white">
                <div className="mx-auto h-full flex justify-center items-center">
                    <div className="flex-1">
                        <p className="whitespace-nowrap text-xl">
                            {participants.length + 1}{' '}
                            {t('video_page.participants')}
                            {/*participants.length ? 's' : ''*/}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <ToggleAudioButton
                            className={`mr-3 border-solid border-2 border-black p-3 rounded-lg h-16 ${
                                isReconnecting
                                    ? 'bg-zinc-500/40 cursor-not-allowed'
                                    : ' hover:bg-zinc-500/20 cursor-pointer'
                            }`}
                            disabled={isReconnecting}
                        />
                        <ToggleVideoButton
                            className={`border-solid border-2 border-black p-3 rounded-lg  h-16 ${
                                isReconnecting
                                    ? 'bg-zinc-500/40 cursor-not-allowed'
                                    : ' hover:bg-zinc-500/20 cursor-pointer'
                            }`}
                            disabled={isReconnecting}
                        />
                    </div>
                    <div className="flex-1 flex justify-end">
                        <EndCallButton />
                    </div>
                </div>
            </footer>
        </>
    )
}
