import React from 'react'
import { News } from '../../../utils/GlobalInterface'
import NewsItem from './NewsItem'

interface Props {
    news: News[]
}

const NewsFeed = React.forwardRef<HTMLDivElement, Props>(({ news }, ref) => {
    return (
        <div className="overflow-auto flex flex-col gap-4" ref={ref}>
            {news
                ? news.map((article, index) => (
                      <NewsItem
                          key={index}
                          index={index}
                          description={article.description}
                          title={article.title}
                          image={article.image}
                          author={article.author}
                          url={article.url}
                          category={article.category}
                          source={article.source}
                          className="scroll-child"
                      />
                  ))
                : null}
        </div>
    )
})
NewsFeed.displayName = 'NewsFeed'

export default NewsFeed
