import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingWheel from '../../../../common/LoadingWheel'
import { useSpotify } from '../../../../../context/spotify/SpotifyPlayerProvider'

const TrackAddModal: React.FC = () => {
    const [playlists, setPlaylists] = useState<
        SpotifyApi.PlaylistObjectSimplified[]
    >([])
    const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>('')
    const { spotifyApi, trackAddModal, trackId, setTrackAddModal } =
        useSpotify()

    const { t } = useTranslation()

    const getUserPlaylists = async () => {
        try {
            const meResponse = await spotifyApi?.getMe()
            if (meResponse?.body.id) {
                const response = await spotifyApi?.getUserPlaylists(
                    meResponse.body.id
                )

                if (response?.statusCode === 200) {
                    const ownedPlaylists = response.body.items.filter(
                        (playlist) => playlist.owner.id === meResponse.body.id
                    )
                    setPlaylists(ownedPlaylists)
                }
            }
        } catch (error) {
            console.error('Error getting user playlists:', error)
            setErrorMessage(
                t('music_page.error getting user playlists') ||
                    'Error getting user playlists'
            )
        }
    }

    useEffect(() => {
        if (trackAddModal && spotifyApi) {
            getUserPlaylists()
        }
        setErrorMessage('')
    }, [trackAddModal])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const modalClose = () => {
        setSelectedPlaylistId('')
        setTrackAddModal(false)
        setSelectedPlaylistId('')
    }

    const handleModalSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault()
        setIsLoading(true)
        setErrorMessage('')
        if (!trackId) {
            setErrorMessage(
                t('music_page.error getting track id') ||
                    'Error getting track id'
            )
            setIsLoading(false)

            return
        }

        try {
            const response = await spotifyApi?.addTracksToPlaylist(
                selectedPlaylistId,
                [trackId]
            )
            if (response?.statusCode === 200 || response?.statusCode === 201) {
                modalClose()
            }
        } catch (error) {
            console.error('Error adding track:', error)
            const s = t('music_page.error adding track')
            setErrorMessage(s)
        }

        setIsLoading(false)
    }

    return (
        <div className="absolute inset-0 flex items-center justify-center z-50 ">
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={modalClose}
            ></div>
            <div className="bg-white rounded-lg p-6 z-20 w-80">
                <h2 className="text-3xl font-semibold mb-4">
                    {t('music_page.add track to playlist')}
                </h2>
                <form onSubmit={handleModalSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="albumName"
                            className="block text-gray-700 text font-medium mb-2"
                        >
                            {t('music_page.choose playlist')}
                        </label>
                        <select
                            id="playlistSelect"
                            className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 text-lg"
                            value={selectedPlaylistId}
                            onChange={(e) =>
                                setSelectedPlaylistId(e.target.value)
                            }
                            required
                        >
                            <option value="" disabled>
                                {t('music_page.select a playlist')}
                            </option>
                            {playlists.map((playlist) => (
                                <option key={playlist.id} value={playlist.id}>
                                    {playlist.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {errorMessage && (
                        <div className="text-red-500 mb-4">{errorMessage}</div>
                    )}

                    <div className="flex justify-between">
                        <button
                            type="button"
                            className="mr-2 px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none text-xl"
                            onClick={modalClose}
                        >
                            {t('phone_page.cancel')}
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none text-xl"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <LoadingWheel className="w-6 h-6" />
                            ) : (
                                t('phone_page.add')
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default TrackAddModal
