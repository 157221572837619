import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import sudoku from './GameCovers/sudoku.png'
import chess from './GameCovers/chess.png'
import mahjong from './GameCovers/mahjong.png'
import solitare from './GameCovers/solitare.png'
import g2048 from './GameCovers/2048.png'

const GamesScreen: React.FC = () => {
    const scrollRef = React.useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    const games = [
        {
            title: 'Sudoku',
            img: sudoku,
            link: 'sudoku',
        },
        {
            title: t('game_page.chess'),
            img: chess,
            link: 'chess',
        },
        {
            title: 'Mahjong',
            img: mahjong,
            link: 'mahjong',
        },
        {
            title: t('game_page.solitaire'),
            img: solitare,
            link: 'solitaire',
        },
        {
            title: '2048',
            img: g2048,
            link: '2048',
        },
    ]

    return (
        <div className="flex flex-col gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between">
                <div className="text-6xl font-medium text-gray-300">Games</div>
            </div>
            <div
                className="flex gap-6 flex-wrap h-full overflow-auto"
                ref={scrollRef}
            >
                {games.map((game) => (
                    <Link
                        to={game.title}
                        key={game.title}
                        className="drop-shadow-md aspect-square h-[47%] -mt-2 bg-sky-600/60"
                    >
                        <div className="flex flex-col gap-4 justify-center drop-shadow-md h-full text-gray-300 scroll-child">
                            <span className="self-center w-40">
                                <img className="w-auto" src={game.img} alt="" />
                            </span>
                            <span className="text-center text-lg lg:text-2xl 2xl:text-5xl font-medium truncate px-3">
                                {game.title}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="inline-block">&nbsp;</div>
        </div>
    )
}

export default GamesScreen
