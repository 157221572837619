import React from 'react'

import useLocalAudioToggle from '../../hooks/Tracks/useLocalAudioToggle'
import useVideoContext from '../../hooks/Video/useVideoContext'
import MuteMicIcon from '../../../../../common/SpecialIcons/MuteMicIcon'
import MicIcon from '../../../../../common/SpecialIcons/MicIcon'
import { useTranslation } from 'react-i18next'

export default function ToggleAudioButton(props: {
    disabled?: boolean
    className?: string
}) {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
    const { localTracks } = useVideoContext()
    const hasAudioTrack = localTracks.some((track) => track.kind === 'audio')

    const { t } = useTranslation()

    return (
        <button
            className={`flex items-center ${props.className}`}
            onClick={toggleAudioEnabled}
            disabled={!hasAudioTrack || props.disabled}
            data-cy-audio-toggle
        >
            {isAudioEnabled ? (
                <MicIcon className="mr-2 w-8 h-8 text-black" />
            ) : (
                <MuteMicIcon className="mr-2 w-8 h-8 text-red-500" />
            )}
            <span className="w-28">
                {!hasAudioTrack
                    ? t('video_page.no audio')
                    : isAudioEnabled
                    ? t('video_page.mute')
                    : t('video_page.unmute')}
            </span>
        </button>
    )
}
