// settingsReducer.ts
import { Reducer } from 'react'
import { getDefaultSettings } from './SettingsProvider'
import { Action, Settings } from './Types'
import i18n from '../../i18n/config'

const settingsReducer: Reducer<Settings, Action> = (state, action) => {
    switch (action.type) {
        case 'UPDATE_SETTINGS':
            if (
                action.payload?.language &&
                i18n.language !== action.payload.language &&
                ['en', 'de', 'sl'].includes(action.payload.language)
            ) {
                i18n.changeLanguage(action.payload.language)
            }
            return { ...state, ...action.payload }
        case 'RESET_SETTINGS':
            return getDefaultSettings()
        default:
            return state
    }
}

export default settingsReducer
