import React, { useEffect } from 'react'
import './spotifyPlayerStyle.css'
import { useVolumeTarget } from '../../../context/volume/VolumeContext'
import SpotifyWebPlayer from 'react-spotify-web-playback'
import { useSpotify } from '../../../context/spotify/SpotifyPlayerProvider'
import { useVolume } from '../../../context/volume/VolumeContext'


const SpotifyPlayerComponent: React.FC = () => {
    const { token, uris, setPlayer, play, setPlay, setState, state } = useSpotify()
    const vol = useVolume()
    const volumeTarget = useVolumeTarget()

    const disableAlbumLink = () =>{
        const link = document.querySelector(`.spotify-sidebar [data-component-name="Info"]`);
    if (link) {
      link.addEventListener('click', (e) => {
        e.preventDefault(); // Prevent link from navigating
      });
    }
    }
    useEffect(() => {
        disableAlbumLink()
    }, [state] ) 

    return (
        <div className={`spotify-sidebar ${(volumeTarget!== 'spotify' ) && 'hidden'} w-full `}>
            <SpotifyWebPlayer
                name="Silvernet"
                play={play}
                token={token}
                uris={uris}
                getPlayer={setPlayer}
                hideAttribution={true}
                layout={'responsive'}
                initialVolume={vol / 100}
                callback={(state) => {
                    setPlay(state.isPlaying)
                    setState(state)
                }}
            />
        </div>
    )
}

export default SpotifyPlayerComponent
