import React, { useEffect, useState } from 'react'
import { FolderIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import api from '../../../../api'
import CreateAlbumModal from './AddAlbumModal'
import LoadingWheel from '../../../common/LoadingWheel'
import { useTranslation } from 'react-i18next'

export interface Album {
    coverPhotoBaseUrl: string
    coverPhotoMediaItemId: string
    id: string
    isWriteable: boolean
    mediaItemsCount: string
    productUrl: string
    shareInfo: ShareInfo
    title: string
}

interface ShareInfo {
    isJoinable: boolean
    isJoined: boolean
    isOwned: boolean
    shareToken: string
    shareableUrl: string
    sharedAlbumOptions: SharedAlbumOptions
}

interface SharedAlbumOptions {
    isCollaborative: boolean
    isCommentable: boolean
}

const AlbumsScreen: React.FC = () => {
    const [albums, setAlbums] = useState<Album[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const scrollRef = React.useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    const fetchAlbums = async (): Promise<void> => {
        try {
            const response = await api.get('/photos/albums')
            if (response.data && response.data.data) {
                const sortedAlbums = response.data.data.sort(
                    (a: Album, b: Album) => a.title.localeCompare(b.title)
                )
                setAlbums(sortedAlbums)
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching albums:', error)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAlbums()
    }, [])

    return (
        <div className="flex flex-col gap-4 pb-4 px-4 pt-8 md:px-8 md:pt-12 lg:px-12 lg:pt-16 xl:px-16 xl-pt-24 h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between">
                <div className="text-6xl font-medium text-gray-300">Albums</div>
                <CreateAlbumModal setAlbums={setAlbums} albums={albums} />
            </div>
            <div
                className="flex gap-6 flex-wrap h-full overflow-auto"
                ref={scrollRef}
            >
                {loading ? (
                    <div className="flex justify-center items-center w-full h-full">
                        <LoadingWheel className="h-20 w-20" />
                    </div>
                ) : albums.length !== 0 ? (
                    albums.map((album) => (
                        <Link
                            to={album.id}
                            key={album.id}
                            className="drop-shadow-md aspect-square h-[47%] -mt-2 bg-orange-600/60"
                        >
                            <div className="flex flex-col gap-4 justify-center drop-shadow-md h-full text-gray-300 scroll-child">
                                <span className="self-center w-16 lg:w-24 xl:w-32 2xl:w-36 ">
                                    <FolderIcon />
                                </span>
                                <span className="text-center text-lg lg:text-2xl 2xl:text-5xl font-medium truncate px-3">
                                    {album.title}
                                </span>
                            </div>
                        </Link>
                    ))
                ) : (
                    <div className="flex justify-center items-center w-full h-full">
                        <p className="text-xl p-3 bg-white rounded">
                            {t('album_page.you have no albums')}
                        </p>
                    </div>
                )}
            </div>
            <div className="inline-block">&nbsp;</div>
        </div>
    )
}

export default AlbumsScreen
