import React, { useEffect, useState, useRef, MouseEvent } from 'react'
import api from '../../../../api'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    InteractionItem,
} from 'chart.js'
import { Line, getElementAtEvent } from 'react-chartjs-2'
import AddVitalsModal from './AddVitalsModal'
import { useTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/24/outline'


function classNames(...classes: (string | undefined | null)[]): string {
    return classes.filter(Boolean).join(' ')
}
export interface Vital {
    data: string
    date: Date
    id: string
}

const VitalsScreen: React.FC = () => {
    const { t } = useTranslation()
    const chartRef = useRef()

    const [vitals, setVitals] = useState<Vital[]>([] as Vital[])
    const [selectedVital, setSelectedVital] = useState<Vital>()
    const [data, setData] = useState<string[]>([] as string[])
    const [date, setDate] = useState<string[]>([] as string[])
    const [selectedOption, setSelectedOption] = useState<string>('bloodSugar')

    const [modalAddVital, setModalAddVital] = useState<boolean>(false)
    const [loadingVitals, setLoadingVitals] = useState<boolean>(true)

    useEffect(() => {
        if(modalAddVital === false){
           setSelectedVital(undefined)
        }
    }, [modalAddVital])

    const [graphData, setGraphData] = useState({
        labels: date,
        datasets: [
            {
                data: data,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 10,
            },
        ],
    })
    const [min, setMin] = useState<number>(0)
    const [max, setMax] = useState<number>(10)

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'black', // Set the color of the x-axis grid to white
                },
                ticks: {
                    color: 'black', // Set the color of the x-axis labels to white
                    font: {
                        size: 16, // Increase the font size for x-axis labels
                    },
                },
            },
            y: {
                min: min,
                max: max,
                grid: {
                    color: 'black', // Set the color of the y-axis grid to white
                },
                ticks: {
                    color: 'black', 
                    font: {
                        size: 16, // Increase the font size for x-axis labels
                    },
                },
            },
        },
        
    }

    const tabs = [
        {
            name: t('vitals_page.blood_sugar'),
            value: 'bloodSugar',

            min: 0,
            max: 10,
            unit: '',
        },
        {
            name: t('vitals_page.blood_pressure'),
            value: 'bloodPressure',
            min: 0,
            max: 200,
            unit: '',
        },
        {
            name: t('vitals_page.weight'),
            value: 'weight',
            min: 0,
            max: 300,
            unit: 'kg',
        },
    ]

    const fetchData = async () => {
        try {
            setLoadingVitals(true)

            const response = await api.get('/' + selectedOption + '/get')
            if (response.data && response.data.data) {
                const formattedData: Vital[] = response.data.data.data // Explicitly type formattedData

                formattedData.sort(
                    (a, b) =>
                        new Date(a.date).getTime() - new Date(b.date).getTime()
                )

                setVitals(formattedData)

                const labels = formattedData.map((vital) =>
                    vital.date.toLocaleString().slice(0, 10)
                )
                const dataset = formattedData.map((vital) => vital.data)
                setData(dataset)
                setDate(labels)

                setLoadingVitals(false)
            }
        } catch (error) {
            console.error('Error fetching vitals data:', error)
            setLoadingVitals(false)
        }
    }

    const printElementAtEvent = (
        element: InteractionItem[],
        vitals: Vital[]
    ) => {
        if (!element.length) return

        const { index } = element[0]
        const selectedVitalElement = vitals[index]

        if (selectedVitalElement) {
            setSelectedVital(selectedVitalElement)
            setModalAddVital(true)
        }
    }

    const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const { current: chart } = chartRef

        if (!chart) {
            return
        }

        printElementAtEvent(getElementAtEvent(chart, event), vitals)
    }

    useEffect(() => {
        fetchData()
    }, [selectedOption])

    useEffect(() => {
        // Update graph data whenever vitals change
        const labels = vitals.map((vital: { date: Date }) =>
            vital.date.toLocaleString().slice(0, 10)
        )
        const dataset = vitals.map((vital: { data: string }) => vital.data)

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            labels: labels,
            datasets: [
                {
                    ...prevGraphData.datasets[0],
                    label: selectedOption,
                    data: dataset,
                    pointRadius: 10,
                },
            ],
        }))
    }, [vitals])

    const handleAddVital = async (updatedVitals: Vital[]) => {
        updatedVitals.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )
        setVitals(updatedVitals)
    }

    return (
        <div className="relative flex flex-col gap-2 pb-4 px-4 pt-6 md:px-8 md:pt-6 lg:px-12 lg:pt-8 xl:px-16 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            {!loadingVitals && (
                <div className="flex flex-col h-full">
                    <div className="flex flex-row justify-between mx-4 ">
                        <div className="my-4 ">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {tabs.map((tab) => (
                                    <button
                                        key={tab.value}
                                        className={classNames(
                                            tab.value === selectedOption
                                                ? 'bg-gray-100 text-gray-700'
                                                : 'text-slate-200 hover:text-white border-white border',
                                            'px-4 py-2 font-medium text-base '
                                        )}
                                        onClick={() => {
                                            setSelectedOption(tab.value)
                                            setMin(tab.min)
                                            setMax(tab.max)
                                        }}
                                        aria-current={
                                            tab.value === selectedOption
                                                ? 'page'
                                                : undefined
                                        }
                                    >
                                        {tab.name}
                                    </button>
                                ))}
                            </nav>
                        </div>
                        <div className="my-4 gap-4 flex-row flex">
                            <div className="inline-block border w-80">
                                <div
                                    className="flex gap-2 px-4 py-2 text-white  font-medium text-lg hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                                    onClick={() => setModalAddVital(true)}
                                >
                                    <span>{t('vitals_page.add_btn')}</span>
                                    <span className="w-6">
                                        <PlusCircleIcon className="w-7 h-7" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex bg-white bg-opacity-90 p-8 h-[90%] w-full">
                        {vitals.length === 0 ? (
                            <div className="h-full w-full flex items-center justify-center text-black font-medium uppercase text-2xl">
                                {t('vitals_page.no data recorded')}
                            </div>
                        ) : (
                            <div className="flex h-full items-center justify-center w-full">
                                <Line
                                    options={options}
                                    data={graphData}
                                    ref={chartRef}
                                    onClick={onClick}

                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {modalAddVital && (
                <AddVitalsModal
                    handleModalClose={() => {
                        setModalAddVital(false)
                    }}
                    handleAddVital={handleAddVital}
                    setVitals={setVitals}
                    setSelectedVital={setSelectedVital}
                    selectedVital={selectedVital}
                    selectedOption={selectedOption}
                />
            )}
        </div>
    )
}

export default VitalsScreen
