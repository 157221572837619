import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const CurrentDateTime: React.FC = () => {
    const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date())

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date())
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
        dayPeriod: 'short',
    }

    const dayOfWeek = currentDateTime.getDay()
    const { t } = useTranslation()
    const dayName = [
        t('days.sunday'),
        t('days.monday'),
        t('days.tuesday'),
        t('days.wednesday'),
        t('days.thursday'),
        t('days.friday'),
        t('days.saturday'),
    ]
    const formattedDate =
        dayName[dayOfWeek] +
        ', ' +
        currentDateTime.toLocaleString(undefined, options)
    const formattedTime = currentDateTime.toLocaleString(undefined, timeOptions)

    return (
        <div className="flex flex-col items-end">
            <p className="text-6xl font-medium md:text-6xl xl:text-7xl 2xl:text-8xl mb-1 text-white text-right">
                {formattedTime}
            </p>
            <p className="text-lg md:text-xl xl:text-2xl 2xl:text-3xl text-white text-right font-medium">
                {formattedDate}
            </p>
        </div>
    )
}

export default CurrentDateTime
