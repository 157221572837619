import React from 'react'
import VideoInputList from './VideoInputList'
import AudioInputList from './AudioInputList'
import AudioOutputList from './AudioOutputList'
import { useTranslation } from 'react-i18next'

export default function DeviceSettingsModal({
    onClose,
}: {
    open: boolean
    onClose: () => void
}) {
    const handleClose = () => {
        onClose()
    }

    const { t } = useTranslation()

    return (
        <>
            <div
                className="absolute bg-black/20 h-full w-full z-10"
                onClick={onClose}
            ></div>
            <div
                className={
                    'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white p-5 rounded-lg z-50'
                }
                style={{ width: '600px', minHeight: '400px' }}
            >
                <h2 className="text-2xl font-semibold">
                    {t('video_page.audio and video settings')}
                </h2>
                <hr className="border-gray-500 border-1" />

                <div>
                    <div className="mt-3">
                        <h3 className="text-lg font-semibold mb-3">
                            {' '}
                            {t('video_page.video')}
                        </h3>
                        <VideoInputList />
                    </div>
                    <div className="list-section mt-3">
                        <h3 className="text-lg font-semibold mb-1">
                            {' '}
                            {t('video_page.audio')}
                        </h3>
                        <AudioInputList />
                    </div>
                    <div className="list-section mt-3">
                        <AudioOutputList />
                    </div>
                </div>
                <div className="dialog-actions">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-5 text-xl rounded float-right mt-4"
                        onClick={handleClose}
                    >
                        {t('video_page.done')}
                    </button>
                </div>
            </div>
        </>
    )
}
