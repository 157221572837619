import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExclamationCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid'

interface ConfirmationModalProps {
    message: string
}

const DeviceProblemModal: React.FC<ConfirmationModalProps> = ({ message }) => {
    const { t } = useTranslation()

    const handleRefreshPage = () => {
        window.location.reload()
    }

    return (
        <div className="absolute inset-0 flex items-center justify-center z-50 bg-black/40">
            <div className="bg-white rounded-lg p-6 shadow-md mx-6">
                <div className="flex items-center">
                    <ExclamationCircleIcon className="w-20 h-20 self-center text-gray-300 text-red-500" />
                    <p className="mb-4 text-xl">{message}</p>
                </div>
                <div className="flex justify-center">
                    <button
                        className="flex px-4 py-2 bg-blue-500 text-white rounded text-xl justify-center"
                        onClick={handleRefreshPage} // Add the click event handler
                    >
                        {t('Refresh Page')}{' '}
                        <ArrowPathIcon className="w-6 h-6 self-center text-white ml-2" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeviceProblemModal
