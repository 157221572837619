import React from 'react'
import { Switch } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

type FormData = {
    language?: string
    colorBlindMode?: string
    useEyeTracker?: boolean
    pinCode?: string
    location?: string
    newsCategories?: string[]
    newsLanguages?: string[]
    newsProviders?: string[]
    phoneNumber?: string
}

interface GeneralFormProps {
    formData: FormData

    handleChangeSwitch: (name: string, checked: boolean) => void
    handleChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const GeneralForm: React.FC<GeneralFormProps> = ({
    formData,
    handleChangeSwitch,
    handleChangeSelect,
    handleChange,
}) => {
    const { t } = useTranslation()
    const colorBlindOptions = [
        'None',
        'Deutranopia',
        'Protanopia',
        'Tritanopia',
    ]
    const locationOptions = [
        'Maribor',
        'Ljubljana',
        'Berlin',
        'Vienna',
        'Graz',
        'Dortmund',
    ]

    return (
        <ul role="list" className="mt-2 divide-y divide-gray-200 ">
            <Switch.Group
                as="li"
                className="flex items-center justify-between py-4"
            >
                <div className="flex flex-col">
                    <Switch.Label
                        as="p"
                        className="text-base font-medium text-gray-900"
                        passive
                    >
                        {t('settings_page.use eye tracker')}
                    </Switch.Label>
                </div>
                <Switch
                    name="useEyeTracker"
                    checked={formData.useEyeTracker}
                    onChange={(checked) =>
                        handleChangeSwitch('useEyeTracker', checked)
                    }
                    className={
                        (formData.useEyeTracker
                            ? 'bg-teal-500'
                            : 'bg-gray-200') +
                        ' relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                    }
                >
                    <span
                        aria-hidden="true"
                        className={
                            (formData.useEyeTracker
                                ? 'translate-x-5'
                                : 'translate-x-0') +
                            ' inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        }
                    />
                </Switch>
            </Switch.Group>

            <div className="py-4">
                <label
                    htmlFor="language"
                    className="block text-base font-medium text-gray-700"
                >
                    {t('settings_page.language')}
                </label>
                <select
                    id="language"
                    name="language"
                    onChange={handleChangeSelect}
                    value={formData.language}
                    className="mt-1 block cursor-pointer w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-teal-600 focus:border-teal-600 sm:text-sm rounded-md"
                >
                    <option value={'sl'}>{t('settings_page.slovenian')}</option>
                    <option value={'en'}>{t('settings_page.english')}</option>
                    <option value={'de'}>{t('settings_page.german')}</option>
                </select>
            </div>

            <div className="py-4">
                <label
                    htmlFor="colorBlindMode"
                    className="block text-base font-medium text-gray-700 mb-2"
                >
                    {t('settings_page.colorblind mode')}
                </label>
                {colorBlindOptions.map((option) => (
                    <div key={option} className="flex py-2">
                        <input
                            type="checkbox"
                            id={option}
                            name="colorBlindMode"
                            value={option}
                            checked={formData.colorBlindMode === option}
                            onChange={handleChange}
                            className=" text-teal-500 focus:ring-0 cursor-pointer ring-0 h-5 w-5 my-auto border-gray-300 rounded"
                        />
                        <label
                            htmlFor={option}
                            className="ml-4 text-sm my-auto font-medium text-gray-600"
                        >
                            {option}
                        </label>
                    </div>
                ))}
            </div>

            <div className="py-4">
                <label
                    htmlFor="location"
                    className="block text-base font-medium text-gray-700"
                >
                    {t('settings_page.weather location')}
                </label>
                <select
                    id="location"
                    name="location"
                    onChange={handleChangeSelect}
                    value={formData.location}
                    className="mt-2 block cursor-pointer w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-teal-600 focus:border-teal-600 sm:text-sm rounded-md"
                >
                    {locationOptions.map((l) => (
                        <option key={l} value={l}>
                            {l}
                        </option>
                    ))}
                </select>
            </div>

            <div className="py-4">
                <label
                    htmlFor="location"
                    className="block text-base font-medium text-gray-700"
                >
                    {t('settings_page.pin')}
                </label>
                <input
                    type="text"
                    name="pinCode"
                    id="pinCode"
                    onChange={handleChange}
                    value={formData.pinCode}
                    className="mt-2 block rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                />
            </div>
            <div className="py-4">
                <label
                    htmlFor="phoneNumber"
                    className="block text-base font-medium text-gray-700"
                >
                    {'Phone Number'}
                </label>
                <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    onChange={handleChange}
                    value={formData.phoneNumber}
                    className="mt-2 block rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                />
            </div>
        </ul>
    )
}

export default GeneralForm
