import {
    PencilIcon,
    TrashIcon,
    PlusCircleIcon,
    ChatBubbleLeftRightIcon,
    InformationCircleIcon,
} from '@heroicons/react/24/outline'
import React, { useEffect, useState, useRef } from 'react'
import api from '../../../../api'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import LoadingWheel from '../../../common/LoadingWheel'
import AddContactModal from '../Phone/AddContactModal'
import ConfirmationModal from '../../../common/ConfirmationModal'
import { useSmsDevice } from '../../../../context/Twilio/SmsDeviceContext'
import { useSettings } from '../../../../hooks/useSettings'
import DeviceProblemModal from '../../../common/DeviceProblemModal'
import { Message, Conversation, Participant } from '@twilio/conversations'

import ConversationFeed from './ConverationFeed'
import ContactsFeed from '../Phone/ContactsFeed'
import ChatComponent from './ChatComponent'
import ChatInstructions from './ChatInstructions'

import { useTranslation } from 'react-i18next'

export interface Contact {
    name: string
    number: string
    id: string
}

interface ContactInfo {
    sms?: {
        address: string
    }
}

export const handlePromiseRejection = async (
    func: () => void
): Promise<void> => {
    try {
        await func()
    } catch (e) {
        console.error(e)
    }
}

const SmsScreen: React.FC = () => {
    const { t } = useTranslation()

    const [loadingContacts, setLoadingContacts] = useState<boolean>(false)
    const [contacts, setContacts] = useState<Contact[]>([] as Contact[])
    const [selectedContact, setSelectedContact] = useState<Contact>()
    const [selectedId, setSelectedId] = useState<string>('')
    const contactsFeedContainerRef = useRef<HTMLDivElement>(null)
    const [modalAddContact, setModalAddContact] = useState<boolean>(false)
    const [modalDeleteContact, setModalDeleteContact] = useState<boolean>(false)
    const [errorDeleteContact, setErrorDeleteContact] = useState<string>('')
    const [updateContact, setUpdateContact] = useState<Contact | undefined>()
    const [participants, setParticipants] = useState<Participant[]>([])

    const [selectedConvo, setSelectedConvo] = useState<Conversation | null>(
        null
    )
    const [convoMessages, setConvoMessages] = useState<Message[]>([])
    const [showConvos, setShowConvos] = useState<boolean>(true)

    const { client, ready, conversations, messages } = useSmsDevice()
    const [settings, ,] = useSettings()

    const findConversation = (id: string) => {
        for (const participant of participants) {
            if (participant.identity === id) {
                return participant.conversation
            }
            if ((participant.bindings as ContactInfo).sms?.address === id) {
                return participant.conversation
            }
        }
        return null
    }
    const createConversation = async (): Promise<Conversation | undefined> => {
        try {
            if (selectedContact) {
                const conversation = await client?.createConversation({
                    friendlyName: selectedContact?.number,
                })

                try {
                    const numberRegex =
                        /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
                    if (numberRegex.test(selectedContact.number)) {
                        if (settings?.phoneNumber) {
                            await conversation?.addNonChatParticipant(
                                settings?.phoneNumber,
                                selectedContact.number,
                                {
                                    friendlyName: selectedContact.number,
                                }
                            )
                        }
                    } else {
                        await conversation?.add(selectedContact.number)
                    }
                    await conversation?.join()
                    await conversation?.advanceLastReadMessageIndex(0)
                    return conversation
                } catch (error) {
                    console.error('Error adding participants:', error)
                    const err = t('sms_page.error user id')
                    setConvoError(err)
                    try {
                        await conversation?.delete()
                    } catch (error) {
                        console.error('Error deleting conversation:', error)
                    }
                }
            }
        } catch (error) {
            console.error('Error creating conversation:', error)
            const err = t('sms_page.error convo')
            setConvoError(err)
        }
    }

    const getParticipants = async () => {
        try {
            if (conversations && ready && messages) {
                let users: Participant[] = []
                for (const convo of conversations) {
                    const participants = Array.from(
                        convo._participants.values()
                    )

                    if (participants) {
                        users = users.concat(participants)
                    }
                }
                setParticipants(users)
            }
        } catch (e) {
            console.error(e)
        }
    }

    const findContact = (identity: string) => {
        const name = contacts.find((contact) => contact.number === identity)
        return name?.name || identity
    }

    const handleSelectedConversation = async (id: string) => {
        setSelectedId(id)
        const selectedConversation = conversations?.find(
            (convo) => convo.sid === id
        )
        setSelectedContact(undefined)
        setSelectedConvo(selectedConversation || null)
        if (selectedConversation) {
            const msg = messages?.get(selectedConversation.sid)
            if (msg) {
                setConvoMessages(msg)
            }
        }
    }
    const handleSelectedContact = (id: string) => {
        setSelectedId(id)
        const selected = contacts.find((contact) => contact.id === id)
        setSelectedContact(selected)
        setConvoError('')
        setSelectedConvo(null)
    }

    useEffect(() => {
        if (selectedConvo) {
            const msg = messages?.get(selectedConvo.sid)
            if (msg) {
                setConvoMessages(msg)

                //advance(msg)
            }
        }
    }, [messages])

    useEffect(() => {
        getParticipants()
    }, [conversations])

    const sortContacts = (contacts: Contact[]): Contact[] => {
        return contacts.slice().sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
    }

    const fetchData = async () => {
        try {
            setLoadingContacts(true)

            const response = await api.get('/phone/contact')
            if (response.data && response.data.data) {
                setContacts(sortContacts(response.data.data.contacts))
                setLoadingContacts(false)
            }
        } catch (error) {
            console.error('Error fetching email data:', error)
            setLoadingContacts(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleDeleteContact = async () => {
        try {
            const response = await api.delete('/phone/contact/' + selectedId)
            if (response.data && response.data.data) {
                setContacts(sortContacts(response.data.data.contacts))
                setSelectedId('')
                setSelectedContact(undefined)
                setModalDeleteContact(false)
                setErrorDeleteContact('')
                setSelectedConvo(null)
            }
            const s = t('phone_page.delete error')
            setErrorDeleteContact(s)
        } catch (error) {
            console.error('Error creating contact:', error)
            const s = t('phone_page.delete error')
            setErrorDeleteContact(s)
        }
    }

    const handleAddContact = async (updatedContacts: Contact[]) => {
        const addedContact = updatedContacts.find(
            (contact) =>
                !contacts.some(
                    (existingContact) => existingContact.id === contact.id
                )
        )

        setContacts(sortContacts(updatedContacts))
        setConvoError('')
        setSelectedContact(addedContact)
        setSelectedId(addedContact?.id || '')
    }

    const [loadiginConvo, setLoadingConvo] = useState(false)
    const [convoError, setConvoError] = useState('')

    const handleFindOrCreateConvo = async () => {
        setLoadingConvo(true)
        setConvoError('')
        const sid = findConversation(selectedContact?.number || '')?.sid
        if (sid) {
            setSelectedContact(undefined)
            handleSelectedConversation(sid)
            setLoadingConvo(false)
            setShowConvos(true)
        } else {
            const conversation = await createConversation()

            if (conversation) {
                setSelectedId(conversation.sid)
                setSelectedConvo(conversation)
                setSelectedContact(undefined)
                setConvoMessages([])
                setShowConvos(true)
            } else {
                if (!convoError) {
                    const err = t('sms_page.error convo')
                    setConvoError(err)
                }
            }
            setLoadingConvo(false)
        }
    }

    return (
        <div className="relative flex flex-col gap-2 pb-4 px-4 pt-6 md:px-8 md:pt-6 lg:px-12 lg:pt-8 xl:px-16 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between">
                <div className="w-full text-5xl font-medium text-gray-300">
                    {'SMS'}
                </div>

                <div className="flex ">
                    <div className="inline-block border w-60 mr-4">
                        <div
                            className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                            onClick={() => {
                                setSelectedContact(undefined)
                                setSelectedConvo(null)
                                setSelectedId('')
                            }}
                        >
                            <span>{t('sms_page.information')}</span>
                            <span className="w-6">
                                <InformationCircleIcon className="w-7 h-7" />
                            </span>
                        </div>
                    </div>
                    <div className="inline-block border w-60">
                        <div
                            className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                            onClick={() => setModalAddContact(true)}
                        >
                            <span>{t('phone_page.new contact')}</span>
                            <span className="w-6">
                                <PlusCircleIcon className="w-7 h-7" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden gap-4 h-full">
                <div className="flex flex-col w-1/4 overflow-hidden border h-full">
                    <div className="flex flex-row  justify-between">
                        <div
                            className={`text-gray-300 font-medium text-2xl  hover:cursor-pointer w-1/2 truncate  ${
                                !showConvos
                                    ? ' hover:bg-zinc-600/70'
                                    : 'bg-zinc-600/50'
                            } `}
                            onClick={() => setShowConvos(true)}
                        >
                            {t('sms_page.conversations')}
                        </div>
                        <div
                            className={`flex text-gray-300 font-medium text-2xl border-l w-1/2 justify-end pr-1 hover:bg-zinc-600/70 hover:cursor-pointer truncate ${
                                showConvos
                                    ? ' hover:bg-zinc-600/70'
                                    : 'bg-zinc-600/50'
                            }`}
                            onClick={() => setShowConvos(false)}
                        >
                            {t('phone_page.contacts')}
                        </div>
                    </div>

                    <hr />
                    <div
                        className="overflow-auto flex-grow h-full"
                        ref={contactsFeedContainerRef}
                    >
                        {showConvos ? (
                            <>
                                {conversations && conversations.length > 0 ? (
                                    <ConversationFeed
                                        client={client}
                                        messages={messages || new Map()}
                                        conversations={conversations || []}
                                        selectedConvo={selectedConvo}
                                        handleSelectConversation={
                                            handleSelectedConversation
                                        }
                                        selectedId={selectedId}
                                        findContact={findContact}
                                    />
                                ) : (
                                    <div className="flex items-center justify-center text-2xl h-full text-white font-medium">
                                        {t('sms_page.no conversations')}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {loadingContacts ? (
                                    <div className="h-full flex justify-center items-center">
                                        <LoadingWheel className="w-16 h-16" />
                                    </div>
                                ) : contacts && contacts.length > 0 ? (
                                    <ContactsFeed
                                        contacts={contacts}
                                        handleSelectContact={
                                            handleSelectedContact
                                        }
                                        selectedId={selectedId}
                                    />
                                ) : (
                                    <div className="h-full flex justify-center items-center text-gray-300 font-medium text-2xl">
                                        {t('phone_page.no contacts')}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <hr />
                    <div className="shrink-0 my-2 self-center items-end  flex">
                        <ScrollFeedButtons
                            scrollContainerRef={contactsFeedContainerRef}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-3/4 overflow-hidden border">
                    <div className="text-gray-300 font-medium text-2xl ">
                        {selectedContact
                            ? t('sms_page.contacts')
                            : selectedConvo
                            ? t('sms_page.messages')
                            : t('sms_page.information')}
                    </div>
                    <hr />
                    {selectedContact ? (
                        <div className="overflow-auto h-full">
                            <div
                                className={`flex flex-col overflow-auto h-full `}
                            >
                                <div className="flex text-gray-300 font-medium text-4xl justify-end w-full ">
                                    <button
                                        className="text-gray-300 hover:text-gray-400 mr-3"
                                        onClick={() => {
                                            setModalAddContact(true)
                                            setUpdateContact(selectedContact)
                                        }}
                                    >
                                        <PencilIcon className="w-12 h-12" />
                                    </button>
                                    <button
                                        className="text-gray-300 hover:text-gray-400 mr-1"
                                        onClick={() =>
                                            setModalDeleteContact(true)
                                        }
                                    >
                                        <TrashIcon className="w-12 h-12" />
                                    </button>
                                </div>
                                <div className="flex text-gray-300 font-medium text-4xl justify-center w-full mt-5">
                                    {selectedContact.name}
                                </div>
                                <div className="flex text-gray-300 font-medium text-4xl justify-center w-full mt-5">
                                    {selectedContact.number}
                                </div>
                                <div className="flex justify-center mt-10">
                                    <button
                                        className={`flex items-center justify-center border-4 rounded-xl w-36 h-36 border-gray-300 ${
                                            loadiginConvo ||
                                            'hover:bg-zinc-600/70'
                                        } `}
                                        onClick={
                                            loadiginConvo
                                                ? undefined
                                                : handleFindOrCreateConvo
                                        }
                                    >
                                        {loadiginConvo ? (
                                            <LoadingWheel className=" ml-2 w-20 h-20" />
                                        ) : (
                                            <ChatBubbleLeftRightIcon className="w-20 h-20 self-center text-gray-300" />
                                        )}
                                    </button>
                                </div>
                                {convoError && (
                                    <div
                                        className={`flex justify-center items-center mt-5 bg-red-500 text-white font-medium text-2xl rounded-sm mx-auto py-2 px-6 max-w-max
                                            
                                    }`}
                                    >
                                        {convoError}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : selectedConvo ? (
                        <>
                            <ChatComponent
                                client={client}
                                selectedConvo={selectedConvo}
                                convoMessages={convoMessages}
                                findContact={findContact}
                            />
                        </>
                    ) : (
                        <ChatInstructions />
                    )}
                </div>
            </div>
            {modalAddContact && (
                <AddContactModal
                    handleModalClose={() => {
                        setModalAddContact(false)
                        setUpdateContact(undefined)
                    }}
                    handleAddContact={handleAddContact}
                    updateContact={updateContact}
                />
            )}
            {modalDeleteContact && (
                <ConfirmationModal
                    message={
                        t('phone_page.are you sure delete') +
                        selectedContact?.name
                    }
                    succesButtonMessage={t('phone_page.delete')}
                    onSuccess={handleDeleteContact}
                    onCancel={() => {
                        setModalDeleteContact(false)
                        setErrorDeleteContact('')
                    }}
                    errorMessage={errorDeleteContact}
                />
            )}
            {ready || (
                <DeviceProblemModal message={t('sms_page.sms problem')} />
            )}
        </div>
    )
}

export default SmsScreen
