import {
    PaperAirplaneIcon,
    ArrowUturnLeftIcon,
    ArrowPathIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/outline'
import React, { useEffect, useState, useRef } from 'react'
import api from '../../../../api'
import EmailFeed from './EmailFeed'
import ScrollFeedButtons from '../../../common/scrollComponents/ScrollFeedButtons'
import LoadingWheel from '../../../common/LoadingWheel'
import CreateEmail from './CreateEmail'
import ReplyEmail from './ReplyEmail'
import { useTranslation } from 'react-i18next'

export interface MailDetails {
    bodyHTML: string
    bodyPlain: string
    recipientEmail: string
    senderEmail: string
    subject: string
    attachments: {
        name: string
        type: string
    }[]
}

export interface Email {
    date: string
    id: string
    senderEmail: string
    size: number
    snippet: string
    subject: string
}
const EmailScreen: React.FC = () => {
    const [mailDetails, setMailDetails] = useState<MailDetails>(
        {} as MailDetails
    )

    const { t } = useTranslation()

    const [loadingEmails, setLoadingEmails] = useState<boolean>(false)
    const [mails, setMails] = useState<Email[]>([] as Email[])
    const [nextPageToken, setNextPageToken] = useState<string>('')
    const [selectedId, setSelectedId] = useState<string>('')
    const [creatingMail, setCreatingMail] = useState<boolean>(false)
    const emailsFeedContainerRef = useRef<HTMLDivElement>(null)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const detailsContainerRef = useRef<HTMLDivElement>(null)
    const replyMailRef = useRef<HTMLDivElement>(null)
    const [errorMailDetails, setErrorMailDetails] = useState<boolean>(false)
    const [replyEmail, setReplyEmail] = useState<boolean>(false)
    const [mailPage, setMailPage] = useState<number>(0)
    const [mailCache, setMailCache] = useState<Email[][]>([])
    const [lastPage, setLastPage] = useState<number>(-1)
    const [loadingMailDetails, setLoadingMailDetails] = useState<boolean>(false)

    const fetchData = async () => {
        try {
            setLoadingEmails(true)
            const response = await api.get('/mail/get')

            if (response.data) {
                setMails(response.data.mails)
                setNextPageToken(response.data.nextPageToken)
                setLoadingEmails(false)
                setMailCache([response.data.mails])

                if (response.data.nextPageToken === '') {
                    setLastPage(0)
                }
                setMailPage(0)
            }
        } catch (error) {
            console.error('Error fetching email data:', error)
            setLoadingEmails(false)
        }
    }

    const fetchMoreData = async () => {
        try {
            if (!nextPageToken || nextPageToken == '') {
                return
            }
            setLoadingEmails(true)
            const response = await api.get(
                `/mail/get?pageToken=${nextPageToken}` //This is how you specify the page
            )
            if (response.data) {
                setMails(response.data.mails)
                setNextPageToken(response.data.nextPageToken)
                if (response.data.nextPageToken == '') {
                    setLastPage(mailPage + 1)
                }

                setLoadingEmails(false)
                setMailCache(mailCache.concat([response.data.mails]))
                setMailPage(mailPage + 1)
            }
        } catch (error) {
            console.error('Error fetching more email data:', error)
            setLoadingEmails(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchMailDetails = async (mailId: string) => {
        setLoadingMailDetails(true)
        try {
            setCreatingMail(false)
            setMailDetails({} as MailDetails)
            const response = await api.get('/mail/get/' + mailId)

            if (response.data) {
                if (response.data.data) {
                    setMailDetails(response.data.data)
                    setErrorMailDetails(false)
                } else {
                    setErrorMailDetails(true)
                }
                setReplyEmail(false)
                setSelectedId(mailId)
            }
        } catch (error) {
            console.error('Error fetching email data:', error)
            setErrorMailDetails(true)
        }
        setLoadingMailDetails(false)
    }

    const emailDetailsHeader = (title: string, content: string) => {
        return (
            <>
                <div className="flex flex-row text-gray-500 font-medium text-2xl px-2 pb-1 box-content ">
                    <span className="w-32 text-2xl">{title}: </span>
                    <span className="text-2xl">{content}</span>
                </div>
                <hr className="border-gray-500" />
            </>
        )
    }
    const openCreateEmailWindow = () => {
        setCreatingMail(true)
        setSelectedId('')
        setErrorMailDetails(false)
        setReplyEmail(false)
    }

    const openReplyEmail = () => {
        setReplyEmail(true)
        setTimeout(() => {
            if (replyMailRef.current) {
                replyMailRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 100)
    }

    const closeReplyEmail = () => {
        if (detailsContainerRef.current) {
            detailsContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
        setTimeout(() => {
            setReplyEmail(false)
        }, 500)
    }

    const handleNextPage = () => {
        const currentPage = mailPage + 1
        if (mailCache.length > currentPage) {
            setMailPage(currentPage)
            setMails(mailCache[currentPage])
            return
        } else {
            fetchMoreData()
        }
    }

    const handlePreviousPage = () => {
        if (mailPage === 0) return
        const currentPage = mailPage - 1
        setMailPage(currentPage)
        setMails(mailCache[currentPage])
    }

    useEffect(() => {
        if (iframeRef.current) {
            const iframe = iframeRef.current

            const updateIframeHeight = () => {
                if (iframe?.contentWindow?.document.body.scrollHeight) {
                    //add 50 px for offset so that the scroll bar doesn't show up
                    iframe.style.height = `${
                        iframe?.contentWindow?.document.body.scrollHeight + 50
                    }px`
                    iframe.style.width = `100%`
                } else {
                    iframe.style.height = `500px`
                }
            }

            // Attach the event listener to the iframe's load event
            iframe.addEventListener('load', updateIframeHeight)

            // Initial call to set the iframe height
            updateIframeHeight()

            // Cleanup the event listener when the component unmounts
            return () => {
                iframe.removeEventListener('load', updateIframeHeight)
            }
        }
    }, [mailDetails.bodyHTML])

    return (
        <div className="flex flex-col gap-2 pb-4 pb-4 pl-4 pt-6 md:pl-4 md:pt-6 lg:pl-6 lg:pt-8 xl:pl-8 xl-pt-16 overflow-hidden h-main-sm md:h-main-md lg:h-main-lg xl:h-main-xl 2xl:h-main-2xl">
            <div className="flex items-center justify-between">
                <div className="w-full text-5xl font-medium text-gray-300">
                    {t('email_page.email')}
                </div>

                <div className="inline-block border w-60">
                    <div
                        className="flex gap-2 px-4 py-2 text-white  font-medium text-xl hover:bg-zinc-600/70 hover:cursor-pointer justify-center items-center"
                        onClick={openCreateEmailWindow}
                    >
                        <span>{t('email_page.new email')}</span>
                        <span className="w-6">
                            <PaperAirplaneIcon />
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden gap-4 h-full">
                <div className="flex flex-col w-1/4 overflow-hidden border h-full">
                    <div className="flex justify-center items-center">
                        <div className="flex-1 truncate">
                            <div className="text-gray-300 font-medium text-2xl truncate">
                                {t('email_page.inbox')}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="text-lg  mt-auto text-white truncate">
                                #{mailPage + 1}
                            </span>
                        </div>
                        <div className="flex-1 flex justify-end">
                            <button className="px-2" onClick={fetchData}>
                                <ArrowPathIcon className="h-6 w-6 text-white" />
                            </button>
                        </div>
                    </div>

                    <hr />
                    <div
                        className="overflow-auto flex-grow h-full"
                        ref={emailsFeedContainerRef}
                    >
                        {loadingEmails ? (
                            <div className="h-full flex justify-center items-center">
                                <LoadingWheel className="w-16 h-16" />
                            </div>
                        ) : mails && mails.length > 0 ? (
                            <EmailFeed
                                mails={mails}
                                fetchMailDetails={fetchMailDetails}
                                selectedId={selectedId}
                            />
                        ) : (
                            <div className="h-full flex justify-center items-center text-gray-300 font-medium text-2xl">
                                {t('email_page.no emails')}
                            </div>
                        )}
                    </div>
                    <hr />
                    <div className="shrink-0 my-2 self-center items-end  flex">
                        <div className="flex flex-col mr-2 items-center">
                            <div></div>
                            <div className="flex">
                                <button
                                    className={`flex  w-10 h-10 md:h-12 md:w-12 bg-gray-300 rounded items-center justify-center hover:bg-gray-400 mr-2 ${
                                        mailPage === 0
                                            ? 'opacity-50 cursor-not-allowed'
                                            : ''
                                    }`}
                                    onClick={handlePreviousPage}
                                >
                                    <ChevronLeftIcon className="w-10 h-10" />
                                </button>

                                <button
                                    className={`flex  w-10 h-10 md:h-12 md:w-12 bg-gray-300 rounded items-center justify-center hover:bg-gray-400 ${
                                        lastPage === mailPage
                                            ? 'opacity-50 cursor-not-allowed'
                                            : ''
                                    }`}
                                    onClick={handleNextPage}
                                >
                                    <ChevronRightIcon className="w-10 h-10 " />
                                </button>
                            </div>
                        </div>
                        <ScrollFeedButtons
                            scrollContainerRef={emailsFeedContainerRef}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-3/4 overflow-hidden border">
                    <div className="text-gray-300 font-medium text-2xl ">
                        {creatingMail
                            ? t('email_page.new email')
                            : t('email_page.email content')}
                    </div>
                    <hr />
                    {creatingMail ? (
                        <CreateEmail ref={detailsContainerRef} />
                    ) : loadingMailDetails ? (
                        <div className="h-full flex justify-center items-center">
                            <LoadingWheel className="w-16 h-16" />
                        </div>
                    ) : mailDetails.senderEmail ? (
                        <div className="overflow-auto h-full">
                            <div
                                className={`flex overflow-auto h-full bg-white`}
                                ref={detailsContainerRef}
                            >
                                <div className="flex flex-col scroll-child w-full break-all  ">
                                    <div className="flex flex-row w-full shrink">
                                        <div className="w-full">
                                            {emailDetailsHeader(
                                                t('email_page.sender'),
                                                mailDetails.senderEmail
                                            )}
                                            {emailDetailsHeader(
                                                t('email_page.recipient'),
                                                mailDetails.recipientEmail
                                            )}
                                            {emailDetailsHeader(
                                                t('email_page.subject'),
                                                mailDetails.subject
                                            )}
                                        </div>
                                        <button
                                            className="bg-zinc-400 w-16 w-min-16 h-full rounded mr-1 justify-center items-center shrink-0 hover:bg-gray-500"
                                            onClick={openReplyEmail}
                                        >
                                            <ArrowUturnLeftIcon className="" />
                                        </button>
                                    </div>
                                    {mailDetails.bodyHTML ? (
                                        <div>
                                            {' '}
                                            {/* the div elements is required to function properly */}
                                            <iframe /* iframe elements isolates the html */
                                                ref={iframeRef}
                                                className="w-full p-2  "
                                                title="Email Content"
                                                srcDoc={mailDetails.bodyHTML}
                                            />
                                        </div>
                                    ) : mailDetails.bodyPlain ? (
                                        <div className="w-full p-2 text-lg whitespace-pre-line">
                                            {mailDetails.bodyPlain}
                                        </div>
                                    ) : (
                                        <div className="w-full p-2 text-lg">
                                            {t('email_page.no content')}
                                        </div>
                                    )}
                                    {mailDetails.attachments && (
                                        <>
                                            <div className="text-lg font-medium text-gray-500 px-2">
                                                {t('email_page.attachments')}:
                                            </div>
                                            <div className="flex flex-wrap gap-5 justify-start p-2 ">
                                                {mailDetails.attachments.map(
                                                    (attachment, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex flex-col items-center p-2 border border-gray-500 rounded border-2"
                                                        >
                                                            <div className="">
                                                                {
                                                                    attachment.name
                                                                }
                                                            </div>
                                                            <div className="">
                                                                {
                                                                    attachment.type
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {replyEmail ? (
                                        <ReplyEmail
                                            mailId={selectedId}
                                            mailDetails={mailDetails}
                                            closeReply={closeReplyEmail}
                                            ref={replyMailRef}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : errorMailDetails ? (
                        <div className="flex flex-col justify-center items-center h-full text-gray-300 font-medium text-2xl">
                            {t('email_page.email cannot be loaded')}
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-full text-gray-300 font-medium text-2xl">
                            {t('email_page.no email selected')}
                        </div>
                    )}

                    <div className="my-2 self-center">
                        <ScrollFeedButtons
                            scrollContainerRef={detailsContainerRef}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailScreen
