import React from 'react'

import { useSpotify } from '../../../../../context/spotify/SpotifyPlayerProvider'
import { useTranslation } from 'react-i18next'


type AddTrackButtonProps = {
    uri: string
    className?: string
}

const AddTrackButton: React.FC<AddTrackButtonProps> = ({ uri, className }) => {
    const { setTrackAddModal, setTrackId } = useSpotify()
    const { t } = useTranslation()


    return (
        <>
            <button
                className={
                    'bg-green-600 p-3 rounded-lg text-white font-semibold ' +
                    className
                }
                onClick={() => {
                    setTrackAddModal(true)
                    setTrackId(uri)
                }}
            >
                {t('music_page.add to playlist')}
            </button>
        </>
    )
}

export default AddTrackButton
