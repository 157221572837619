import React, { useEffect, useRef, useState } from 'react'
import { MusicalNoteIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { useSpotify } from '../../../../../context/spotify/SpotifyPlayerProvider'
import CardPlayButton from '../Buttons/CardPlayButton'
import HorizontalScrollButtons from '../../../../common/scrollComponents/HorizontalScrollButtons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type PlaylistCardProps = {
    playlist: SpotifyApi.PlaylistBaseObject
    className?: string
}

const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlist, className }) => {
    const { t } = useTranslation()
    return (
        <Link
            to={'/fun/music/playlist/' + playlist.id}
            className={
                'scroll-child w-44 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 ' +
                className
            }
        >
            <div className="relative h-32 w-32 ">
                {playlist.images.length === 0 ? (
                    <MusicalNoteIcon />
                ) : (
                    <img
                        className="h-32 w-32 rounded-xl"
                        src={playlist.images[0]?.url}
                        alt={playlist.name}
                    />
                )}
                <CardPlayButton
                    uri={playlist.uri}
                    className="absolute bottom-[-1rem] right-[-1rem] w-16 h-16"
                />
            </div>
            <p className="font-bold overflow-hidden whitespace-nowrap overflow-ellipsis break-all">
                {playlist.name}
            </p>
            <p className="text-sm overflow-hidden overflow-ellipsis">
                {t('music_page.playlist')}
            </p>
        </Link>
    )
}

const HorizontalPlaylistFeed: React.FC<{
    playlists: SpotifyApi.PlaylistObjectSimplified[] | undefined
}> = ({ playlists }) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [isScrollBarVisible, setIsScrollBarVisible] = useState(false)

    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current
            const isScrollBarVisible =
                container.scrollWidth > container.clientWidth
            setIsScrollBarVisible(isScrollBarVisible)
        }
    }, [playlists])

    return (
        <>
            {playlists && playlists.length > 0 && (
                <div className="flex scroll-child">
                    <div
                        className="overflow-x-auto whitespace-nowrap"
                        ref={containerRef}
                    >
                        <div
                            className={`flex space-x-4  pt-2  ${
                                isScrollBarVisible ? 'pb-1' : 'pb-3'
                            }`}
                        >
                            {playlists.map((playlist) => (
                                <PlaylistCard
                                    key={playlist.id}
                                    playlist={playlist}
                                />
                            ))}
                        </div>
                    </div>
                    {isScrollBarVisible && (
                        <HorizontalScrollButtons
                            scrollContainerRef={containerRef}
                            extraScroll={16}
                        />
                    )}
                </div>
            )}
        </>
    )
}

type PlaylistGridFeedProps = {
    playlists: SpotifyApi.PlaylistObjectSimplified[]
}

const PlaylistGridFeed: React.FC<PlaylistGridFeedProps> = ({ playlists }) => {
    return (
        <div className="flex flex-wrap">
            {playlists.map((playlist) => (
                <PlaylistCard
                    key={playlist.id}
                    playlist={playlist}
                    className="mr-3 mb-3"
                />
            ))}
        </div>
    )
}

const CreatePlaylistCard: React.FC = () => {
    const { setCreateModal } = useSpotify()
    const { t } = useTranslation()

    return (
        <div
            className="scroll-child w-44 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer"
            onClick={() => setCreateModal(true)}
        >
            <PlusCircleIcon className="h-32 w-32 text-green-600 " />
            <p className="flex font-bold w-32  break-words whitespace-normal">
                {t('music_page.create a playlist')}
            </p>
        </div>
    )
}

const SavedPlaylistsFeed: React.FC<{
    playlists: SpotifyApi.PlaylistObjectSimplified[] | undefined
}> = ({ playlists }) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [isScrollBarVisible, setIsScrollBarVisible] = useState(false)

    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current
            const isScrollBarVisible =
                container.scrollWidth > container.clientWidth
            setIsScrollBarVisible(isScrollBarVisible)
        }
    }, [playlists])

    return (
        <>
            <div className="flex scroll-child">
                <div
                    className="overflow-x-auto whitespace-nowrap"
                    ref={containerRef}
                >
                    <div
                        className={`flex space-x-4  pt-2  ${
                            isScrollBarVisible ? 'pb-1' : 'pb-3'
                        }`}
                    >
                        <CreatePlaylistCard />
                        {playlists && playlists.length > 0 && (
                            <>
                                {playlists.map((playlist) => (
                                    <PlaylistCard
                                        key={playlist.id}
                                        playlist={playlist}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </div>
                {isScrollBarVisible && (
                    <HorizontalScrollButtons
                        scrollContainerRef={containerRef}
                        extraScroll={16}
                    />
                )}
            </div>
        </>
    )
}

export { HorizontalPlaylistFeed, PlaylistGridFeed, SavedPlaylistsFeed }
