import React, { useEffect, useState } from 'react'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import api from '../../../api'
import { useTranslation } from 'react-i18next'

interface Props {
    color: string | undefined
    className: string
    setShowingNotifications: (showing: boolean) => void
}

const UnreadMails: React.FC<Props> = ({
    color,
    className,
    setShowingNotifications,
}) => {
    const [unreadMails, setUnreadMails] = useState(0)
    const { t } = useTranslation()

    if (!color) {
        color = 'bg-neutral-400/[.4]'
    }

    const getUnreadMails = async () => {
        try {
            const response = await api.get('/mail/unread')
            if (response.data.data) {
                setUnreadMails(response.data.data)
                if (response.data.data > 0) {
                    setShowingNotifications(true)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getUnreadMails()

        // Set up an interval to fetch events every 5 minutes (300,000 milliseconds)
        const intervalId = setInterval(() => {
            getUnreadMails()
        }, 300000)

        // Cleanup the interval when the component unmounts
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    return (
        <>
            {unreadMails > 0 && (
                <Link to="/communication/email" className="w-full">
                    <div className={`relative ${color} flex ${className} p-1`}>
                        <div className="text-white h-16 p-2 -ml-1 aspect-square lg:h-20">
                            <EnvelopeIcon />
                        </div>
                        <div className="absolute top-0 bottom-0 right-0 flex flex-col justify-center left-16 lg:left-20">
                            <p className="truncate text-white font-medium text-2xl ">
                                {t('notifications.you have') +
                                    ' ' +
                                    unreadMails +
                                    ' ' +
                                    t('notifications.unread mails')}
                            </p>
                        </div>
                    </div>
                </Link>
            )}
        </>
    )
}

export default UnreadMails
